// @ts-check

import { getStartAndEndDates } from '@/utils/utilities'

/**
 * @typedef {import('./types').Event} Event
 * @typedef {import('./types').EventForm} EventForm
 * @typedef {import('./types').OccurrenceException} OccurrenceException
 * @typedef {import('./types').AddressForm} AddressForm
 */

/** @param {import('./types').AddressForm[]} addresses */
const getEventPartenaireId = (addresses) => {
  /** @type {import('./types').AddressObject} */
  // @ts-ignore
  const structureAddress = addresses.find(
    (location) => location && typeof location === 'object' && location?.type === 'structure',
  )
  return structureAddress?.id
}

/** @param {AddressForm} location */
const getLocationAddressFromEventForm = (location) => {
  return typeof location === 'object' ? location?.text : location ?? null
}

/**
 * @param {object} payload
 * @param {EventForm} payload.event
 * @param {string} [payload.date]
 * @returns {Event}
 */
export const buildEventForApi = ({ event, date }) => {
  try {
    const isPunctualEvent = event.recurrence === '00:00:00'

    const partenaireId = getEventPartenaireId([
      event.mobility_arrival_1,
      event.mobility_departure_1,
    ])

    const eventDate = date ?? event.date

    const { endFormatted } = getStartAndEndDates({
      date: eventDate,
      duration: event.duration,
      time: event.time,
    })

    const dates = isPunctualEvent
      ? {
          date: eventDate,
          date_start: eventDate,
          date_end: endFormatted,
        }
      : {
          date: event.date,
          date_start: event.date_start,
          date_end: event.date_end,
        }

    return {
      ...dates,
      event_type: event.event_type,
      recurrence: event.recurrence,

      time: event.time,
      duration: event.duration,

      demande_id: event.demande_id,

      repit_mode: event.repit_mode,
      repit_address: event.repit_address,

      partenaire_id: partenaireId ?? event.partenaire_id ?? null,
      relayeur_id: event.partenaire_person?.id ?? null,
      relayeur_retour_id: event.retour_partenaire_person?.id ?? null,

      mobility_signed: event.mobility_signed ?? false,
      mobility_trip_type: event.mobility_trip_type ?? null,
      mobility_mode: event.mobility_mode ?? null,
      mobility_departure_1: getLocationAddressFromEventForm(event.mobility_departure_1),
      mobility_departure_2: getLocationAddressFromEventForm(event.mobility_departure_2),
      mobility_arrival_1: getLocationAddressFromEventForm(event.mobility_arrival_1),
      mobility_arrival_2: getLocationAddressFromEventForm(event.mobility_arrival_2),

      cancelled: event.cancelled ?? false,
      cancellation_reason: event.cancellation_reason ?? null,
    }
  } catch (error) {
    throw new Error(`[events.mapper](buildEventForApi) ${error}`)
  }
}

/**
 * @param {object} payload
 * @param {Event} payload.event
 * @param {EventForm} payload.occurrenceException
 * @returns {OccurrenceException}
 */
export const buildOccurrenceExceptionForApi = ({ event, occurrenceException }) => {
  try {
    const partenaireId = getEventPartenaireId([
      occurrenceException.mobility_arrival_1,
      occurrenceException.mobility_departure_1,
    ])

    return {
      id: event.occurrenceExceptionId,
      event_id: event.id,
      date: event.originalDate ?? event.date,

      new_date: occurrenceException.date,

      event_type: occurrenceException.event_type,
      recurrence: occurrenceException.recurrence,

      time: occurrenceException.time,
      duration: occurrenceException.duration,

      demande_id: occurrenceException.demande_id,

      repit_mode: occurrenceException.repit_mode,
      repit_address: occurrenceException.repit_address,

      partenaire_id: partenaireId ?? event.partenaire_id ?? null,
      relayeur_id: occurrenceException.partenaire_person?.id ?? null,
      relayeur_retour_id: occurrenceException.retour_partenaire_person?.id ?? null,

      mobility_signed: occurrenceException.mobility_signed ?? false,
      mobility_trip_type: occurrenceException.mobility_trip_type ?? null,
      mobility_mode: occurrenceException.mobility_mode ?? null,
      mobility_departure_1: getLocationAddressFromEventForm(
        occurrenceException.mobility_departure_1,
      ),
      mobility_departure_2: getLocationAddressFromEventForm(
        occurrenceException.mobility_departure_2,
      ),
      mobility_arrival_1: getLocationAddressFromEventForm(occurrenceException.mobility_arrival_1),
      mobility_arrival_2: getLocationAddressFromEventForm(occurrenceException.mobility_arrival_2),
    }
  } catch (error) {
    throw new Error(`[events.mapper](buildEventForApi) ${error}`)
  }
}
