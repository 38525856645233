// @ts-check

import { moment } from '@/plugins/moment.plugin'
import {
  INSERT_REMINDERS,
  ACTIVE_REMINDERS,
  DEMANDE_REMINDERS,
  UPDATE_REMINDER,
} from '@/graphql/reminders'
import { GET_PARTENAIRE_LIST } from '@/graphql/partenaire'
import { apolloClient } from '@/plugins/apollo-client.plugin'
import { RRule, rrulestr } from 'rrule'

export default {
  namespaced: true,
  state: {
    reminders: null,
    demandeReminders: {
      waitings: [],
      rappels: [],
      visites: [],
      relayeurs: [],
      informations: [],
    },
  },
  getters: {
    reminders(state) {
      return state.reminders
    },
    demandeReminders(state) {
      return state.demandeReminders
    },
  },
  mutations: {
    SET_REMINDERS(state, reminders) {
      const today = Date.now()

      for (const reminder of reminders.toCome) {
        if (reminder.type === 'visite') reminder.type = 'Visite à domicile'
        if (reminder.type === 'waiting') reminder.type = 'Retour'
        if (reminder.type === 'information') reminder.type = 'Information'
        if (reminder.type === 'relayeur') reminder.type = 'Relayeur'
      }

      for (const reminder of reminders.waitings) {
        reminder.since = millisecToDays(today - new Date(reminder.created_at).getTime())
      }

      for (const reminder of reminders.today) {
        if (reminder.type === 'information') reminder.type = 'Recherche information'
        if (reminder.type === 'relayeur') reminder.type = 'Recherche relayeur'
        reminder.since = millisecToDays(today - new Date(reminder.date).getTime())
        if (reminder.type === 'visite') reminder.type = 'Visite à domicile'
      }

      state.reminders = reminders
    },
    SET_DEMANDE_REMINDERS(state, reminders) {
      const rappels = []
      const waitings = []
      const visites = []
      const repit = []
      const relayeurs = []
      const informations = []

      for (const reminder of reminders) {
        const reminderPerson = JSON.parse(reminder.person)

        reminder.person = {
          ...reminderPerson,
          fullName: reminderPerson?.fullName ?? reminderPerson?.names,
        }

        if (reminder.user) {
          reminder.user = reminder.user.id
        }
        reminder.isChecked = true

        switch (reminder.type) {
          case 'rappel': {
            rappels.push(reminder)
            break
          }
          case 'waiting': {
            waitings.push(reminder)
            break
          }
          case 'visite': {
            visites.push(reminder)
            break
          }
          case 'relayeur': {
            relayeurs.push(reminder)
            break
          }
          case 'information': {
            informations.push(reminder)
            break
          }
          case 'Répit':
          case 'Mobilité': {
            {
              repit.push(reminder)
              // No default
            }
            break
          }
        }
      }

      state.demandeReminders.rappels =
        rappels.length === 0
          ? [
              {
                person: null,
                date: moment().format('YYYY-MM-DD'),
                type: 'rappel',
                id_demande: null,
                isChecked: false,
              },
            ]
          : rappels

      state.demandeReminders.waitings =
        waitings.length === 0
          ? [
              {
                person: null,
                date: moment().format('YYYY-MM-DD'),
                type: 'waiting',
                id_demande: null,
                isChecked: false,
              },
            ]
          : waitings

      state.demandeReminders.relayeurs =
        relayeurs.length === 0
          ? [
              {
                person: null,
                date: moment().format('YYYY-MM-DD'),
                type: 'relayeur',
                id_demande: null,
                isChecked: false,
              },
            ]
          : relayeurs

      state.demandeReminders.informations =
        informations.length === 0
          ? [
              {
                person: null,
                date: moment().format('YYYY-MM-DD'),
                type: 'information',
                id_demande: null,
                isChecked: false,
              },
            ]
          : informations
      state.demandeReminders.visites =
        visites.length === 0
          ? [
              {
                person: null,
                date: moment().format('YYYY-MM-DD'),
                time: null,
                type: 'visite',
                id_demande: null,
                isChecked: false,
              },
            ]
          : visites

      state.demandeReminders.repit = repit
    },
    resetReminders(state) {
      state.reminders = null
    },
  },
  actions: {
    async insertReminders(_, reminders) {
      await this._vm.$apollo.mutate({
        mutation: INSERT_REMINDERS,
        variables: {
          reminders,
        },
      })
    },
    async getActiveReminders({ commit }, { id_handler, today, dateEnd }) {
      try {
        const { data } = await this._vm.$apollo.query({
          query: ACTIVE_REMINDERS,
          fetchPolicy: 'no-cache',
          variables: {
            id_handler,
            today,
          },
        })

        const absences = await getPartenaireUnavailabilities(dateEnd)

        commit('SET_REMINDERS', {
          ...data,
          absences,
        })
      } catch (error) {
        throw new Error(`[store/reminders](getActiveReminders) ${error}`)
      }
    },
    async getDemandeReminders({ commit }, id) {
      const {
        data: { reminders },
      } = await this._vm.$apollo.mutate({
        mutation: DEMANDE_REMINDERS,
        variables: { id },
      })

      commit('SET_DEMANDE_REMINDERS', reminders)
    },
    resetReminders({ commit }) {
      commit('resetReminders')
    },
    async updateActiveReminder(_, { id, setObject }) {
      await this._vm.$apollo.mutate({
        mutation: UPDATE_REMINDER,
        variables: { id, setObject },
      })
    },
  },
}

function millisecToDays(ms) {
  return Math.floor(ms / (24 * 60 * 60 * 1000))
}

/**
 * @param {string} endDate
 * @returns {Promise<{ partnerFullName: string; datetime: string; toLink: Record<string, unknown>, allDay: boolean }[]>}
 */
async function getPartenaireUnavailabilities(endDate) {
  const {
    data: { partenaire_person },
  } = await apolloClient.query({
    query: GET_PARTENAIRE_LIST,
  })

  /** @type {import('@/domain/events/types').Partenaire[] } */
  const partnersWithAbsences = partenaire_person.filter(
    ({ unavailabilities }) => unavailabilities.length,
  )

  /** @type {{ partnerFullName: string; datetime: string; endHour?: string; toLink: Record<string, unknown>, allDay: boolean, }[]} */
  const partnerAbscences = []

  for (const partner of partnersWithAbsences) {
    for (const unavailability of partner.unavailabilities) {
      const parsedRrule = rrulestr(unavailability.rrule)

      const isRecurrentAbsence =
        parsedRrule.options.interval === 1 || parsedRrule.options.interval === 2

      if (isRecurrentAbsence) {
        const rrule = new RRule({ ...parsedRrule.options, tzid: undefined })

        const abscenceDates = rrule.between(moment().toDate(), moment(endDate).toDate())

        for (const abscenceDate of abscenceDates) {
          partnerAbscences.push({
            partnerFullName: `${partner.firstname} ${partner.lastname}`,
            datetime: moment(abscenceDate).format(),
            endHour: moment(unavailability.date_end).format('LT'),
            allDay: unavailability.all_day,
            toLink: {
              name: 'partenaire-relayeurs',
              params: { id: partner.id },
            },
          })
        }
      } else {
        const isPastAbsence = moment().isAfter(unavailability.date_start)
        const isBeforeEndDate = moment(unavailability.date_start).isBefore(endDate)

        if (!isPastAbsence && isBeforeEndDate) {
          partnerAbscences.push({
            partnerFullName: `${partner.firstname} ${partner.lastname}`,
            datetime: unavailability.date_start,
            endHour: moment(unavailability.date_end).format('LT'),
            allDay: unavailability.all_day,
            toLink: {
              name: 'partenaire-relayeurs',
              params: { id: partner.id },
            },
          })
        }
      }
    }
  }

  return partnerAbscences.sort(({ datetime: datetimeA }, { datetime: datetimeB }) => {
    return moment(datetimeA).toDate().getTime() - moment(datetimeB).toDate().getTime()
  })
}
