import gql from 'graphql-tag'

export const eventFieldsFragment = gql`
  {
    id
    relayeur_id
    relayeur_retour_id
    demande_id
    duration
    event_type
    time
    mobility_arrival_1
    mobility_arrival_2
    mobility_departure_1
    mobility_departure_2
    mobility_mode
    mobility_signed
    mobility_trip_type
    repit_address
    repit_mode
    date
    date_start
    date_end
    cancelled
    recurrence
    partenaire_id
    partenaire {
      id
      firstname
      lastname
      phone_number1
      comment
      partenaire_location {
        id
        email
        name
        adress
        obsolete
        phone_number1
        cedex_city {
          cedex
          city
        }
        comment
      }
    }
    demande {
      demande_aidant_HMAs {
        id
        type
        identity_aidant_HMA {
          id
          firstname
          lastname
          type
          birthday
          email
          phone_number
          aidant_HMA_comments {
            content
          }
          cedex_city {
            city
          }
        }
      }
    }
    partenaire_person {
      id
      email
      firstname
      lastname
      actif
      phone_number1
      partenaire_location {
        id
        email
        name
        adress
        obsolete
        phone_number1
        cedex_city {
          cedex
          city
        }
        comment
      }
      unavailabilities: partenaire_unavailabilities {
        all_day
        created_at
        date_end
        date_start
        id
        rrule
      }
      structure: partenaire_location {
        id
        name
      }
    }
    retour_partenaire_person {
      id
      email
      firstname
      lastname
      actif
      unavailabilities: partenaire_unavailabilities {
        all_day
        created_at
        date_end
        date_start
        id
        rrule
      }
      structure: partenaire_location {
        id
        name
      }
    }
    cancellation_reason
    cancelled
    occurrence_exceptions {
      id
      relayeur_id
      relayeur_retour_id
      demande_id
      duration
      event_type
      time
      mobility_arrival_1
      mobility_arrival_2
      mobility_departure_1
      mobility_departure_2
      mobility_mode
      mobility_signed
      mobility_trip_type
      repit_address
      repit_mode
      date
      new_date
      cancelled
      recurrence
      partenaire_id
      partenaire {
        id
        firstname
        lastname
        phone_number1
        comment
        partenaire_location {
          id
          name
          adress
          phone_number1
          cedex_city {
            cedex
            city
          }
          comment
        }
      }
      demande {
        demande_aidant_HMAs {
          identity_aidant_HMA {
            id
            firstname
            lastname
            type
            birthday
            email
            phone_number
            aidant_HMA_comments {
              content
            }
            cedex_city {
              city
            }
          }
        }
      }
      partenaire_person {
        id
        email
        firstname
        lastname
        actif
        unavailabilities: partenaire_unavailabilities {
          all_day
          created_at
          date_end
          date_start
          id
          rrule
        }
        structure: partenaire_location {
          id
          name
        }
      }
      retour_partenaire_person {
        id
        email
        firstname
        lastname
        actif
        unavailabilities: partenaire_unavailabilities {
          all_day
          created_at
          date_end
          date_start
          id
          rrule
        }
        structure: partenaire_location {
          id
          name
        }
      }
      cancellation_reason
      cancelled
    }
  }
`
