// @ts-check

import Vue from 'vue'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'

export function loadSentry(router) {
  Sentry.init({
    Vue,
    dsn: 'https://34b9ddc49d3f46749bd21520adedb38d@o566450.ingest.sentry.io/4504571655684096',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ['localhost', process.env.VUE_APP_SENTRY_DOMAIN, /^\//],
      }),
      new CaptureConsole({
        levels: ['error'],
      }),
    ],
    logErrors: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1,
  })
}
