// @ts-check
import Vue from 'vue'
import { getEventsWithOccurrencesAndExceptions } from '@/domain/events/utils'

import { DateTime } from 'luxon'

const initialState = () => {
  return {
    demande_id: null,
    duration: null,
    recurrence: null,
    dates: [],
    date_start: undefined,
    date_end: undefined,
    event_type: null,
    partenaire_person: null,
    retour_partenaire_person: null,
    repit_mode: null,
    repit_address: null,
    mobility_mode: null,
    mobility_trip_type: null,
    mobility_signed: false,
    mobility_departure_1: null,
    mobility_arrival_1: null,
    mobility_departure_2: null,
    mobility_arrival_2: null,
    hma: null,
    cancelled: false,
    cancellation_reason: null,
    date: null,
    time: null,
  }
}
export default {
  namespaced: true,
  state: {
    event: initialState(),
    events: [],
    eventsLoading: false,
    filters: {
      reccurence: null,
      type: null,
      cancelled: false,
      relayeur: null,
      hma: null,
      structure: null,
      startDate: null,
      endDate: null,
    },
    relayeur: null,
    calendarDate: {
      start: undefined,
      end: undefined,
    },
  },
  getters: {
    events(state) {
      let events = state.events.filter((event) => event.cancelled === state.filters.cancelled)

      if (state.filters.type) {
        events = events.filter((event) => event.event_type === state.filters.type)
      }

      if (state.filters.reccurence === '00:00:00') {
        events = events.filter((event) => event.recurrence === state.filters.reccurence)
      } else if (state.filters.reccurence) {
        events = events.filter((event) => event.recurrence !== '00:00:00')
      }

      if (state.filters.relayeur) {
        events = events.filter(
          (event) => event.partenaire_person?.id === state.filters.relayeur?.id,
        )
      }

      if (state.filters.hma) {
        events = events.filter((event) => {
          const hma = event.demande?.demande_aidant_HMAs.find((demande) => {
            return demande.identity_aidant_HMA.type === 'HMA'
          })
          return hma?.identity_aidant_HMA.id === state.filters.hma.id
        })
      }

      if (state.filters.structure) {
        events = events.filter(
          (event) => event.partenaire_person?.structure?.id === state.filters?.structure.id,
        )
      }

      if (state.filters.startDate && state.filters.endDate) {
        const sdate = Date.parse(state.filters.startDate)
        const edate = Date.parse(state.filters.endDate)
        events = events.filter((event) => {
          const date = Date.parse(event.date)
          return date >= sdate && date <= edate
        })
      }

      return events
    },
    event(state) {
      return state.event
    },
    eventById: (state) => (id) => {
      return state.events.find((event) => event.id === id)
    },
    mode(state) {
      return state.mode
    },
    eventsLoading(state) {
      return state.eventsLoading
    },
    filters(state) {
      return state.filters
    },
    relayeur(state) {
      return state.relayeur
    },
    calendarDate(state) {
      return state.calendarDate
    },
  },
  mutations: {
    SET_CALENDAR_DATE(state, { start, end }) {
      state.calendarDate = { start, end }
    },
    INIT_EVENT(state) {
      state.event = initialState()
    },
    SET_EVENT(state, eventInfos) {
      Vue.set(state, 'event', { ...state.event, ...eventInfos })
    },
    UPDATE_EVENT_IN_EVENTS(state, newEvent) {
      const newEvents = state.events.map((event) => (event.id === newEvent?.id ? newEvent : event))

      const { calendarDate } = state

      Vue.set(
        state,
        'events',
        calendarDate.start && calendarDate.end
          ? getEventsWithOccurrencesAndExceptions(newEvents, calendarDate.start, calendarDate.end)
          : newEvents,
      )
    },
    SET_EVENTS(state, events) {
      state.events = events
    },
    SET_EVENTS_LOADING(state, loading) {
      state.eventsLoading = loading
    },
    UPDATE_FILTER(state, { key, value }) {
      state.filters[key] = value
    },
    ADD_EVENT(state, event) {
      state.events.push(event)
    },
  },
  actions: {
    addEvent({ commit }, event) {
      commit('ADD_EVENT', event)
    },
    setCalendarDate({ commit }, { start, end }) {
      commit('SET_CALENDAR_DATE', { start, end })
    },
    updateEventInEvents({ commit }, event) {
      commit('UPDATE_EVENT_IN_EVENTS', event)
    },
    initEvent({ commit }) {
      commit('INIT_EVENT')
    },
    setEvent({ commit }, event) {
      commit('SET_EVENT', event)
    },
    async getEvents(
      { state, commit },
      // eslint-disable-next-line unicorn/no-object-as-default-parameter
      { dateStart, dateEnd } = { dateStart: undefined, dateEnd: undefined },
    ) {
      const dateStartFilter =
        state.filters.startDate ||
        dateStart ||
        state.calendarDate.start ||
        DateTime.now().startOf('week').toISODate()
      const dateEndFilter =
        state.filters.endDate ||
        dateEnd ||
        state.calendarDate.end ||
        DateTime.now().endOf('week').toISODate()
      commit('SET_EVENTS_LOADING', true)

      /** @type {import('@/domain/events/types').Event[]} */
      const events = await this._vm.$api.events.getAll({
        dateStart: dateStartFilter,
        dateEnd: dateEndFilter,
      })

      commit('SET_EVENTS', events)
      return events
    },
    getEventsOnFormView(
      { commit },
      // eslint-disable-next-line unicorn/no-object-as-default-parameter
      { dateStart, dateEnd } = { dateStart: undefined, dateEnd: undefined },
    ) {
      const dateStartFilter = dateStart || DateTime.now().startOf('week').toISODate()
      const dateEndFilter = dateEnd || DateTime.now().endOf('week').toISODate()

      commit('SET_EVENTS_LOADING', true)

      return this._vm.$api.events.getAll({ dateStart: dateStartFilter, dateEnd: dateEndFilter })
    },
    getFilters({ commit }) {
      const filters = { type: null, canceled: false, relayeur: null }

      for (const [key, value] of Object.entries(filters)) {
        commit('UPDATE_FILTER', { key, value })
      }
    },
    setEventLoading({ commit }, value) {
      commit('SET_EVENTS_LOADING', value)
    },
    async getOne({ _state }, id) {
      const event2 = await this._vm.$api.events.getOne(id)

      let event = {
        ...event2,
        dates: [event2.date],
        demande_id: event2.demande_id,
      }
      event.person = event.partenaire_person
      if (event.person) {
        event.person.fullName = `${event.person.firstname} ${event.person.lastname}`
      }

      if (event.retour_partenaire_person) {
        event.retour_partenaire_person.fullName = `${event.retour_partenaire_person.firstname} ${event.retour_partenaire_person.lastname}`
      }

      if (event.demande) {
        const hmaType = event.demande?.demande_aidant_HMAs.find((demande) => {
          return demande.identity_aidant_HMA.type === 'HMA'
        })
        const hma =
          hmaType === undefined
            ? null
            : {
                id: hmaType.identity_aidant_HMA.id,
                firstname: hmaType.identity_aidant_HMA.firstname,
                lastname: hmaType.identity_aidant_HMA.lastname,
                fullName: `${hmaType.identity_aidant_HMA.firstname} ${hmaType.identity_aidant_HMA.lastname}`,
              }
        event = {
          ...event,
          hma,
        }
      }

      delete event.events_dates
      delete event.__typename
      return event
    },
  },
}
