import {
  GET_AIDANTS_STATS_BY_THEME,
  GET_HMA_STATS_BY_THEME,
  GET_EXCEL_GLOBAL_STATS,
  GET_EXCEL_THEME_STATS,
  GET_EXCEL_CONTACT_STATS,
  GET_RELAIS_MULTIPLES,
  GET_AIDANTS_STATS_BY_CONTACT,
  GET_HMA_STATS_BY_CONTACT,
  GET_AIDANT_TANDEM_DATA,
  GET_HMA_TANDEM_DATA,
  GET_AIDANT_NB_DEMANDES,
} from '@/graphql/excelStats'
import { apolloClient } from '@/plugins/apollo-client.plugin'
import { getUniqFromArray } from '@/utils/utilities'
import { mobilityModeOptions, repitModeOptions, mobilityTripTypeOptions } from '@/utils/data'

const initialIdentityStats = () => {
  return {
    total: 0,
    categories: {
      handicapée: 0,
      malade: 0,
      agée: 0,
    },
    sex: {
      F: 0,
      M: 0,
    },
    age: {
      '-10 ans': 0,
      '11-25 ans': 0,
      '26-40 ans': 0,
      '41-60 ans': 0,
      '61-80 ans': 0,
      '+80 ans': 0,
    },
  }
}
export default {
  namespaced: true,
  state: {
    aidantStats: { ...initialIdentityStats() },
    HMAStats: { ...initialIdentityStats() },
    globalStats: {},
  },
  getters: {
    aidantStats(state) {
      return state.aidantStats
    },
    HMAStats(state) {
      return state.HMAStats
    },
    globalStats(state) {
      return state.globalStats
    },
  },
  mutations: {},
  actions: {
    async getExcelAidantStatsByTheme({ state, _commit }, { begin, end, theme, type_id }) {
      state.aidantStats = initialIdentityStats()
      const response = await apolloClient.mutate({
        mutation: GET_AIDANTS_STATS_BY_THEME,
        variables: {
          begin,
          end,
          theme,
          type_id,
        },
      })
      return formatAidantHMAStats(response.data)
    },
    async getAidantNbDemandes({ _state, _commit }, { begin, end, type_id }) {
      const response = await apolloClient.mutate({
        mutation: GET_AIDANT_NB_DEMANDES,
        variables: {
          begin,
          end,
          type_id,
        },
      })
      return formatAidantNbDemandes(response.data.identity_demandes_count)
    },
    async getExcelAidantStatsByContact({ state, _commit }, { begin, end, contact_ids, type_id }) {
      state.aidantStats = initialIdentityStats()
      const response = await apolloClient.mutate({
        mutation: GET_AIDANTS_STATS_BY_CONTACT,
        variables: {
          begin,
          end,
          contact_ids,
          type_id,
        },
      })
      return formatAidantHMAStats(response.data)
    },
    async getExcelHMAStatsByTheme({ _state, _commit }, { begin, end, theme, type_id }) {
      const response = await apolloClient.mutate({
        mutation: GET_HMA_STATS_BY_THEME,
        variables: {
          begin,
          end,
          theme,
          type_id,
        },
      })
      return formatAidantHMAStats(response.data)
    },
    async getExcelHMAStatsByContact({ _state, _commit }, { begin, end, contact_ids, type_id }) {
      const response = await apolloClient.mutate({
        mutation: GET_HMA_STATS_BY_CONTACT,
        variables: {
          begin,
          end,
          contact_ids,
          type_id,
        },
      })
      return formatAidantHMAStats(response.data)
    },
    async getExcelGlobalStats({ _state, _commit }, { begin, end }) {
      const response = await apolloClient.mutate({
        mutation: GET_EXCEL_GLOBAL_STATS,
        variables: {
          begin,
          end,
        },
      })
      return formatGlobalStats(response.data)
    },
    async getExcelThemeStats({ _state, _commit }, { begin, end, theme }) {
      const response = await apolloClient.mutate({
        mutation: GET_EXCEL_THEME_STATS,
        variables: {
          begin,
          end,
          theme,
        },
      })
      return formatContactThemeStats(response.data)
    },
    async getExcelContactStats({ _state, _commit }, { begin, end, contact_ids }) {
      const response = await apolloClient.mutate({
        mutation: GET_EXCEL_CONTACT_STATS,
        variables: {
          begin,
          end,
          contact_ids,
        },
      })
      return formatContactThemeStats(response.data)
    },
    async getExcelRelaisMultiples({ _state, _commit }, { begin, end }) {
      const response = await apolloClient.mutate({
        mutation: GET_RELAIS_MULTIPLES,
        variables: {
          begin,
          end,
        },
      })
      return formatRelaisMultiple(response.data)
    },
    async getExcelGlobalTandemData({ _state, _commit }, { begin, end, type_id }) {
      const { data: aidants } = await apolloClient.mutate({
        mutation: GET_AIDANT_TANDEM_DATA,
        variables: {
          begin,
          end,
          type_id,
        },
      })
      const { data: hmas } = await apolloClient.mutate({
        mutation: GET_HMA_TANDEM_DATA,
        variables: {
          begin,
          end,
          type_id,
        },
      })
      return {
        hma: formatAidantHMAStats(hmas),
        aidant: formatAidantHMAStats(aidants),
      }
    },
    async getExcelEventStats({ _state, _commit }, { start_date, end_date }) {
      /** @type {import('./../domain/events/types').Event[]} */
      const events = await this._vm.$api.events.getAll({
        dateStart: start_date,
        dateEnd: end_date,
      })

      const notCancelledEvents = events.filter((event) => !event.cancelled)

      const sdate = Date.parse(start_date)
      const edate = Date.parse(end_date)
      const eventFilteredByDates = notCancelledEvents.filter((event) => {
        const date = Date.parse(event.date)
        return date >= sdate && date <= edate
      })

      const eventCountByHma = getEventCountByHma(eventFilteredByDates)
      const eventCountByVolunteer = getEventCountByVolunteer(eventFilteredByDates)
      const hmaCountByEventType = getHmaCountByEventType(eventFilteredByDates)
      const volunteerCountByEventType = getVolunteerCountByEventType(eventFilteredByDates)
      const repitEventModeCount = getRepitEventModeCount(eventFilteredByDates)
      const mobilityEventModeCount = getMobilityEventModeCount(eventFilteredByDates)
      const eventCountByCity = getEventCountByCity(eventFilteredByDates)

      return formatEventStats({
        eventCountByHma,
        eventCountByVolunteer,
        hmaCountByEventType,
        volunteerCountByEventType,
        repitEventModeCount,
        mobilityEventModeCount,
        eventCountByCity,
      })
    },
  },
}

/** @param {import('./../domain/events/types').Event[]} events */
function getEventCountByCity(events) {
  try {
    const eventCity = getPersonFromEventsByType(events, 'HMA')
      .map((person) => person?.cedex_city?.city)
      .filter(Boolean)

    return eventCity.reduce(
      (accumulator, city) => ({
        ...accumulator,
        [city]: accumulator[city] ? accumulator[city] + 1 : 1,
      }),
      {},
    )
  } catch (error) {
    throw new Error(`[excelStats](getEventCountByCity) ${error}`)
  }
}

/** @param {import('./../domain/events/types').Event[]} events */
function extractRepitAndMobilityEvents(events) {
  try {
    return {
      repitEvents: events.filter(({ event_type }) => event_type === 'repit'),
      mobilityEvents: events.filter(({ event_type }) => event_type === 'mobilite'),
    }
  } catch (error) {
    throw new Error(`[excelStats](extractRepitAndMobilityEvents) ${error}`)
  }
}

/** @param {import('./../domain/events/types').Event[]} events */
function getMobilityEventModeCount(events) {
  try {
    const { mobilityEvents } = extractRepitAndMobilityEvents(events)

    const defaultValue = mobilityModeOptions.reduce(
      (accumulator, { value, text }) =>
        value
          ? {
              ...accumulator,
              [text]: mobilityTripTypeOptions.reduce(
                (accumulator2, { value: value2, text: text2 }) =>
                  value2
                    ? {
                        ...accumulator2,
                        [text2]: 0,
                      }
                    : accumulator2,
                {},
              ),
            }
          : accumulator,
      {},
    )

    return mobilityEvents.reduce((accumulator, { mobility_trip_type, mobility_mode }) => {
      const modeText = mobilityModeOptions.find(({ value }) => value === mobility_mode).text
      const tripText = mobilityTripTypeOptions.find(
        ({ value }) => value === mobility_trip_type,
      ).text
      const newValue =
        accumulator[modeText] === undefined
          ? { [tripText]: 1 }
          : { ...accumulator[modeText], [tripText]: (accumulator[modeText][tripText] ?? 0) + 1 }

      return {
        ...accumulator,
        [modeText]: newValue,
      }
    }, defaultValue)
  } catch (error) {
    throw new Error(`[excelStats](getMobilityEventModeCount) ${error}`)
  }
}
/** @param {import('./../domain/events/types').Event[]} events */
function getRepitEventModeCount(events) {
  try {
    const { repitEvents } = extractRepitAndMobilityEvents(events)

    const repitModes = repitEvents.map(
      ({ repit_mode }) => repitModeOptions.find(({ value }) => value === repit_mode)?.text,
    )

    const repitModesCount = repitModes.reduce(
      (count, item) => ((count[item] = count[item] + 1 || 1), count),
      {},
    )

    return {
      ...repitModesCount,
      total: repitEvents.length,
    }
  } catch (error) {
    throw new Error(`[excelStats](getRepitEventModeCount) ${error}`)
  }
}

/** @param {import('./../domain/events/types').Event[]} events */
function getVolunteersFromEvents(events) {
  try {
    const mobilityPersons = events.map(({ partenaire_person }) => partenaire_person)
    const mobilityRetourPersons = events.map(
      ({ retour_partenaire_person }) => retour_partenaire_person,
    )

    return [...mobilityPersons, ...mobilityRetourPersons].filter(Boolean)
  } catch (error) {
    throw new Error(`[excelStats](getVolunteersFromEvents) ${error}`)
  }
}

/** @param {import('./../domain/events/types').Event[]} events */
function getEventCountByVolunteer(events) {
  try {
    const { repitEvents, mobilityEvents } = extractRepitAndMobilityEvents(events)

    const eventVolunteers = getVolunteersFromEvents(events)

    const uniqEventVolunteers = eventVolunteers.filter(
      (volunteer, index) =>
        index ===
        eventVolunteers.findIndex((hmaCompare) => {
          return hmaCompare.id === volunteer.id
        }),
    )

    const repitEventVolunteerIds = getVolunteersFromEvents(repitEvents).map(({ id }) => id)
    const mobilityEventVolunteerIds = getVolunteersFromEvents(mobilityEvents).map(({ id }) => id)

    const volunteersWithEventCount = []

    for (const volunteer of uniqEventVolunteers) {
      let mobilityEventCount = 0
      let repitEventCount = 0

      for (const id of repitEventVolunteerIds) {
        if (volunteer.id === id) {
          repitEventCount++
        }
      }

      for (const id of mobilityEventVolunteerIds) {
        if (volunteer.id === id) {
          mobilityEventCount++
        }
      }

      volunteersWithEventCount.push({
        ...volunteer,
        eventRepitCount: repitEventCount,
        eventMobilityCount: mobilityEventCount,
        total: repitEventCount + mobilityEventCount,
      })
    }

    return volunteersWithEventCount
  } catch (error) {
    throw new Error(`[excelStats](getEventCountByVolunteer) ${error}`)
  }
}

/** @param {import('./../domain/events/types').Event[]} events */
function getEventCountByHma(events) {
  try {
    const { repitEvents, mobilityEvents } = extractRepitAndMobilityEvents(events)

    const repitEventHmas = getPersonFromEventsByType(repitEvents, 'HMA')
    const mobilityEventHmas = getPersonFromEventsByType(mobilityEvents, 'HMA')

    const allHmas = [...repitEventHmas, ...mobilityEventHmas]

    const uniqHmas = allHmas.filter(
      (hma, index) =>
        index ===
        allHmas.findIndex((hmaCompare) => {
          return hmaCompare.id === hma.id
        }),
    )

    const repitEventHmaIds = repitEventHmas?.map(({ id }) => id).filter(Boolean)
    const mobilityEventHmaIds = mobilityEventHmas?.map(({ id }) => id).filter(Boolean)

    const hmasWithEventCount = []

    for (const hma of uniqHmas) {
      let mobilityEventCount = 0
      let repitEventCount = 0

      for (const id of repitEventHmaIds) {
        if (hma.id === id) {
          repitEventCount++
        }
      }

      for (const id of mobilityEventHmaIds) {
        if (hma.id === id) {
          mobilityEventCount++
        }
      }

      hmasWithEventCount.push({
        ...hma,
        eventRepitCount: repitEventCount,
        eventMobilityCount: mobilityEventCount,
        total: repitEventCount + mobilityEventCount,
      })
    }

    return hmasWithEventCount
  } catch (error) {
    throw new Error(`[excelStats](getEventCountByHma) ${error}`)
  }
}

/**
 * @param {import('./../domain/events/types').Event[]} events
 * @param {'HMA' | 'aidant'} type
 */
function getPersonFromEventsByType(events, type) {
  try {
    return events
      .map(
        (event) =>
          event.demande?.demande_aidant_HMAs?.find(
            ({ identity_aidant_HMA }) => identity_aidant_HMA.type === type,
          )?.identity_aidant_HMA,
      )
      .filter(Boolean)
  } catch (error) {
    throw new Error(`[excelStats](getPersonFromEventsByType) ${error}`)
  }
}

/** @param {import('./../domain/events/types').Event[]} events */
function getPartnerOfEvents(events) {
  try {
    const partnerIds = []

    for (const event of events) {
      partnerIds.push(event.relayeur_id, event.relayeur_retour_id)
    }

    return partnerIds.filter(Boolean)
  } catch (error) {
    throw new Error(`[excelStats](getPartnerOfEvents) ${error}`)
  }
}

/** @param {import('./../domain/events/types').Event[]} events */
function getVolunteerCountByEventType(events) {
  try {
    const { repitEvents, mobilityEvents } = extractRepitAndMobilityEvents(events)

    const repitVolunteerIds = getPartnerOfEvents(repitEvents)
    const mobilityVolunteerIds = getPartnerOfEvents(mobilityEvents)
    const allVolunteerIds = getPartnerOfEvents(events)

    const filteredBothVolunteerIds = allVolunteerIds.filter(
      (id) => repitVolunteerIds.includes(id) && mobilityVolunteerIds.includes(id),
    )

    const filteredRepitVolunteerIds = repitVolunteerIds.filter(
      (id) => !mobilityVolunteerIds.includes(id) && !filteredBothVolunteerIds.includes(id),
    )

    const filteredMobilityVolunteerIds = mobilityVolunteerIds.filter(
      (id) => !repitVolunteerIds.includes(id) && !filteredBothVolunteerIds.includes(id),
    )

    const volunteerCountForBoth = getUniqFromArray(filteredBothVolunteerIds).length
    const volunteerCountForRepit = getUniqFromArray(filteredRepitVolunteerIds).length
    const volunteerCountForMobility = getUniqFromArray(filteredMobilityVolunteerIds).length

    return [
      { event_type: 'Répit', total: volunteerCountForRepit },
      { event_type: 'Mobilité', total: volunteerCountForMobility },
      { event_type: 'Les deux', total: volunteerCountForBoth },
    ]
  } catch (error) {
    throw new Error(`[excelStats](getVolunteerCountByEventType) ${error}`)
  }
}

/** @param {import('./../domain/events/types').Event[]} events */
function getHmaCountByEventType(events) {
  try {
    const { repitEvents, mobilityEvents } = extractRepitAndMobilityEvents(events)

    const repitHmaIds = getPersonFromEventsByType(repitEvents, 'HMA').map(({ id }) => id)
    const mobilityHmaIds = getPersonFromEventsByType(mobilityEvents, 'HMA').map(({ id }) => id)

    return [
      { event_type: 'Répit', total: getUniqFromArray(repitHmaIds).length },
      { event_type: 'Mobilité', total: getUniqFromArray(mobilityHmaIds).length },
    ]
  } catch (error) {
    throw new Error(`[excelStats](getHmaCountByEventType) ${error}`)
  }
}

function formatGlobalStats(globalStats) {
  try {
    const formatedStats = { life_domains: [], beneficiaires: {}, demande_type: [] }
    formatedStats.total = globalStats.demandes.aggregate.count
    for (const domain of globalStats.life_domains) {
      formatedStats.life_domains.push([
        domain.name,
        percentage(domain.nombre.aggregate.count, formatedStats.total) + '%',
        domain.nombre.aggregate.count,
      ])
    }
    formatedStats.beneficiaires.total =
      globalStats.aidants.aggregate.count +
      globalStats.HMA.aggregate.count +
      globalStats.partenaires.aggregate.count
    formatedStats.beneficiaires.aidants =
      percentage(globalStats.aidants.aggregate.count, formatedStats.beneficiaires.total) + '%'
    formatedStats.beneficiaires.HMA =
      percentage(globalStats.HMA.aggregate.count, formatedStats.beneficiaires.total) + '%'
    formatedStats.beneficiaires.partenaires =
      percentage(globalStats.partenaires.aggregate.count, formatedStats.beneficiaires.total) + '%'
    for (const type of globalStats.demande_type) {
      formatedStats.demande_type.push([
        type.name,
        percentage(type.nombre.aggregate.count, formatedStats.total) + '%',
      ])
    }
    formatedStats.solutions_adapt = globalStats.solutions_adapt
    return formatedStats
  } catch (error) {
    throw new Error(`[excelStats](formatGlobalStats) ${error}`)
  }
}

function formatContactThemeStats(stats) {
  const formatedStats = { life_domains: [], demande_source: [], demande_type: [], comments: {} }
  formatedStats.total = stats.demandes.aggregate.count
  formatedStats.HMA = stats.HMA.aggregate.count
  formatedStats.aidants = stats.aidants.aggregate.count
  for (const type of stats.demande_type) {
    formatedStats.demande_type.push([type.name + ' : ', type.nombre.aggregate.count])
  }
  for (const source of stats.demande_source) {
    formatedStats.demande_source.push([source.name + ' : ', source.nombre.aggregate.count])
  }
  for (const domain of stats.life_domains) {
    formatedStats.life_domains.push([domain.name + ' : ', domain.nombre.aggregate.count])
  }
  if (stats.comments.length > 0) {
    stats.comments.sort(
      (a, b) =>
        a.demande_comments_aggregate.aggregate.count - b.demande_comments_aggregate.aggregate.count,
    )
    formatedStats.comments.moy = (
      stats.total_comments.aggregate.count / formatedStats.total
    ).toFixed(1)
    formatedStats.comments.max =
      stats.comments[stats.comments.length - 1].demande_comments_aggregate.aggregate.count
    formatedStats.comments.min = stats.comments[0].demande_comments_aggregate.aggregate.count
  } else {
    formatedStats.comments.moy = 0
    formatedStats.comments.max = 0
    formatedStats.comments.min = 0
  }
  formatedStats.solution = extractType(stats.solutions)
  formatedStats.pas_de_mise_en_lien = stats.pas_de_mise_en_lien.aggregate.count
  formatedStats.recherche_information = stats.recherche_information.aggregate.count
  return formatedStats
}
function formatCitiesStats(cities, _total) {
  const citiesStats = []

  for (const city of cities) {
    if (city.number.aggregate.count) {
      citiesStats.push([city.city + ' : ', city.number.aggregate.count])
    }
  }
  return citiesStats.sort((a, b) => b.percentage - a.percentage)
}

function percentage(partialValue, totalValue) {
  let result = ((100 * partialValue) / totalValue).toFixed(1)
  if (result === 'NaN') result = 0
  return result
}

function getAge(dateString) {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}
function extractType(statArray) {
  const typeStats = {}
  const resultArray = []
  for (const stat of statArray) {
    const type = stat.partenaire_person.partenaire_location.app_dropdown
      ? stat.partenaire_person.partenaire_location.app_dropdown.name
      : null
    if (typeStats[type] && type) typeStats[type]++
    else if (type) typeStats[type] = 1
  }
  for (const type in typeStats) {
    resultArray.push([type + ' : ', typeStats[type]])
  }
  return resultArray
}

function formatRelaisMultiple(lists) {
  const mobiliteRepit = compareArrays(lists.listRepit, lists.listMobilite)
  const repitRessources = compareArrays(lists.listRepit, lists.listRessources)
  const mobiliteRessources = compareArrays(lists.listMobilite, lists.listRessources)
  const repitRessourcesMobilite = compareArrays(repitRessources, lists.listMobilite)
  return {
    mobiliteRessources,
    mobiliteRepit,
    repitRessources,
    repitRessourcesMobilite,
  }
}

function compareArrays(array1, array2) {
  const temporaryArray = []
  for (const item1 of array1) {
    for (const item2 of array2) {
      if (item1.id_aidant_HMA === item2.id_aidant_HMA) temporaryArray.push(item1)
    }
  }
  return temporaryArray
}

function formatAidantHMAStats(stats) {
  const identityStats = {
    family_link: [],
    identity_status: [],
    categories: {
      handicapée: 0,
      malade: 0,
      agée: 0,
      handiMalade: 0,
      handiAgee: 0,
      maladeAgee: 0,
      handiMaladeAgee: 0,
    },
    sex: {
      F: 0,
      M: 0,
    },
    age: {
      '-10 ans': 0,
      '11-25 ans': 0,
      '26-40 ans': 0,
      '41-60 ans': 0,
      '61-80 ans': 0,
      '+80 ans': 0,
    },
  }
  identityStats.total = stats.all.aggregate.count
  if (stats.family_link)
    for (const link of stats.family_link)
      identityStats.family_link.push([link.name + ' : ', link.number.aggregate.count])
  else delete identityStats.family_link
  if (stats.identity_status)
    for (const status of stats.identity_status)
      identityStats.identity_status.push([status.name + ' : ', status.number.aggregate.count])
  else delete identityStats.identity_status
  if (stats.city) identityStats.cities = formatCitiesStats(stats.city, identityStats.total)
  const sexArray = [...stats.females, ...stats.males]
  for (const { identity } of sexArray) {
    if (identity.category) {
      const categories = identity.category.split(';')
      if (categories.length > 1) {
        if (
          categories.includes('agée') &&
          categories.includes('handicapée') &&
          categories.includes('malade')
        )
          identityStats.categories.handiMaladeAgee++
        else if (categories.includes('handicapée') && categories.includes('malade'))
          identityStats.categories.handiMalade++
        else if (categories.includes('handicapée') && categories.includes('agée'))
          identityStats.categories.handiAgee++
        else if (categories.includes('malade') && categories.includes('agée'))
          identityStats.categories.maladeAgee++
      } else
        for (const category of categories) {
          identityStats.categories[category]++
        }
    }
    identityStats.sex[`${identity.sex}`]++
    const age = getAge(identity.birthday)
    if (age <= 10) identityStats.age['-10 ans']++
    else if (age <= 25) identityStats.age['11-25 ans']++
    else if (age <= 40) identityStats.age['26-40 ans']++
    else if (age <= 60) identityStats.age['41-60 ans']++
    else if (age <= 80) identityStats.age['61-80 ans']++
    else if (age > 80) identityStats.age['+80 ans']++
  }

  for (const category in identityStats.categories) {
    identityStats.categories[category] = {
      count: identityStats.categories[category],
      percentage: percentage(identityStats.categories[category], identityStats.total),
    }
  }
  for (const sex in identityStats.sex) {
    identityStats.sex[sex] = {
      count: identityStats.sex[sex],
      percentage: percentage(identityStats.sex[sex], identityStats.total),
    }
  }
  for (const age in identityStats.age) {
    identityStats.age[age] = {
      count: identityStats.age[age],
      percentage: percentage(identityStats.age[age], identityStats.total),
    }
  }

  return identityStats
}

function formatAidantNbDemandes(aidants) {
  const nbDemandesStats = {}
  for (const {
    demande_aidant_HMAs_aggregate: {
      aggregate: { count },
    },
  } of aidants) {
    if (nbDemandesStats[count]) {
      nbDemandesStats[count]++
    } else {
      nbDemandesStats[count] = 1
    }
  }

  return nbDemandesStats
}

function formatEventStats(eventStats) {
  const statsHmaCountByEventType = eventStats.hmaCountByEventType.map((stat) => {
    return {
      Évenement: stat.event_type,
      Total: stat.total,
    }
  })

  const statsVolunteerCountByEventType = eventStats.volunteerCountByEventType.map((stat) => {
    return {
      Évenement: stat.event_type,
      Total: stat.total,
    }
  })

  const statsEventCountByHma = eventStats.eventCountByHma.map((hma) => {
    return {
      Prénom: hma.firstname,
      Nom: hma.lastname,
      Email: hma.email,
      Répit: hma.eventRepitCount,
      Mobilité: hma.eventMobilityCount,
      Total: hma.total,
    }
  })

  const statsEventCountByCity = Object.entries(eventStats.eventCountByCity).map(([key, value]) => ({
    Ville: key,
    Total: value,
  }))

  const statsEventCountByVolunteer = eventStats.eventCountByVolunteer.map((stat) => {
    return {
      Prénom: stat.firstname,
      Nom: stat.lastname,
      Email: stat.email,
      Structure: stat.structure?.name,
      Répit: stat.eventRepitCount,
      Mobilité: stat.eventMobilityCount,
      Total: stat.total,
    }
  })

  const statsRepitModeByEvent = Object.entries(eventStats.repitEventModeCount).map(
    ([key, count]) => ({
      'Mode de répit': key,
      Total: count,
    }),
  )

  const statsMobilityModeCount = Object.entries(eventStats.mobilityEventModeCount).map(
    ([key, value]) => {
      return {
        'Mode de mobilité': key,
        ...value,
        Total: Object.values(value).reduce((partialSum, a) => partialSum + a, 0),
      }
    },
  )

  return {
    statsHmaCountByEventType,
    statsEventCountByHma,
    statsEventCountByCity,
    statsEventCountByVolunteer,
    statsRepitModeByEvent,
    statsVolunteerCountByEventType,
    statsMobilityModeCount,
  }
}
