import {
  ADD_IDENTITY,
  SEARCH_IDENTITY_BY_NAME,
  ADD_IDENTITY_COMMENT,
  UPDATE_IDENTITY,
  GET_IDENTITY_DETAILS,
} from '@/graphql/identity'

const initialState = () => {
  return {
    sex: null,
    firstname: null,
    lastname: null,
    adress: null,
    cedex_city_id: null,
    hebergement: null,
    building_floor: null,
    access_codes: null,
    phone_number: null,
    birthday: null,
    email: null,
    status_id: null,
    category: [],
    family_link_id: null,
    host_id: null,
  }
}

export default {
  namespaced: true,
  state: {
    identity: initialState(),
    identityList: [],
    sameAdress: false,
    anonym: false,
  },
  getters: {
    identity(state) {
      return state.identity
    },
    identityList(state) {
      return state.identityList.map((identity) => {
        return {
          ...identity,
          fullName: `${identity.firstname || ''} ${identity.lastname || ''}`,
        }
      })
    },
    getSameAdress(state) {
      return state.sameAdress
    },
    isAnonym(state) {
      return state.anonym
    },
  },
  mutations: {
    setIdentity(state, identity) {
      state.identity = identity
    },
    updateIdentity(state, identity) {
      state.identity = { ...state.identity, ...identity }
    },
    addType(state) {
      state.identity.type = 'HMA'
    },
    setSameAdress(state, value) {
      state.sameAdress = value
    },
    joinCategory(state) {
      const category = state.identity.category
      state.identity.category = !category || category.length === 0 ? null : category.join(';')
    },
    resetHMA(state) {
      state.identity = initialState()
    },
    SET_IDENTITY_SEARCH(state, identityList) {
      state.identityList = identityList
    },
    SET_ANONYM(state, value) {
      state.anonym = value
    },
  },
  actions: {
    async insertIdentity({ state, commit, _rootState }) {
      if (state.identity !== null) {
        commit('addType')
        commit('joinCategory')
        const response = await this._vm.$apollo.mutate({
          mutation: ADD_IDENTITY,
          variables: {
            identity: state.identity,
          },
        })
        return response.data.insert_identity_aidant_HMA.returning[0]
      }
    },
    async storeIdentity({ commit }, identity) {
      await commit('setIdentity', identity)
    },
    async appendIdentity({ commit }, identity) {
      await commit('updateIdentity', identity)
    },
    resetHMA({ commit }) {
      commit('resetHMA')
    },
    async searchIdentityByName({ commit }, { name }) {
      const response = await this._vm.$apollo.mutate({
        mutation: SEARCH_IDENTITY_BY_NAME,
        variables: {
          name,
        },
      })
      commit('SET_IDENTITY_SEARCH', response.data.identities)
    },
    async insertComment({ state }) {
      if (state.identity.comment !== '' && state.identity.comment !== undefined) {
        const comments = [
          {
            content: state.identity.comment,
            aidant_HMA_id: state.identity.id,
          },
        ]
        await this._vm.$apollo.mutate({
          mutation: ADD_IDENTITY_COMMENT,
          variables: {
            comments,
          },
        })
      }
    },
    async updateIdentity({ state }, { hma_id, aidant_id }) {
      if (state.hebergement !== null) {
        this._vm.$apollo.mutate({
          mutation: UPDATE_IDENTITY,
          variables: {
            id: hma_id,
            identity: {
              host_id: aidant_id,
            },
          },
        })
      }
    },
    async updateAddress({ state }, { hma_id, address }) {
      if (state.hebergement !== null) {
        this._vm.$apollo.mutate({
          mutation: UPDATE_IDENTITY,
          variables: {
            id: hma_id,
            identity: address,
          },
        })
      }
    },
    async getIdentity({ commit }, id) {
      const response = await this._vm.$apollo.query({
        query: GET_IDENTITY_DETAILS,
        variables: {
          id_identity: id,
        },
      })
      await commit('setIdentity', response.data.identity_aidant_HMA)
    },
  },
}
