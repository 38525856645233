// @ts-check

import { getFetchInstance } from '@/services/get-fetch-instance'

export class EmailsProvider {
  /** @type {ReturnType<typeof import('@/services/get-fetch-instance').getFetchInstance>} */
  apiEmails = undefined

  constructor() {
    this.apiEmails = getFetchInstance({ baseUrl: process.env.VUE_APP_MAIL_URL })
  }

  /** @param {import('./types').MailBodyWithReceiverPartner} body */
  sendEmail(body) {
    try {
      return this.apiEmails.post('/send', body)
    } catch (error) {
      throw new Error(`[EmailsProvider](sendEmail) ${error}`)
    }
  }
}
