export const recurrenceOptions = [
  { value: null, text: 'Choisissez une récurrence' },
  { value: '00:00:00', text: 'Ponctuelle' },
  { value: '7 days', text: 'Toutes les semaines' },
  { value: '14 days', text: 'Toutes les deux semaines' },
]

export const repitModeOptions = [
  { value: null, text: 'Choisissez un mode' },
  { value: 1, text: 'Répit régulier' },
  { value: 2, text: 'Répit ponctuel' },
  { value: 3, text: 'VAD' },
  { value: 4, text: 'Présentation bénévole' },
]

export const mobilityTripTypeOptions = [
  { value: null, text: 'Choisissez un mode de trajet' },
  { value: 1, text: 'Trajet A/R' },
  { value: 2, text: 'Trajet aller' },
  { value: 3, text: 'Trajet retour' },
]

export const mobilityModeOptions = [
  { value: null, text: 'Choisissez un mode' },
  { value: 1, text: 'Trajet à pied' },
  { value: 2, text: 'En voiture' },
  { value: 3, text: 'En taxi' },
  { value: 4, text: 'En transport en commun' },
  { value: 5, text: 'Autres' },
]

export const eventTypeOptions = [
  { value: null, text: "Choisir le type d'évènement" },
  { value: 'repit', text: 'Répit' },
  { value: 'mobilite', text: 'Mobilité' },
]
