import {
  GET_PARTENAIRE_BY_ID,
  // MODIF_PARTENAIRE_DETAIL,
  MODIF_STRUCTURE_DETAIL,
  MODIF_PERSON_DETAIL,
  SET_OBSOLETE,
  EDIT_PARTENAIRE_PERSON,
  ADD_PARTENAIRE_PERSON,
  CREATE_PERSON_ACTIF_HISTORY,
  DELETE_PARTENAIRE_PERSON,
  UPDATE_ADHESIONS,
  UPDATE_DOCUMENTS,
  DELETE_DOCUMENT,
  DELETE_UNAVAILABILITY,
} from '@/graphql/partenaire'

const defaultContact = () => {
  return {
    id: -1,
    firstName: '',
    lastName: '',
    profession: '',
    phoneFirst: '',
    phoneSecond: '',
    email: '',
    service: '',
    sex: true,
    phoneFirstNC: false,
    phoneSecondNC: false,
  }
}

const initialCardState = () => {
  return {
    id: -1,
    name: '',
    structureType: '',
    adress: '',
    cedex: '',
    phoneFirst: '',
    phoneSecond: '',
    email: '',
    obsolete: false,
    lastUpdate: '',
    contacts: [defaultContact()],
    lifeDomains: [],
    comment: '',
    town: '',
    keywords: [],
    birth_date: '',
    entry_date: '',
    exit_date: '',
  }
}

const initialPerson = () => {
  return {
    email: null,
    firstname: null,
    id: null,
    lastname: null,
    service: null,
    phone_number1: null,
    phone_number2: null,
    profession: null,
    sex: null,
    dontCommunicatePhoneNumber1: false,
    dontCommunicatePhoneNumber2: false,
    dontCommunicateEmail: false,
    // unavailabilities: []
  }
}

const initialStructure = () => {
  return {
    id: null,
    email: null,
    name: null,
    comment: null,
    rencontre: null,
    complement: null,
    cedex_city_id: null,
    adress: null,
    phone_number1: null,
    phone_number2: null,
    dontCommunicatePhoneNumber1: false,
    dontCommunicatePhoneNumber2: false,
    dontCommunicateEmail: false,
    obsolete: null,
    structure_type_id: null,
  }
}
export default {
  namespaced: true,
  state: {
    identity: null,
    partenaire: initialCardState(),
    person: initialPerson(),
    structure: initialStructure(),
    lifeDomainList: [],
    previousActif: null,
    adhesions: [],
    documents: [],
    unavailabilities: [],
  },
  getters: {
    documents(state) {
      return state.documents
    },
    lastAdhesion(state) {
      return state.partenaire.adhesions[state.partenaire.adhesions.length - 1]
    },
    lastDocument(state) {
      return state.partenaire.documents[state.partenaire.documents.length - 1]
    },
    partenaire(state) {
      return state.partenaire
    },
    person(state) {
      return state.person
    },
    structure(state) {
      return state.structure
    },
    contacts(state) {
      if (state.partenaire) return state.partenaire.contacts
    },
    unAvailabilities(state) {
      return state.partenaire.unAvailabilities
    },
  },
  mutations: {
    UNSET_PARTENAIRE(state) {
      state.partenaire = null
    },
    SET_INFO_PARTENAIRE(state, card) {
      state.previousActif = card.actif
      state.partenaire = card
    },
    SET_INFO_PERSON(state, person) {
      state.person = person
    },
    SET_INFO_STRUCTURE(state, structure) {
      state.structure = structure
    },
    SET_PARTENAIRE_KEYWORDS(state, keywords) {
      state.partenaire.keywords = keywords
    },
    SET_PARTENAIRE_LIFEDOMAINS(state, lifeDomains) {
      state.partenaire.lifeDomains = lifeDomains
    },
    SET_PARTENAIRE_PEOPLE(state, people) {
      state.partenaire.contacts = people
    },
    SET_LAST_UPDATE(state, newDate) {
      state.partenaire.lastUpdate = newDate
    },
    RESET_INITIAL_STATE(state) {
      state.partenaire = initialCardState()
    },
    ADD_CONTACT(state, newcontact) {
      state.partenaire.contacts.push(newcontact)
    },
    SET_ADHESIONS(state, adhesions) {
      state.adhesions = adhesions
    },
    SET_DOCUMENTS(state, documents) {
      state.documents = documents
    },
    SET_UNAVAILABILITIES(state, unavailabilities) {
      state.unavailabilities = unavailabilities
    },
  },
  actions: {
    async getDetailData({ commit, dispatch }, id) {
      const response = await this._vm.$apollo.query({
        query: GET_PARTENAIRE_BY_ID,
        variables: {
          id: id,
        },
        fetchPolicy: 'no-cache',
      })

      if (response.data.partenaire_person) {
        dispatch('parseDetailData', response.data.partenaire_person[0])
        return response.data.partenaire_person
      } else {
        commit('RESET_INITIAL_STATE')
      }
    },
    editStructureDetails(_, { structure, id }) {
      return this._vm.$apollo.mutate({
        mutation: MODIF_STRUCTURE_DETAIL,
        variables: {
          structure,
          id,
        },
      })
    },
    async editPersonDetails({ dispatch, state }, { person, id, keywords }) {
      delete person.adhesions
      delete person.documents

      const unavailabilities = state.unavailabilities
        .map((element) => {
          return element.id === -1
            ? {
                rrule: element.rrule,
                date_start: element.date_start,
                date_end: element.date_end,
                all_day: element.all_day,
                person_id: id,
              }
            : {
                rrule: element.rrule,
                date_start: element.date_start,
                date_end: element.date_end,
                id: element.id,
                all_day: element.all_day,
                person_id: id,
              }
        })
        .filter((element) => element.date_start !== '' && element.date_end !== '')

      delete person.unavailabilities
      /* Update partenaires */
      await this._vm.$apollo.mutate({
        mutation: MODIF_PERSON_DETAIL,
        variables: {
          person,
          id,
          keywords,
          unavailabilities,
        },
      })

      /* Update actif history */
      if (state.previousActif !== person.actif) {
        this._vm.$apollo.mutate({
          mutation: CREATE_PERSON_ACTIF_HISTORY,
          variables: {
            object: {
              partenaire_person_id: state.partenaire.id,
              actif: person.actif,
            },
          },
        })
      }

      /* Update adhesions */
      const oldAdhesions = state.adhesions
        .filter((adhesion) => adhesion.id !== -1)
        .map((adhesion) => {
          return {
            id: adhesion.id,
            start_date: adhesion.start_date,
            end_date: adhesion.end_date,
            partenaire_person_id: id,
          }
        })

      const newAdhesions = state.adhesions
        .filter(
          (adhesion) =>
            adhesion.id === -1 && adhesion.start_date !== '' && adhesion.end_date !== '',
        )
        .map((adhesion) => {
          return {
            start_date: adhesion.start_date,
            end_date: adhesion.end_date,
            partenaire_person_id: id,
          }
        })
      const adhesions = [...oldAdhesions, ...newAdhesions]

      if (adhesions.length > 0) {
        await this._vm.$apollo.mutate({
          mutation: UPDATE_ADHESIONS,
          variables: {
            adhesions,
          },
        })
      }

      /* Update Documents */
      const oldDocuments = state.documents
        .filter((document) => document.id !== -1)
        .map((document) => {
          return {
            id: document.id,
            year: document.year,
            ndf: document.ndf,
            insurance: document.insurance,
            criminal_record: document.criminal_record,
            partenaire_person_id: id,
          }
        })

      const newDocuments = state.documents
        .filter((document) => document.id === -1 && document.year !== '')
        .map((document) => {
          return {
            year: document.year,
            ndf: document.ndf,
            insurance: document.insurance,
            criminal_record: document.criminal_record,
            partenaire_person_id: id,
          }
        })
      const documents = [...oldDocuments, ...newDocuments]

      if (documents.length > 0) {
        await this._vm.$apollo.mutate({
          mutation: UPDATE_DOCUMENTS,
          variables: {
            documents,
          },
        })
      }

      dispatch('getDetailData', id)
      // empty the documents states to avoid duplicate enteries
      dispatch('updateDocuments', [])
      dispatch('updateAdhesions', [])
    },
    async setObsolete({ dispatch }, id) {
      await this._vm.$apollo.mutate({
        mutation: SET_OBSOLETE,
        variables: { id },
      })
      dispatch('getDetailData', id)
    },
    async editContact({ dispatch, state }, contact) {
      await this._vm.$apollo.mutate({
        mutation: EDIT_PARTENAIRE_PERSON,
        variables: {
          email: contact.email,
          firstName: contact.firstName,
          lastName: contact.lastName,
          phone1NC: contact.phoneFirstNC,
          phone2NC: contact.phoneSecondNC,
          phone1: contact.phoneFirst,
          phone2: contact.phoneSecond,
          profession: contact.profession,
          service: contact.service,
          sex: contact.sex ? 'M' : 'F',
          id: contact.id,
        },
      })
      //dispatch partenaire person
      dispatch('getDetailData', state.partenaire.id)
    },
    async addNewContact({ dispatch, state }, contact) {
      const partenairePerson = {
        sex: contact.sex ? 'M' : 'F',
        firstname: contact.firstName,
        lastname: contact.lastName,
        phone_number1: contact.phoneFirst,
        phone_number2: contact.phoneSecond,
        dontCommunicatePhoneNumber1: contact.phoneFirstNC,
        dontCommunicatePhoneNumber2: contact.phoneSecondNC,
        email: contact.email,
        profession: contact.profession,
        service: contact.service,
        location_id: state.partenaire.id,
      }
      await this._vm.$apollo.mutate({
        mutation: ADD_PARTENAIRE_PERSON,
        variables: {
          partenaire_person: partenairePerson,
        },
      })
      dispatch('getDetailData', state.partenaire.id)
    },
    async deleteContact(_, extid) {
      await this._vm.$apollo.mutate({
        mutation: DELETE_PARTENAIRE_PERSON,
        variables: { id: extid },
      })
      // dispatch('getDetailData', partenaireID)
    },
    async deleteDocument(_context, id) {
      await this._vm.$apollo.mutate({
        mutation: DELETE_DOCUMENT,
        variables: { id },
      })
    },
    async deleteUnavailabilities(_context, id) {
      await this._vm.$apollo.mutate({
        mutation: DELETE_UNAVAILABILITY,
        variables: { id },
      })
    },
    addDefaultContact({ commit }) {
      const contact = {
        firstName: '',
        lastName: '',
        profession: '',
        phoneFirst: '',
        phoneSecond: '',
        email: '',
        service: '',
        id: -1,
        isEdit: true,
        sex: true,
        phoneFirstNC: false,
        phoneSecondNC: false,
      }
      commit('ADD_CONTACT', contact)
    },
    async parseDetailData({ commit, dispatch }, detail) {
      //
      detail.updated_at = await dispatch('parseLastUpdateDate', detail.updated_at)
      detail.structure.updated_at = await dispatch(
        'parseLastUpdateDate',
        detail.structure.updated_at,
      )
      commit('SET_INFO_PARTENAIRE', detail)
      if (detail.person_keywords) dispatch('parsePartenaireKeywords', detail.person_keywords)
      if (detail?.partenaire_actif_histories[0]) {
        detail.partenaire_actif_histories[0].created_at = await dispatch(
          'parseLastUpdateDate',
          detail.partenaire_actif_histories[0].created_at,
        )
      }
    },
    parsePartenaireKeywords({ commit }, extkeywords) {
      const keywords = extkeywords.map((keyword) => {
        return {
          label: keyword.keyword.label,
          id: keyword.keyword.id,
        }
      })
      commit('SET_PARTENAIRE_KEYWORDS', keywords)
    },
    parsePartenairePeople({ commit }, extpeoples) {
      const peoples = extpeoples.map((person) => {
        return {
          firstName: person.firstname,
          lastName: person.lastname,
          profession: person.profession,
          phoneFirst: person.phone_number1 ?? '-',
          phoneSecond: person.phone_number2 ?? '-',
          email: person.email ?? '-',
          service: person.service,
          id: person.id,
          sex: person.sex === 'M' ? true : false,
          phoneFirstNC: person.dontCommunicatePhoneNumber1,
          phoneSecondNC: person.dontCommunicatePhoneNumber2,
        }
      })
      commit('SET_PARTENAIRE_PEOPLE', peoples)
    },
    parseLastUpdateDate(_, date) {
      const temporaryDate = new Date(date)
      const day =
        temporaryDate.getDate() < 10 ? '0' + temporaryDate.getDate() : temporaryDate.getDate()
      const month =
        temporaryDate.getMonth() + 1 < 10
          ? '0' + (temporaryDate.getMonth() + 1)
          : temporaryDate.getMonth() + 1
      return `${day}/${month}/${temporaryDate.getFullYear().toString().slice(-2)}`
      // commit("SET_LAST_UPDATE", newDate);
    },
    unsetPartenaire({ commit }) {
      commit('UNSET_PARTENAIRE')
    },
    updateAdhesions({ commit }, adhesions) {
      commit('SET_ADHESIONS', adhesions)
    },
    updateDocuments({ commit }, documents) {
      commit('SET_DOCUMENTS', documents)
    },
    updateUnavailabilities({ commit }, unavailabilities) {
      commit('SET_UNAVAILABILITIES', unavailabilities)
    },
  },
}
