// @ts-check

import { GC_AUTH_TOKEN } from '@/constants/settings'
import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  base: '/app/',
  routes: [
    {
      path: '/auth',
      name: 'auth',
      component: () =>
        import(/* webpackChunkName: "Authentication" */ '@/views/Authentication.vue'),
      meta: { layout: 'no-layout' },
    },
    {
      path: '/',
      name: 'home',
      meta: { layout: 'app-layout' },
      component: () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue'),
    },
    {
      path: '/aidants-HMA',
      name: 'aidants-HMA',
      meta: { layout: 'app-layout' },
      component: () => import(/* webpackChunkName: "AidantsHMA" */ '@/views/AidantsHMA.vue'),
    },
    {
      path: '/demande',
      meta: { layout: 'app-layout' },
      component: () => import(/* webpackChunkName: "Demande" */ '@/views/Demande.vue'),
      children: [
        // {
        //   path: '/',
        //   name: 'demande',
        // meta: { layout: 'app-layout' },
        //   component: () => import(/* webpackChunkName: "Demande" */ '@/views/demande/Index.vue'),
        // },
        {
          path: '/',
          name: 'demande_creation',
          meta: { layout: 'app-layout' },
          component: () =>
            import(/* webpackChunkName: "demande creation" */ '@/views/demande/Creation.vue'),
        },
        {
          path: ':id/type',
          name: 'demande_type',
          meta: { layout: 'app-layout' },
          component: () =>
            import(/* webpackChunkName: "demande creation" */ '@/views/demande/Type.vue'),
        },
      ],
    },

    {
      path: '/historique',
      name: 'historique',
      meta: { layout: 'app-layout' },
      component: () => import(/* webpackChunkName: "Historique" */ '@/views/Historique.vue'),
    },
    {
      path: '/partenaire-relayeurs',
      meta: { layout: 'app-layout' },
      component: () =>
        import(/* webpackChunkName: "PartenaireRelayeurs" */ '@/views/PartenaireRelayeurs.vue'),
      children: [
        {
          path: '/',
          name: 'partenaire-relayeurs',
          meta: { layout: 'app-layout' },
          component: () =>
            import(
              /* webpackChunkName: "partenaire index" */ '@/views/partenaires-relayeurs/Index.vue'
            ),
        },
        {
          path: 'add-partenaire',
          name: 'add-partenaire',
          meta: { layout: 'app-layout' },
          component: () =>
            import(
              /* webpackChunkName: "partenaire add" */ '@/views/partenaires-relayeurs/AddPartenaire.vue'
            ),
        },
      ],
    },
    {
      path: '/statistiques',
      name: 'statistiques',
      meta: { layout: 'app-layout' },
      component: () => import(/* webpackChunkName: "Statistiques" */ '@/views/Statistiques.vue'),
    },
    {
      path: '/new-event',
      name: 'NewEvent',
      meta: { layout: 'app-layout' },
      component: () => import(/* webpackChunkName: "newEvent" */ '@/views/events/NewEvent.vue'),
    },
    {
      path: '/events',
      name: 'Events',
      meta: { layout: 'app-layout' },
      component: () => import(/* webpackChunkName: "events index" */ '@/views/events/Index.vue'),
      children: [
        {
          path: ':id',
          name: 'ShowEvent',
          meta: { layout: 'app-layout' },
          component: () => import(/* webpackChunkName: "Event" */ '@/views/events/Index.vue'),
          children: [
            {
              path: 'edit',
              name: 'EditEvent',
              meta: { layout: 'app-layout' },
              component: () => import(/* webpackChunkName: "Event" */ '@/views/events/Index.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/user',
      name: 'user',
      meta: { layout: 'app-layout' },
      component: () => import(/* webpackChunkName: "user" */ '@/views/User.vue'),
    },
    {
      path: '/password-reset/:uid',
      name: 'password-reset',
      component: () => import(/* webpackChunkName: "password reset" */ '@/views/ResetPassword.vue'),
      meta: { layout: 'no-layout' },
    },
    {
      path: '/password-forgot',
      name: 'password-forgot',
      component: () =>
        import(/* webpackChunkName: "password reset" */ '@/views/ForgotPassword.vue'),
      meta: { layout: 'no-layout' },
    },
  ],
  mode: 'history',
})

const authRouteNames = new Set(['auth', 'password-forgot', 'password-reset'])

const hasTokenStored = () => !!localStorage.getItem(GC_AUTH_TOKEN)

const userIsLogged = async () => {
  try {
    const userInfos = await store.dispatch('auth/getCurrentUserInfos')
    return !!userInfos?.id
  } catch (error) {
    console.warn(`[router](userIsLogged) ${error}`)
    return false
  }
}

router.beforeEach(async (to, _from, next) => {
  const isAuthRoute = authRouteNames.has(to.name)
  const hasToken = hasTokenStored()
  let isLoggedIn = false

  try {
    if (hasToken) {
      isLoggedIn = await userIsLogged()
    }

    if (!isLoggedIn && hasToken) {
      store.dispatch('auth/cleanLocalStorage')
    }

    if (isAuthRoute && isLoggedIn) {
      return next({ name: 'home' })
    }

    if (!isAuthRoute && !isLoggedIn) {
      return next({ name: 'auth' })
    }

    return next()
  } catch (error) {
    throw new Error(`[router](beforeEach) ${error}`)
  }
})

export default router
