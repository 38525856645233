import gql from 'graphql-tag'

const GET_STRUCTURE_TYPES = gql`
  query getStructureTypes {
    app_dropdown(where: { type: { _eq: "structure_type" } }, order_by: { id: asc }) {
      name
      id
    }
  }
`

export { GET_STRUCTURE_TYPES }
