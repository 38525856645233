import { SEARCH_DEMANDE_NOTIF_PERSON } from '@/graphql/autocompletion'

export default {
  namespaced: true,
  state: {
    autocompleteDemande: [],
  },
  getters: {
    autocompleteDemande(state) {
      return state.autocompleteDemande.map((user) => ({
        fullName: (user.firstname || '') + ' ' + (user.lastname || ''),
        id: user.id,
        type: user.__typename,
      }))
    },
    autocompleteDemandeWithUnavailabilities(state) {
      return state.autocompleteDemande.map((user) => ({
        fullName: (user.firstname || '') + ' ' + (user.lastname || ''),
        id: user.id,
        type: user.__typename,
        unavailabilities: user.unavailabilities,
      }))
    },
  },
  mutations: {
    SET_AUTOCOMPLETE_DEMANDE_NOTIF(state, result) {
      state.autocompleteDemande = result
    },
  },
  actions: {
    async autocompleteDemandeNotif({ commit }, { id_demande, search }) {
      commit('SET_AUTOCOMPLETE_DEMANDE_NOTIF', [])
      const name = search + '%'
      const response = await this._vm.$apollo.mutate({
        mutation: SEARCH_DEMANDE_NOTIF_PERSON,
        variables: {
          id_demande,
          name,
        },
      })
      const temporary = []
      if (response.data.demande_aidant_HMA.length > 0)
        for (const element of response.data.demande_aidant_HMA) {
          temporary.push(element.identity_aidant_HMA)
        }
      const mergedArray = [...temporary, ...response.data.partenaire_person]
      commit('SET_AUTOCOMPLETE_DEMANDE_NOTIF', mergedArray)
    },
  },
}
