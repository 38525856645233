import gql from 'graphql-tag'
const ADD_COMPLETE_DEMANDE = gql`
  mutation addCompleteDemande(
    $contact_method_id: Int!
    $handler_id: Int!
    $source_id: Int!
    $type_id: Int!
    $theme: String!
    $created_at: timestamptz!
    $demande_partenaire: demande_partenaire_arr_rel_insert_input
    $demande_aidant_HMA: demande_aidant_HMA_arr_rel_insert_input
  ) {
    insert_demande(
      objects: {
        contact_method_id: $contact_method_id
        handler_id: $handler_id
        source_id: $source_id
        status_id: 0
        theme: $theme
        type_id: $type_id
        created_at: $created_at
        demande_partenaires: $demande_partenaire
        demande_aidant_HMAs: $demande_aidant_HMA
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`
const ADD_DEMANDE_COMMENT = gql`
  mutation addDemandeComment(
    $commentObj: [demande_comments_insert_input!]!
    $id_demande: Int!
    $date: timestamptz!
  ) {
    insert_demande_comments(objects: $commentObj) {
      returning {
        comment
        user {
          acronym
        }
        created_at
      }
    }
    update_demande(where: { id: { _eq: $id_demande } }, _set: { updated_at: $date }) {
      affected_rows
    }
  }
`
const UPDATE_DEMANDE_COMMENT = gql`
  mutation updateComment($id: Int!, $properties: demande_comments_set_input!) {
    update_demande_comments(where: { id: { _eq: $id } }, _set: $properties) {
      affected_rows
    }
  }
`
const UPDATE_DEMANDE_STATUS = gql`
  mutation updateStatus($id: Int!, $status: Int!) {
    update_demande(where: { id: { _eq: $id } }, _set: { status_id: $status }) {
      affected_rows
    }
  }
`

const ADD_DEMANDE_PARTENAIRE = gql`
  mutation addDemandePartenaire($id_demande: Int!, $id_partenaire_person: Int!) {
    insert_demande_partenaire(
      objects: { id_demande: $id_demande, id_partenaire_person: $id_partenaire_person }
    ) {
      affected_rows
    }
  }
`
const ADD_DEMANDE_SOLUTION = gql`
  mutation addDemandeSolution($id_demande: Int!, $id_partenaire: Int!) {
    insert_demande_solution(objects: { id_demande: $id_demande, id_partenaire: $id_partenaire }) {
      affected_rows
    }
  }
`
const ADD_DEMANDE_SUGGESTION = gql`
  mutation addDemandeSuggestions($suggestions: [demande_suggestions_insert_input!]!) {
    insert_demande_suggestions(objects: $suggestions) {
      affected_rows
    }
  }
`

const ADD_DEMANDE_SEARCH = gql`
  mutation insertDemandeSearch($search: [demande_search_insert_input!]!) {
    insert_demande_search(objects: $search) {
      affected_rows
    }
  }
`
const ADD_DEMANDE_PERTINENCE = gql`
  mutation MyMutation($pertinence: [pertinence_vote_insert_input!]!) {
    insert_pertinence_vote(objects: $pertinence) {
      affected_rows
    }
  }
`

const GET_DEMANDE_PERTINENCE = gql`
  query getDemandePertinence {
    pertinence_vote(order_by: { id_demande: asc }) {
      cedex_city {
        city
      }
      id_demande
      partenaire_person {
        firstname
        lastname
        partenaire_location {
          name
        }
      }
      is_pertinent
      keywords
      percent
    }
  }
`
const GET_DEMANDE_PARTENAIRE_AND_AIDANT = gql`
  query getDemandeWithPartenaireAndAidant($id_demande: Int!) {
    demande(where: { id: { _eq: $id_demande } }) {
      demande_partenaire {
        partenaire_person {
          id
        }
      }
    }
    demande_aidant_HMA(where: { id_demande: { _eq: $id_demande } }) {
      type
      identity_aidant_HMA {
        id
        type
        lastname
        firstname
      }
    }
  }
`

const GET_DEMANDE_LIST = gql`
  query getDemandeList {
    demande(order_by: { created_at: desc }, where: { status_id: { _neq: 4 } }) {
      created_at
      id
      family_links {
        aidant_id
      }
      theme
      user {
        id
        acronym
      }
      app_dropdown {
        id
        name
      }
      status_id
      demande_search {
        cedex_city {
          id
          city
        }
        app_dropdown {
          id
          name
        }
      }
      demande_partenaire {
        partenaire_person {
          id
          lastname
          firstname
          phone_number1
          phone_number2
        }
      }
      demande_aidant_HMAs {
        type
        identity_aidant_HMA {
          phone_number
          phone_number_2
          id
          type
          lastname
          firstname
          family_link_id
        }
      }
      appDropdownByTypeId {
        name
        id
      }
    }
  }
`

const GET_DEMANDE_DETAIL = gql`
  query getDemandeDetail($id: Int!) {
    demande(where: { id: { _eq: $id } }) {
      id
      created_at
      updated_at
      theme
      family_links {
        app_dropdown {
          name
        }
      }
      user {
        id
        acronym
        name
      }
      appDropdownByTypeId {
        id
        name
      }
      status_id
      demande_search {
        cedex_city {
          id
          city
        }
        app_dropdown {
          id
          name
        }
      }
    }
    demande_solution(
      where: { _and: [{ id_demande: { _eq: $id } }, { isRemoved: { _eq: false } }] }
    ) {
      partenaire_person {
        id
        email
        firstname
        lastname
        dontCommunicatePhoneNumber2
        dontCommunicatePhoneNumber1
        phone_number1
        phone_number2
        dontCommunicateEmail
        profession
        service
        sex
        structure: partenaire_location {
          name
        }
      }
    }
    demande_partenaire(where: { id_demande: { _eq: $id } }) {
      partenaire_person {
        id
        lastname
        firstname
        email
        phone_number1
        phone_number2
        dontCommunicatePhoneNumber2
        dontCommunicatePhoneNumber1
        profession
        service
        structure: partenaire_location {
          name
        }
      }
    }
    demande_aidant_HMA(where: { id_demande: { _eq: $id } }) {
      type
      identity_aidant_HMA {
        id
        type
        lastname
        firstname
        dead
      }
    }
    demande_suggestions(where: { id_demande: { _eq: $id } }) {
      partenaire_person {
        id
        firstname
        lastname
        service
        profession
        phone_number1
        phone_number2
        email
        structure: partenaire_location {
          name
          phone_number1
          email
        }
      }
    }
    demande_comments(
      where: { id_demande: { _eq: $id }, isDeleted: { _eq: false } }
      order_by: { created_at: desc }
    ) {
      id
      comment
      created_at
      updated_at
      isDeleted
      user {
        id
        acronym
        name
      }
    }
  }
`
const DELETE_DEMANDE_SEARCH = gql`
  mutation deleteDemandeSearch($id_demande: Int!) {
    delete_demande_search(where: { demande_id: { _eq: $id_demande } }) {
      affected_rows
    }
  }
`

const UPDATE_FAMILIY_LINK = gql`
  mutation updateFamilyLink($id: Int!, $family_link: Int!) {
    update_family_link(
      where: { demande_id: { _eq: $id } }
      _set: { app_dropdown_id: $family_link }
    ) {
      affected_rows
    }
  }
`

export const INSERT_FAMILY_LINK = gql`
  mutation InsertFamilyLink($object: family_link_insert_input!) {
    insert_family_link_one(object: $object) {
      id
    }
  }
`

const UPDATE_SEARCH = gql`
  mutation updateSearch($id: Int!, $theme: demande_set_input, $search: demande_search_set_input) {
    update_demande(where: { id: { _eq: $id } }, _set: $theme) {
      affected_rows
    }
    update_demande_search(where: { demande_id: { _eq: $id } }, _set: $search) {
      affected_rows
    }
  }
`

const GET_DEMANDE_SOLUTION = gql`
  query getDemandeSolutionAndSearch($id: Int!) {
    demande_solution(where: { id_demande: { _eq: $id } }) {
      id
    }
    demande_search(where: { demande_id: { _eq: $id } }) {
      id
    }
  }
`
const GET_HISTORIQUE_EXPORT = gql`
  query getHistorique {
    demande(order_by: { id: asc }, where: { status_id: { _nin: [3, 4] } }) {
      id
      status_id
      demande_aidant_HMAs {
        identity_aidant_HMA {
          firstname
          lastname
          cedex_city {
            city
          }
        }
        type
        id_demande
      }
      demande_partenaire {
        partenaire_person {
          firstname
          lastname
          partenaire_location {
            cedex_city {
              city
            }
          }
        }
      }
      updated_at
      user {
        acronym
      }
    }
  }
`

const REMOVE_DEMANDE_SOLUTION = gql`
  mutation removeSolution($id_demande: Int!, $id_partenaire: Int!) {
    update_demande_solution(
      where: {
        _and: [{ id_demande: { _eq: $id_demande } }, { id_partenaire: { _eq: $id_partenaire } }]
      }
      _set: { isRemoved: true }
    ) {
      affected_rows
    }
  }
`
const DELETE_DEMANDE = gql`
  mutation deleteDemande($id_demande: Int!) {
    delete_demande_aidant_HMA(where: { id_demande: { _eq: $id_demande } }) {
      affected_rows
    }
    delete_demande_comments(where: { id_demande: { _eq: $id_demande } }) {
      affected_rows
    }
    delete_demande_comments(where: { id_demande: { _eq: $id_demande } }) {
      affected_rows
    }
    delete_demande_partenaire(where: { id_demande: { _eq: $id_demande } }) {
      affected_rows
    }
    delete_demande_search(where: { demande_id: { _eq: $id_demande } }) {
      affected_rows
    }
    delete_demande_solution(where: { id_demande: { _eq: $id_demande } }) {
      affected_rows
    }
    delete_demande_suggestions(where: { id_demande: { _eq: $id_demande } }) {
      affected_rows
    }
    delete_reminders(where: { id_demande: { _eq: $id_demande } }) {
      affected_rows
    }
    delete_pertinence_vote(where: { id_demande: { _eq: $id_demande } }) {
      affected_rows
    }
    delete_demande(where: { id: { _eq: $id_demande } }) {
      affected_rows
    }
  }
`
const UPDATE_HANDLER = gql`
  mutation MyMutation($id: Int!, $handler_id: Int!) {
    update_demande(where: { id: { _eq: $id } }, _set: { handler_id: $handler_id }) {
      affected_rows
    }
  }
`
const LAST_DEMANDE = gql`
  query last_demande_aidant_HMAs {
    demande(limit: 1, order_by: { id: desc }) {
      demande_aidant_HMAs {
        id
        type
        id_aidant_HMA
      }
    }
  }
`

export const GET_DEMANDE_BY_ID = gql`
  query getDemandeById($id: Int!) {
    demande_by_pk(id: $id) {
      demande_solutions {
        partenaire_person {
          birth_date
          comment
          email
          firstname
          id
          lastname
          phone_number1
          phone_number2
          profession
          rencontre
          partenaire_location {
            adress
            cedex_city_id
            cedex_city {
              city
              cedex
            }
            comment
            complement
            created_at
            id
            name
            rencontre
            structure_type_id
          }
        }
      }
      demande_partenaire {
        partenaire_person {
          birth_date
          comment
          email
          firstname
          id
          lastname
          phone_number1
          phone_number2
          profession
          rencontre
          partenaire_location {
            adress
            cedex_city_id
            cedex_city {
              city
              cedex
            }
            comment
            complement
            created_at
            id
            name
            rencontre
            structure_type_id
          }
        }
      }
    }
  }
`

export {
  ADD_DEMANDE_COMMENT,
  ADD_DEMANDE_PARTENAIRE,
  ADD_DEMANDE_SOLUTION,
  ADD_DEMANDE_SUGGESTION,
  ADD_DEMANDE_SEARCH,
  ADD_DEMANDE_PERTINENCE,
  GET_DEMANDE_PERTINENCE,
  GET_DEMANDE_PARTENAIRE_AND_AIDANT,
  GET_DEMANDE_LIST,
  UPDATE_DEMANDE_STATUS,
  UPDATE_DEMANDE_COMMENT,
  DELETE_DEMANDE_SEARCH,
  UPDATE_FAMILIY_LINK,
  // INSERT_DEMANDE_SEARCH,
  GET_DEMANDE_DETAIL,
  UPDATE_SEARCH,
  GET_DEMANDE_SOLUTION,
  GET_HISTORIQUE_EXPORT,
  REMOVE_DEMANDE_SOLUTION,
  DELETE_DEMANDE,
  ADD_COMPLETE_DEMANDE,
  UPDATE_HANDLER,
  LAST_DEMANDE,
}
