export function initQuillWithSpellCheck(Quill) {
  const Inline = Quill.import('blots/inline')

  class CustomColor extends Inline {
    constructor(domNode, value) {
      super(domNode, value)

      // Map <font> properties
      domNode.style.color = domNode.color

      const span = this.replaceWith(new Inline(Inline.create()))

      for (const child of span.children) {
        if (child.attributes) child.attributes.copy(span)
        if (child.unwrap) child.unwrap()
      }

      this.remove()

      return span
    }
  }

  CustomColor.blotName = 'customColor'
  CustomColor.tagName = 'FONT'

  Quill.register(CustomColor, true)
}
