// @ts-check
import { buildEventForApi, buildOccurrenceExceptionForApi } from './events.mapper'
import { EventsProvider } from './events.provider'
import { getEventsWithOccurrencesAndExceptions } from './utils'
import { moment } from '@/plugins/moment.plugin'

/** @typedef {import('./types').OccurrenceException} OccurrenceException */
/** @typedef {import('./types').Event} Event */
/** @typedef {import('./types').EventForm} EventForm */
/** @typedef {import('./types').CancelOccurrencePayload} CancelOccurrencePayload */
/** @typedef {import('@apollo/client').ApolloClient<any>} ApolloClient */

export class EventsService {
  /** @type {EventsProvider} */
  provider = undefined

  /** @param {ApolloClient} apolloClient */
  constructor(apolloClient) {
    this.provider = new EventsProvider(apolloClient)
  }

  /**
   *
   * @param {Event} event
   * @returns {Event | OccurrenceException}
   */
  addHmaToEvent(event) {
    const identityAidantHMA = event.demande?.demande_aidant_HMAs.find(
      (demande) => demande.type === 'HMA' || demande.identity_aidant_HMA.type === 'HMA',
    )?.identity_aidant_HMA
    const hma = identityAidantHMA
      ? {
          id: identityAidantHMA.id,
          firstname: identityAidantHMA.firstname,
          lastname: identityAidantHMA.lastname,
          fullName: `${identityAidantHMA.firstname} ${identityAidantHMA.lastname}`,
        }
      : { firstname: '', lastname: '' }

    return {
      ...event,
      hma,
    }
  }

  /** @param {number} id */
  async getOne(id) {
    try {
      return this.addHmaToEvent(await this.provider.getOne(id))
    } catch (error) {
      throw new Error(`[EventsService](getOne) ${error}`)
    }
  }

  /**
   * @param {object} payload
   * @param {number} payload.id
   * @param {string} payload.date
   * @returns {Promise<Event>}
   */
  async getOneOccurence({ id, date }) {
    try {
      const event = await this.provider.getOne(id)
      const isPunctualEvent = event.recurrence === '00:00:00'
      if (isPunctualEvent) {
        return this.addHmaToEvent(event)
      }

      const eventOccurences = getEventsWithOccurrencesAndExceptions([event], date, date)
      const events = eventOccurences.length > 0 ? eventOccurences : [event]

      return this.addHmaToEvent(events[0])
    } catch (error) {
      throw new Error(`[EventsService](getOne) ${error}`)
    }
  }

  /** @param {number} id */
  async deleteOne(id) {
    try {
      return this.addHmaToEvent(await this.provider.deleteOne(id))
    } catch (error) {
      throw new Error(`[EventsService](deleteOne) ${error}`)
    }
  }

  /**
   * @param {object} payload
   * @param {string} payload.dateStart
   * @param {string} payload.dateEnd
   */
  async getAll({ dateStart, dateEnd }) {
    try {
      /** @type {Event[]} */
      const events = await this.provider.getAll({ dateStart, dateEnd })

      return getEventsWithOccurrencesAndExceptions(events, dateStart, dateEnd).map((event) =>
        this.addHmaToEvent(event),
      )
    } catch (error) {
      throw new Error(`[EventsService](getAll) ${error}`)
    }
  }

  /** @param {EventForm} event */
  async addOne(event) {
    try {
      const builtEvent = buildEventForApi({ event })

      const addedEvent = await this.provider.addOne(builtEvent)

      return this.addHmaToEvent(addedEvent)
    } catch (error) {
      throw new Error(`[EventsService](addOne) ${error}`)
    }
  }

  /** @param {EventForm} event */
  add(event) {
    const isPunctualEvent = event.recurrence === '00:00:00'

    return isPunctualEvent ? this.addPunctual(event) : this.addRecurrent(event)
  }

  /**
   * @param {EventForm} event
   */
  addPunctual(event) {
    try {
      if (!event.dates) {
        throw String('No dates in form payload')
      }

      const dates = event.dates.filter(Boolean)
      const events = dates.map((date) => buildEventForApi({ event, date }))

      return this.provider.add(events)
    } catch (error) {
      throw new Error(`[EventsService](addPunctual) ${error}`)
    }
  }

  /** @param {EventForm} event */
  addRecurrent(event) {
    try {
      const newEvent = buildEventForApi({ event: { ...event, date: event.date_start } })

      return this.provider.add(/** @type {Event} */ (newEvent))
    } catch (error) {
      throw new Error(`[EventsService](addRecurrent) ${error}`)
    }
  }

  /**
   * @param {EventForm} event
   * @param {Event} originalEvent
   */
  async update(event, originalEvent) {
    try {
      const newEvent = {
        id: event.id,
        ...buildEventForApi({ event }),
      }

      // Update all occurence date if date start of event change
      if (originalEvent?.date_start) {
        const diff = moment(newEvent.date_start).diff(originalEvent.date_start, 'day')
        if (diff !== 0) {
          const occu = event.occurrence_exceptions.map((o) => {
            return {
              id: o.id,
              demande_id: o.demande_id,
              event_id: event.id,
              date:
                diff > 0
                  ? moment(o.date).add(diff, 'day').format('YYYY-MM-DD')
                  : moment(o.date).subtract(Math.abs(diff), 'day').format('YYYY-MM-DD'),
            }
          })
          await this.updateOccurences(occu)
        }
      }

      const updatedEvent = await this.provider.update(newEvent)

      return this.addHmaToEvent(updatedEvent)
    } catch (error) {
      throw new Error(`[EventsService](updateEvent) ${error}`)
    }
  }

  /**
   * @param {object} payload
   * @param {number} payload.id
   * @param {boolean} payload.cancelled
   * @param {string} payload.cancellation_reason
   */
  async cancelOrReprogram(payload) {
    try {
      const updatedEvent = await this.provider.update(payload)
      return this.addHmaToEvent(updatedEvent)
    } catch (error) {
      throw new Error(`[EventsService](cancelOrReprogram) ${error}`)
    }
  }

  /**
   * @param {object} payload
   * @param {CancelOccurrencePayload} payload.occurrenceException
   * @param {Event} payload.event
   */
  async addOrUpdateCancellationOccurrenceException({ occurrenceException, event }) {
    try {
      const eventBuilt = buildEventForApi({
        event,
      })

      delete eventBuilt.date_end
      delete eventBuilt.date_start

      const updatedOccurrenceException = occurrenceException.id
        ? await this.provider.updateOccurrenceException({
            ...eventBuilt,
            ...occurrenceException,
          })
        : await this.provider.addOccurrenceException({
            ...eventBuilt,
            ...occurrenceException,
          })

      return this.addHmaToEvent(updatedOccurrenceException)
    } catch (error) {
      throw new Error(`[EventsService](addOrUpdateCancellationOccurrenceException) ${error}`)
    }
  }

  /**
   * @param {object} payload
   * @param {EventForm} payload.occurrenceException
   * @param {Event} payload.event
   */
  async addOrUpdateOccurrenceException({ occurrenceException, event }) {
    try {
      const occurrenceExceptionBuilt = buildOccurrenceExceptionForApi({
        occurrenceException,
        event,
      })

      const updatedEvent = occurrenceExceptionBuilt.id
        ? await this.provider.updateOccurrenceException({
            ...occurrenceExceptionBuilt,
            date: undefined,
            event_id: undefined,
          })
        : await this.provider.addOccurrenceException({
            ...occurrenceExceptionBuilt,
            event_id: occurrenceExceptionBuilt.event_id,
          })

      return this.addHmaToEvent(updatedEvent)
    } catch (error) {
      throw new Error(`[EventsService](addOrUpdateOccurrenceException) ${error}`)
    }
  }

  /**
   * @param {any[]} occurences
   */
  async updateOccurences(occurences) {
    const data = occurences.map(({ __typename, ...o }) => ({ ...o }))
    await this.provider.updateOccurence(data)
  }
}
