<template>
  <BButton
    ref="button"
    class="app-button"
    :class="[
      {
        capitalize,
        uppercase,
      },
    ]"
    :pill="pill"
    :type="type"
    :size="size"
    :variant="buttonVariant"
    v-bind="$attrs"
    v-on="{
      ...$listeners,
    }"
  >
    <slot>
      {{ name }}
    </slot>
  </BButton>
</template>

<script>
export default {
  props: {
    name: { type: String, default: undefined },
    color: { type: String, default: 'blue' },
    action: { type: Function, default: undefined },
    capitalize: { type: Boolean, default: false },
    uppercase: { type: Boolean, default: false },
    type: { type: String, default: 'button' },
    size: { type: String, default: 'md' },
    variant: { type: String, default: 'primary' },
    pill: { type: Boolean, default: false },
  },
  computed: {
    buttonVariant() {
      if (this.color === 'blue') {
        return 'primary'
      } else if (this.color === 'white') {
        return 'outline-primary'
      }

      return this.color
    },
  },
}
</script>

<style lang="postcss">
.app-button {
  width: auto;
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 0.3em 1em;
  font-weight: normal;
  text-align: center;
  cursor: pointer;
  transition: all 300ms ease-in-out;

  &.app-button-blue:not(:disabled) {
    background: #264c88;
    border-color: #264c88;
    color: white;

    &:hover {
      color: #264c88;
      background-color: white;
    }
  }

  &.app-button-white:not(:disabled) {
    color: #264c88;
    background: transparent;
    border-color: #264c88;

    &:hover {
      background-color: #264c88;
      color: white;
    }
  }

  &:disabled {
    color: #ccc;
    background: #f2f2f2;
    cursor: not-allowed;
  }
}
</style>
