import { eventFieldsFragment } from '@/domain/events/gql/fragments/event.fragment'
import gql from 'graphql-tag'

const ADD_PARTENAIRE_LOCATION = gql`
  mutation insertPartenaireLocation($partenaire_location: [partenaire_location_insert_input!]!) {
    insert_partenaire_location(objects: $partenaire_location) {
      returning {
        id
      }
    }
  }
`

const ADD_PARTENAIRE_PERSON = gql`
  mutation insertPartenairePerson($partenaire_person: [partenaire_person_insert_input!]!) {
    insert_partenaire_person(objects: $partenaire_person) {
      returning {
        id
      }
    }
  }
`

const ADD_PERSON_KEYWORDS = gql`
  mutation insertPersonKeywords($keywords: [person_keywords_insert_input!]!) {
    insert_person_keywords(objects: $keywords) {
      affected_rows
    }
  }
`

const ADD_PARTENAIRE_LIFE_DOMAIN = gql`
  mutation insertLifeDomain($lifeDomains: [partenaire_life_domain_insert_input!]!) {
    insert_partenaire_life_domain(objects: $lifeDomains) {
      affected_rows
    }
  }
`
const SEARCH_PARTENAIRE_BY_KEYWORDS = gql`
  query searchPartenaireByKeywords($keywords: [Int!], $partenaireFilter: Int!) {
    partenaire_person(
      where: {
        _and: [
          {
            person_keywords: { id_keyword: { _in: $keywords } }
            id: { _neq: $partenaireFilter }
            isObsolete: { _eq: false }
            actif: { _eq: true }
          }
        ]
      }
    ) {
      firstname
      lastname
      id
      email
      phone_number1
      phone_number2
      service
      profession
      sex
      structure: partenaire_location {
        name
        complement
        cedex_city {
          id
          city
          lat
          lon
        }
        adress
      }
    }
  }
`

const SEARCH_NB_KEYWORDS = gql`
  query nbOfKeywords($id_partenaire: Int!, $keywords: [Int!]) {
    person_keywords_aggregate(
      where: { id_person: { _eq: $id_partenaire }, _and: { id_keyword: { _in: $keywords } } }
    ) {
      aggregate {
        count
      }
    }
  }
`

const GET_PARTENAIRE_BY_ID = gql`
  query partenaireById($id: Int!) {
    partenaire_person(where: { id: { _eq: $id } }) {
      email
      firstname
      id
      lastname
      service
      phone_number1
      phone_number2
      profession
      sex
      dontCommunicatePhoneNumber1
      dontCommunicatePhoneNumber2
      dontCommunicateEmail
      updated_at
      rencontre
      comment
      isObsolete
      actif
      birth_date
      entry_date
      exit_date
      adhesions: partenaire_adhesions {
        id
        start_date
        end_date
      }
      documents: admin_documents(order_by: { year: desc }) {
        id
        year
        ndf
        insurance
        criminal_record
      }

      person_keywords(order_by: { keyword: { id: asc } }) {
        keyword {
          label
          id
        }
      }
      structure: partenaire_location {
        id
        email
        name
        comment
        rencontre
        complement
        cedex_city {
          id
          city
          cedex
        }
        adress
        phone_number1
        phone_number2
        dontCommunicatePhoneNumber1
        dontCommunicatePhoneNumber2
        dontCommunicateEmail
        obsolete
        updated_at
        website
        structure_type_id: app_dropdown {
          name
          id
        }
      }
      partenaire_actif_histories(order_by: { created_at: desc }, limit: 1) {
        actif
        created_at
      }
      unavailabilities: partenaire_unavailabilities(order_by: { date_start: asc }) {
        id
        rrule
        date_start
        date_end
        all_day
      }
    }
  }
`

const GET_PARTENAIRE_FULL = gql`
  query MyQuery {
    partenaire_location {
      adress
      cedex_city {
        city
        cedex
      }
      comment
      complement
      email
      id
      name
      phone_number2
      phone_number1
      rencontre
      updated_at
      partenaire_person {
        email
        firstname
        id
        lastname
        phone_number1
        phone_number2
        service
        profession
        sex
        dontCommunicatePhoneNumber1
        dontCommunicatePhoneNumber2
        dontCommunicateEmail
      }
      partenaire_life_domains(order_by: { app_dropdown: { id: asc } }) {
        app_dropdown {
          name
          id
        }
      }
      partenaire_keywords(order_by: { keyword: { id: asc } }) {
        keyword {
          label
          id
        }
      }
      app_dropdown(order_by: { id: asc }) {
        name
        id
      }
    }
  }
`

const GET_PARTENAIRE_LIST = gql`
  query getPartenaireList {
    partenaire_person(order_by: { created_at: desc }, where: { isObsolete: { _eq: false } }) {
      id
      firstname
      lastname
      sex
      service
      email
      actif
      person_keywords {
        keyword {
          label
          id
        }
      }
      unavailabilities: partenaire_unavailabilities {
        all_day
        created_at
        date_end
        date_start
        id
        rrule
      }
      structure: partenaire_location {
        name
        app_dropdown {
          name
        }
        cedex_city {
          city
        }
      }
    }
  }
`

const MODIF_STRUCTURE_DETAIL = gql`
  mutation updateStructure($structure: partenaire_location_set_input!, $id: Int!) {
    update_partenaire_location(where: { id: { _eq: $id } }, _set: $structure) {
      affected_rows
    }
  }
`
const MODIF_PERSON_DETAIL = gql`
  mutation updatePerson(
    $person: partenaire_person_set_input!
    $id: Int!
    $keywords: [person_keywords_insert_input!]!
    $unavailabilities: [partenaire_unavailabilities_insert_input!]!
  ) {
    update_partenaire_person(where: { id: { _eq: $id } }, _set: $person) {
      affected_rows
    }
    delete_person_keywords(where: { id_person: { _eq: $id } }) {
      affected_rows
    }
    insert_person_keywords(objects: $keywords) {
      affected_rows
    }
    insert_partenaire_unavailabilities(
      objects: $unavailabilities
      on_conflict: {
        constraint: partenaire_unavailabilities_pkey
        update_columns: [date_start, date_end, all_day, rrule]
      }
    ) {
      affected_rows
    }
  }
`

const CREATE_PERSON_ACTIF_HISTORY = gql`
  mutation create_person_actif_history($object: partenaire_actif_histories_insert_input!) {
    insert_partenaire_actif_histories_one(object: $object) {
      created_at
      id
      actif
      partenaire_person_id
    }
  }
`

const SET_OBSOLETE = gql`
  mutation SetObsolete($id: Int!) {
    update_partenaire_person(where: { id: { _eq: $id } }, _set: { isObsolete: true }) {
      affected_rows
    }
  }
`

const EDIT_PARTENAIRE_PERSON = gql`
  mutation EditPerson(
    $id: Int!
    $email: String
    $firstName: String
    $phone1NC: Boolean
    $phone2NC: Boolean
    $lastName: String
    $phone1: String
    $phone2: String
    $profession: String
    $service: String
    $sex: bpchar
  ) {
    update_partenaire_person(
      where: { id: { _eq: $id } }
      _set: {
        email: $email
        firstname: $firstName
        dontCommunicatePhoneNumber1: $phone1NC
        dontCommunicatePhoneNumber2: $phone2NC
        lastname: $lastName
        phone_number1: $phone1
        phone_number2: $phone2
        profession: $profession
        service: $service
        sex: $sex
      }
    ) {
      returning {
        email
        firstname
        id
        dontCommunicatePhoneNumber1
        dontCommunicatePhoneNumber2
        lastname
        phone_number1
        phone_number2
        profession
        service
        sex
        actif
      }
    }
  }
`

const DELETE_PARTENAIRE_PERSON = gql`
  mutation DeletePerson($id: Int!) {
    delete_partenaire_person(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const GET_PARTENAIRE_BY_NAME = gql`
  query serchPartenaireByNama($name: String!) {
    partenaire_location(where: { name: { _ilike: $name } }) {
      adress
      cedex_city_id
      complement
      id
      email
      name
      phone_number1
      phone_number2
      structure_type_id
      dontCommunicatePhoneNumber2
      dontCommunicatePhoneNumber1
      dontCommunicateEmail
      keywords: partenaire_keywords {
        id: id_keyword
      }
      life_domains: partenaire_life_domains {
        id: id_domain
      }
      persons: partenaire_person {
        firstname
        email
        id
        dontCommunicatePhoneNumber1
        dontCommunicatePhoneNumber2
        dontCommunicateEmail
        lastname
        phone_number1
        phone_number2
        profession
        sex
        service
      }
    }
  }
`

const GET_PERSON_BY_NAME = gql`
  query serchPersonByName($where: partenaire_person_bool_exp) {
    partenaire_person(where: $where) {
      email
      firstname
      id
      dontCommunicatePhoneNumber1
      dontCommunicatePhoneNumber2
      dontCommunicateEmail
      lastname
      sex
      service
      profession
      phone_number2
      phone_number1
      rencontre
      comment
      birth_date
      entry_date
      exit_date
      keywords: person_keywords {
        id: id_keyword
      }
      structure: partenaire_location {
        adress
        cedex_city_id
        complement
        email
        id
        dontCommunicateEmail
        dontCommunicatePhoneNumber1
        dontCommunicatePhoneNumber2
        name
        phone_number1
        phone_number2
        structure_type_id
      }
    }
  }
`

const GET_PERSON_BY_NAME_AND_STRUCTURE_TYPE = gql`
  query search_partenaire_person_by_names($name: String!, $structure_type_id: Int!) {
    partenaire_person(
      where: {
        actif: { _eq: true }
        _or: [{ firstname: { _ilike: $name } }, { lastname: { _ilike: $name } }]
        partenaire_location: { structure_type_id: { _eq: $structure_type_id } }
      }
    ) {
      email
      firstname
      id
      lastname
      structure: partenaire_location {
        adress
        cedex_city_id
        cedex_city {
          city
          cedex
        }
        structure_type_id
      }
    }
  }
`

const GET_PERSON_BY_STRUCTURE_NAME_AND_STRUCTURE_TYPE = gql`
  query searchPersonByStructure($name: String!) {
    partenaire_location(
      where: {
        _or: [
          { name: { _ilike: $name } }
          {
            partenaire_person: {
              _or: [{ lastname: { _ilike: $name } }, { firstname: { _ilike: $name } }]
            }
          }
        ]
      }
    ) {
      name
      adress
      cedex_city_id
      cedex_city {
        city
        cedex
      }
      structure_type_id
      partenaire_person {
        email
        firstname
        id
        lastname
      }
    }
  }
`

const GET_PERSON_BY_NAME_ONLY = gql`
  query search_partenaire_person_by_name_only($name: String!) {
    partenaire_person(
      where: {
        actif: { _eq: true }
        _or: [{ firstname: { _ilike: $name } }, { lastname: { _ilike: $name } }]
      }
    ) {
      email
      firstname
      id
      lastname
    }
  }
`

const UPDATE_ADHESIONS = gql`
  mutation update_adhesion($adhesions: [partenaire_adhesions_insert_input!]!) {
    insert_partenaire_adhesions(
      objects: $adhesions
      on_conflict: { constraint: partenaire_adhesions_pkey, update_columns: [start_date, end_date] }
    ) {
      affected_rows
    }
  }
`

const UPDATE_DOCUMENTS = gql`
  mutation update_admin_documents($documents: [admin_documents_insert_input!]!) {
    insert_admin_documents(
      objects: $documents
      on_conflict: {
        constraint: admin_documents_pkey
        update_columns: [year, ndf, insurance, criminal_record]
      }
    ) {
      affected_rows
    }
  }
`

const DELETE_DOCUMENT = gql`
  mutation delete_admin_document($id: Int!) {
    delete_admin_documents(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const DELETE_UNAVAILABILITY = gql`
  mutation deleteUnavailabilities($id: Int!) {
    delete_partenaire_unavailabilities(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`

const SEARCH_STRUCTURE = gql`
  query searchStructure($name: String!) {
    structures: partenaire_location(distinct_on: [name], where: { name: { _ilike: $name } }) {
      name
      id
    }
  }
`

const SEARCH_PARTNER_PERSONS = gql`
  query searchPartnerPersons($structureId: Int!, $query: String!) {
    partnerPersons: partenaire_person(
      where: {
        partenaire_location: { structure_type_id: { _eq: $structureId } }
        isObsolete: { _eq: false },
        actif: { _eq: true }
        _or: [{ firstname: { _ilike: $query } }, { lastname: { _ilike: $query } }]
      }
    ) {
      lastname
      id
      firstname
      email
      actif
      events ${eventFieldsFragment}
      unavailabilities: partenaire_unavailabilities(order_by: { date_start: asc }) {
        id
        rrule
        date_start
        date_end
        all_day
      }
    }
  }
`

export {
  ADD_PARTENAIRE_LOCATION,
  ADD_PARTENAIRE_PERSON,
  ADD_PERSON_KEYWORDS,
  GET_PARTENAIRE_FULL,
  GET_PARTENAIRE_LIST,
  GET_PARTENAIRE_BY_ID,
  ADD_PARTENAIRE_LIFE_DOMAIN,
  SEARCH_PARTENAIRE_BY_KEYWORDS,
  SEARCH_NB_KEYWORDS,
  MODIF_STRUCTURE_DETAIL,
  MODIF_PERSON_DETAIL,
  SET_OBSOLETE,
  EDIT_PARTENAIRE_PERSON,
  DELETE_PARTENAIRE_PERSON,
  GET_PARTENAIRE_BY_NAME,
  GET_PERSON_BY_NAME,
  GET_PERSON_BY_NAME_ONLY,
  GET_PERSON_BY_NAME_AND_STRUCTURE_TYPE,
  GET_PERSON_BY_STRUCTURE_NAME_AND_STRUCTURE_TYPE,
  CREATE_PERSON_ACTIF_HISTORY,
  UPDATE_ADHESIONS,
  UPDATE_DOCUMENTS,
  DELETE_DOCUMENT,
  SEARCH_STRUCTURE,
  DELETE_UNAVAILABILITY,
  SEARCH_PARTNER_PERSONS,
}
