<template>
  <div id="app">
    <BModal v-model="isPasswordOutDated" class="modal" centered title="Mot de passe expiré">
      Le delais de 45 jours est atteint, veuillez changer votre mot de passe

      <template #modal-footer>
        <TheButton color="white" :to="{ name: 'user' }" @click="isPasswordOutDated = false">
          Changer mon mot de passe
        </TheButton>
      </template>
    </BModal>

    <Components :is="layout" />
  </div>
</template>

<script>
import TheButton from '@/components/TheButton'

export default {
  name: 'App',
  components: {
    TheButton,
    NoLayout: () => import('@/layout/NoLayout.vue'),
    AppLayout: () => import('@/layout/AppLayout.vue'),
  },
  data() {
    return {
      isPasswordOutDated: false,
    }
  },
  computed: {
    layout() {
      return this.$route.meta.layout
    },
    currentUser() {
      return this.$store.getters['auth/currentUser']
    },
  },
  watch: {
    currentUser: {
      handler(value, oldValue) {
        if (value && value.id !== oldValue?.id) {
          this.isPasswordOutDated =
            this.$moment().diff(this.$moment(value.updated_at), 'days') >= 44
        }
      },
      immediate: true,
    },
  },
}
</script>
