// @ts-check

import Vue from 'vue'
import { EventsService } from '@/domain/events/events.service'
import { EmailsService } from '@/domain/emails/emails.service'
import { apolloClient as apolloClientModule } from './apollo-client.plugin'

/** @typedef {import('@apollo/client').ApolloClient<any>} ApolloClient */

export class Api {
  /** @type {ApolloClient} */
  $apollo = undefined

  /** @param {ApolloClient} apolloClient */
  constructor(apolloClient) {
    this.$apollo = apolloClient
  }

  get events() {
    return new EventsService(this.$apollo)
  }

  get emails() {
    return new EmailsService()
  }
}

export const api = new Api(apolloClientModule)

Vue.prototype.$api = api
