import gql from 'graphql-tag'

const GET_AIDANTS_STATS_BY_THEME = gql`
  query aidantsStats($begin: timestamptz!, $end: timestamptz!, $theme: String!, $type_id: [Int!]) {
    all: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { type: { _eq: "aidant" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    males: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "aidant" } }
          { identity_aidant_HMA: { sex: { _eq: "M" } } }
          {
            demande: {
              _and: [
                { theme: { _eq: $theme } }
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    females: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "aidant" } }
          { identity_aidant_HMA: { sex: { _eq: "F" } } }
          {
            demande: {
              _and: [
                { theme: { _eq: $theme } }
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    city: cedex_city {
      city
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { theme: { _eq: $theme } }
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "aidant" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
    }

    family_link: app_dropdown(where: { type: { _eq: "identity_family_link" } }) {
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { theme: { _eq: $theme } }
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "aidant" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
      name
    }

    identity_status: app_dropdown(where: { type: { _eq: "identity_status" } }) {
      number: identityAidantHmasByStatusId_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { theme: { _eq: $theme } }
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "aidant" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
      name
    }
  }
`

const GET_AIDANT_NB_DEMANDES = gql`
  query aidantNbDemandes($begin: timestamptz!, $end: timestamptz!, $type_id: [Int!]) {
    identity_demandes_count: identity_aidant_HMA(
      where: {
        demande_aidant_HMAs: {
          _and: [
            { type: { _eq: "aidant" } }
            {
              demande: {
                _and: [
                  { created_at: { _gte: $begin } }
                  { created_at: { _lte: $end } }
                  { type_id: { _in: $type_id } }
                ]
              }
            }
          ]
        }
      }
    ) {
      demande_aidant_HMAs_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`
const GET_AIDANTS_STATS_BY_CONTACT = gql`
  query aidantsStats(
    $begin: timestamptz!
    $end: timestamptz!
    $contact_ids: [Int!]!
    $type_id: [Int!]
  ) {
    all: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { type: { _eq: "aidant" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    males: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "aidant" } }
          { identity_aidant_HMA: { sex: { _eq: "M" } } }
          {
            demande: {
              _and: [
                { contact_method_id: { _in: $contact_ids } }
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    females: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "aidant" } }
          { identity_aidant_HMA: { sex: { _eq: "F" } } }
          {
            demande: {
              _and: [
                { contact_method_id: { _in: $contact_ids } }
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    city: cedex_city {
      city
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { contact_method_id: { _in: $contact_ids } }
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "aidant" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
    }

    family_link: app_dropdown(where: { type: { _eq: "identity_family_link" } }) {
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { contact_method_id: { _in: $contact_ids } }
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "aidant" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
      name
    }

    identity_status: app_dropdown(where: { type: { _eq: "identity_status" } }) {
      number: identityAidantHmasByStatusId_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { contact_method_id: { _in: $contact_ids } }
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "aidant" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
      name
    }
  }
`

const GET_HMA_STATS_BY_THEME = gql`
  query HMAstats($begin: timestamptz!, $end: timestamptz!, $theme: String!, $type_id: [Int!]) {
    all: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { type: { _eq: "HMA" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    males: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "HMA" } }
          {
            demande: {
              _and: [
                { theme: { _eq: $theme } }
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { identity_aidant_HMA: { sex: { _eq: "M" } } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    females: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "HMA" } }
          {
            demande: {
              _and: [
                { theme: { _eq: $theme } }
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { identity_aidant_HMA: { sex: { _eq: "F" } } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    city: cedex_city {
      city
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { theme: { _eq: $theme } }
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "HMA" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
    }
  }
`
const GET_HMA_STATS_BY_CONTACT = gql`
  query HMAstats(
    $begin: timestamptz!
    $end: timestamptz!
    $contact_ids: [Int!]!
    $type_id: [Int!]
  ) {
    all: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { type: { _eq: "HMA" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    males: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "HMA" } }
          {
            demande: {
              _and: [
                { contact_method_id: { _in: $contact_ids } }
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { identity_aidant_HMA: { sex: { _eq: "M" } } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    females: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "HMA" } }
          {
            demande: {
              _and: [
                { contact_method_id: { _in: $contact_ids } }
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { identity_aidant_HMA: { sex: { _eq: "F" } } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    city: cedex_city {
      city
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { contact_method_id: { _in: $contact_ids } }
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "HMA" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

const GET_EXCEL_GLOBAL_STATS = gql`
  query getGlobalStats($begin: timestamptz!, $end: timestamptz!) {
    demandes: demande_aggregate(
      where: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
    ) {
      aggregate {
        count
      }
    }
    life_domains: app_dropdown(where: { type: { _eq: "life_domain" } }) {
      nombre: demande_searches_aggregate(
        where: {
          demande: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    aidants: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          { demande: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] } }
          { type: { _eq: "aidant" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    HMA: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          { demande: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] } }
          { type: { _eq: "HMA" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    partenaires: demande_partenaire_aggregate(
      where: {
        _and: [
          { demande: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] } }
        ]
      }
      distinct_on: id_partenaire_person
    ) {
      aggregate {
        count
      }
    }
    demande_type: app_dropdown(where: { type: { _eq: "demande_type" } }) {
      nombre: demandesByTypeId_aggregate(
        where: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
      ) {
        aggregate {
          count
        }
      }
      name
    }

    solutions_adapt: demande_solution_aggregate(
      where: {
        _and: [
          { demande: { created_at: { _gte: $begin } } }
          { demande: { created_at: { _lte: $end } } }
          { partenaire_person: { partenaire_location: { id: { _eq: 3534 } } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const GET_EXCEL_THEME_STATS = gql`
  query getThemeStats($begin: timestamptz!, $end: timestamptz!, $theme: String!) {
    demandes: demande_aggregate(
        where: {
          _and: [{ created_at: { _gte: $begin }}, { created_at: { _lte: $end }}, { theme: { _eq: $theme } }]
        }
      ) {
        aggregate {
          count
        }
      }
      aidants: demande_aidant_HMA_aggregate(
        where: {
          _and: [
            { demande: {_and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { theme: { _eq: $theme } }
            ]}}
            { type: { _eq: "aidant" } }
          ]
        }, distinct_on: id_aidant_HMA
      ) {
        aggregate {
          count
        }
      }
      HMA: demande_aidant_HMA_aggregate(
        where: {
          _and: [
            { demande: {_and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { theme: { _eq: $theme } }
            ]}}
            { type: { _eq: "HMA" } }
          ]
        }, distinct_on: id_aidant_HMA
      ) {
        aggregate {
          count
        }
      }

      demande_type: app_dropdown(where: { type: { _eq: "demande_type" } }) {
        nombre: demandesByTypeId_aggregate(
          where: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { theme: { _eq: $theme } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
        name
      }

      demande_source: app_dropdown(where: { type: { _eq: "demande_source" } }) {
        nombre: demandesBySourceId_aggregate(
          where: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { theme: { _eq: $theme } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
        name
      }

      life_domains: app_dropdown(where: { type: { _eq: "life_domain" } }) {
        nombre: demande_searches_aggregate(
          where: {
            demande: {_and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { theme: { _eq: $theme } }
            ]}
          }
        ) {
          aggregate {
            count
          }
        }
        name
      }

      comments: demande(where: {_and: [
        { created_at: { _gte: $begin }},
        { created_at: { _lte: $end }},
        { theme : { _eq: $theme } }
        ]
      }
      ) {
        demande_comments_aggregate {
          aggregate {
            count
          }
        }
      }
      total_comments: demande_comments_aggregate(where: {demande: {_and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme : { _eq: $theme } }
        ]}
      })
        {
        aggregate {
          count
        }
      }

      solutions: demande_solution(
        where: {
          demande: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { theme: { _eq: $theme } }
            ]
          }
        }
      ) {
        partenaire_person {
          partenaire_location {
            app_dropdown {
              name
            }
          }
        }
      }
      pas_de_mise_en_lien: demande_solution_aggregate(
        where: {
          _and: [
            { demande: { created_at: { _gte: $begin } } }
            { demande: { created_at: { _lte: $end } } }
            { demande: {theme: { _eq: $theme } }}
            { id_partenaire: { _eq: ${process.env.VUE_APP_ID_PAS_DE_MISE_EN_LIEN} } }
            { isRemoved: { _eq: false } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      recherche_information: demande_solution_aggregate(
        where: {
          _and: [
            { demande: { created_at: { _gte: $begin } } }
            { demande: { created_at: { _lte: $end } } }
            { demande: {theme: { _eq: $theme } }}
            { id_partenaire: { _eq: ${process.env.VUE_APP_ID_RECHERCHE_INFORMATIONS} } }
            { isRemoved: { _eq: false } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
  }
`
const GET_EXCEL_CONTACT_STATS = gql`
  query getContactStats($begin: timestamptz!, $end: timestamptz!, $contact_ids: [Int!]!) {
    demandes: demande_aggregate(
        where: {
          _and: [{ created_at: { _gte: $begin }}, { created_at: { _lte: $end }}, { contact_method_id : { _in: $contact_ids }}]
        }
      ) {
        aggregate {
          count
        }
      }
      aidants: demande_aidant_HMA_aggregate(
        where: {
          _and: [
            { demande: {_and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { contact_method_id : { _in: $contact_ids } }
            ]}}
            { type: { _eq: "aidant" } }
          ]
        }, distinct_on: id_aidant_HMA
      ) {
        aggregate {
          count
        }
      }
      HMA: demande_aidant_HMA_aggregate(
        where: {
          _and: [
            { demande: {_and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { contact_method_id : { _in: $contact_ids } }
            ]}}
            { type: { _eq: "HMA" } }
          ]
        }, distinct_on: id_aidant_HMA
      ) {
        aggregate {
          count
        }
      }

      demande_type: app_dropdown(where: { type: { _eq: "demande_type" } }) {
        nombre: demandesByTypeId_aggregate(
          where: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { contact_method_id : { _in: $contact_ids } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
        name
      }

      demande_source: app_dropdown(where: { type: { _eq: "demande_source" } }) {
        nombre: demandesBySourceId_aggregate(
          where: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { contact_method_id : { _in: $contact_ids } }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
        name
      }

      life_domains: app_dropdown(where: { type: { _eq: "life_domain" } }) {
        nombre: demande_searches_aggregate(
          where: {
            demande: {_and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { contact_method_id : { _in: $contact_ids } }
            ]}
          }
        ) {
          aggregate {
            count
          }
        }
        name
      }
      comments: demande(where: {_and: [
        { created_at: { _gte: $begin }},
        { created_at: { _lte: $end }},
        { contact_method_id : { _in: $contact_ids }}
        ]
      }
      ) {
        demande_comments_aggregate {
          aggregate {
            count
          }
        }
      }
      total_comments: demande_comments_aggregate(where: {demande: {_and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { contact_method_id : { _in: $contact_ids } }
        ]}
      })
        {
        aggregate {
          count
        }
      }

      solutions: demande_solution(
        where: {
          demande: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { contact_method_id : { _in: $contact_ids } }
            ]
          }
        }
      ) {
        partenaire_person {
          partenaire_location {
            app_dropdown {
              name
            }
          }
        }
      }
      pas_de_mise_en_lien: demande_solution_aggregate(
        where: {
          _and: [
            { demande: { created_at: { _gte: $begin } } }
            { demande: { created_at: { _lte: $end } } }
            {demande: { contact_method_id : { _in: $contact_ids } }}
            { id_partenaire: { _eq: ${process.env.VUE_APP_ID_PAS_DE_MISE_EN_LIEN} } }
            { isRemoved: { _eq: false } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      recherche_information: demande_solution_aggregate(
        where: {
          _and: [
            { demande: { created_at: { _gte: $begin } } }
            { demande: { created_at: { _lte: $end } } }
            { demande: {contact_method_id: { _in: $contact_ids } }}
            { id_partenaire: { _eq: ${process.env.VUE_APP_ID_RECHERCHE_INFORMATIONS} } }
            { isRemoved: { _eq: false } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
  }
`

const GET_RELAIS_MULTIPLES = gql`
  query getRelaisMultiples($begin: timestamptz!, $end: timestamptz!) {
    listRepit: demande_aidant_HMA(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Répit" } }
          ]
        }
      }
      distinct_on: id_aidant_HMA
    ) {
      id_aidant_HMA
    }
    listMobilite: demande_aidant_HMA(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Mobilité" } }
          ]
        }
      }
      distinct_on: id_aidant_HMA
    ) {
      id_aidant_HMA
    }
    listRessources: demande_aidant_HMA(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Ressources" } }
          ]
        }
      }
      distinct_on: id_aidant_HMA
    ) {
      id_aidant_HMA
    }
  }
`

const GET_AIDANT_TANDEM_DATA = gql`
  query aidantsStats($begin: timestamptz!, $end: timestamptz!, $type_id: [Int!]) {
    all: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { type: { _eq: "aidant" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    males: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "aidant" } }
          { identity_aidant_HMA: { sex: { _eq: "M" } } }
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    females: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "aidant" } }
          { identity_aidant_HMA: { sex: { _eq: "F" } } }
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }

    family_link: app_dropdown(where: { type: { _eq: "identity_family_link" } }) {
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "aidant" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
      name
    }
  }
`
const GET_HMA_TANDEM_DATA = gql`
  query hmaStats($begin: timestamptz!, $end: timestamptz!, $type_id: [Int!]) {
    all: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
          { type: { _eq: "HMA" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    males: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "HMA" } }
          { identity_aidant_HMA: { sex: { _eq: "M" } } }
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    females: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "HMA" } }
          { identity_aidant_HMA: { sex: { _eq: "F" } } }
          {
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { type_id: { _in: $type_id } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }

    family_link: app_dropdown(where: { type: { _eq: "identity_family_link" } }) {
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              {
                demande: {
                  _and: [
                    { created_at: { _gte: $begin } }
                    { created_at: { _lte: $end } }
                    { type_id: { _in: $type_id } }
                  ]
                }
              }
              { type: { _eq: "HMA" } }
            ]
          }
        }
        distinct_on: id
      ) {
        aggregate {
          count
        }
      }
      name
    }
  }
`

export {
  GET_AIDANTS_STATS_BY_THEME,
  GET_HMA_STATS_BY_THEME,
  GET_EXCEL_GLOBAL_STATS,
  GET_EXCEL_THEME_STATS,
  GET_EXCEL_CONTACT_STATS,
  GET_RELAIS_MULTIPLES,
  GET_AIDANTS_STATS_BY_CONTACT,
  GET_HMA_STATS_BY_CONTACT,
  GET_AIDANT_TANDEM_DATA,
  GET_HMA_TANDEM_DATA,
  GET_AIDANT_NB_DEMANDES,
}
