import gql from 'graphql-tag'

const GET_HMA_STATS = gql`
  query HMAstats($begin: timestamptz!, $end: timestamptz!) {
    demandes: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { demande_aidant_HMAs: { type: { _eq: "HMA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    ressources: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Ressources" } }
          { demande_aidant_HMAs: { type: { _eq: "HMA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    repit: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Répit" } }
          { demande_aidant_HMAs: { type: { _eq: "HMA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    mob: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Mobilité" } }
          { demande_aidant_HMAs: { type: { _eq: "HMA" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    all: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { type: { _eq: "HMA" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    males: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "HMA" } }
          {
            identity_aidant_HMA: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { sex: { _eq: "M" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    females: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "HMA" } }
          {
            identity_aidant_HMA: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { sex: { _eq: "F" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    city: cedex_city {
      city
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { type: { _eq: "HMA" } }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`
const GET_AIDANTS_STATS = gql`
  query aidantsStats($begin: timestamptz!, $end: timestamptz!) {
    demandes: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { demande_aidant_HMAs: { type: { _eq: "aidant" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    ressources: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Ressources" } }
          { demande_aidant_HMAs: { type: { _eq: "aidant" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    repit: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Répit" } }
          { demande_aidant_HMAs: { type: { _eq: "aidant" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    mob: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Mobilité" } }
          { demande_aidant_HMAs: { type: { _eq: "aidant" } } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }

    all: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { type: { _eq: "aidant" } }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    males: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "aidant" } }
          {
            identity_aidant_HMA: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { sex: { _eq: "M" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    females: demande_aidant_HMA(
      where: {
        _and: [
          { type: { _eq: "aidant" } }
          {
            identity_aidant_HMA: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { sex: { _eq: "F" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      identity: identity_aidant_HMA {
        category
        birthday
        sex
      }
    }
    city: cedex_city {
      city
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { type: { _eq: "aidant" } }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }

    family_link: app_dropdown(where: { type: { _eq: "identity_family_link" } }) {
      number: identity_aidant_HMAs_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { type: { _eq: "aidant" } }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }

    identity_status: app_dropdown(where: { type: { _eq: "identity_status" } }) {
      number: identityAidantHmasByStatusId_aggregate(
        where: {
          demande_aidant_HMAs: {
            _and: [
              { created_at: { _gte: $begin } }
              { created_at: { _lte: $end } }
              { type: { _eq: "aidant" } }
            ]
          }
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
  }
`

const GET_GLOBAL_STATS = gql`
  query getGlobalStats($begin: timestamptz!, $end: timestamptz!) {
    monthly: demande(
      where: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
      order_by: { created_at: asc }
    ) {
      created_at
      theme
      status_id
      type_id
    }
    demandes: demande_aggregate(
      where: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
    ) {
      aggregate {
        count
      }
    }
    ressources: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Ressources" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    repit: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Répit" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    mob: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Mobilité" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    life_domains: app_dropdown(where: { type: { _eq: "life_domain" } }) {
      nombre: demande_searches_aggregate(
        where: {
          demande: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    aidants: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          { demande: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] } }
          { type: { _eq: "aidant" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    HMA: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          { demande: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] } }
          { type: { _eq: "HMA" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    partenaires: demande_partenaire_aggregate(
      where: {
        _and: [
          { demande: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    demande_type: app_dropdown(where: { type: { _eq: "demande_type" } }) {
      nombre: demandesByTypeId_aggregate(
        where: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
      ) {
        aggregate {
          count
        }
      }
      name
    }

    structure_types: demande_solution(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Ressources" } }
          ]
        }
      }
    ) {
      partenaire_person {
        partenaire_location {
          app_dropdown {
            name
          }
        }
      }
    }
    demande_contact: app_dropdown(where: { type: { _eq: "demande_contact" } }) {
      nombre: demandes_aggregate(
        where: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_source: app_dropdown(where: { type: { _eq: "demande_source" } }) {
      nombre: demandesBySourceId_aggregate(
        where: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
      ) {
        aggregate {
          count
        }
      }
      name
    }
  }
`

const GET_PARTENAIRE_STATS = gql`
  query getPartenaireStats($begin: timestamptz!, $end: timestamptz!) {
    __typename
    structure_types: app_dropdown(where: { type: { _eq: "structure_type" } }) {
      name
      id
      nombre: partenaire_locations_aggregate(
        where: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
      ) {
        aggregate {
          count
        }
      }
    }
    partenaireTotal: partenaire_location_aggregate(
      where: { _and: [{ created_at: { _gte: $begin } }, { created_at: { _lte: $end } }] }
    ) {
      aggregate {
        count
      }
    }
  }
`

const GET_AIDANT_HMA_LISTS = gql`
  query getAidantHMALists($begin: timestamptz!, $end: timestamptz!) {
    listRepit: demande_aidant_HMA(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Répit" } }
          ]
        }
      }
      distinct_on: id_aidant_HMA
    ) {
      id_aidant_HMA
    }
    listMobilite: demande_aidant_HMA(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Mobilité" } }
          ]
        }
      }
      distinct_on: id_aidant_HMA
    ) {
      id_aidant_HMA
    }
    listRessources: demande_aidant_HMA(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Ressources" } }
          ]
        }
      }
      distinct_on: id_aidant_HMA
    ) {
      id_aidant_HMA
    }
  }
`

const GET_GLOBAL_MOBILITE = gql`
  query getGlobalMobilite($begin: timestamptz!, $end: timestamptz!) {
    demandes: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Mobilité" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    aidant: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            type: { _eq: "aidant" }
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { theme: { _eq: "Mobilité" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    HMA: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            type: { _eq: "HMA" }
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { theme: { _eq: "Mobilité" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }

    demande_type: app_dropdown(where: { type: { _eq: "demande_type" } }) {
      nombre: demandesByTypeId_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Mobilité" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_contact: app_dropdown(where: { type: { _eq: "demande_contact" } }) {
      nombre: demandes_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Mobilité" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }

    demande_source: app_dropdown(where: { type: { _eq: "demande_source" } }) {
      nombre: demandesBySourceId_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Mobilité" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_contact: app_dropdown(where: { type: { _eq: "demande_contact" } }) {
      nombre: demandes_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Mobilité" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_source: app_dropdown(where: { type: { _eq: "demande_source" } }) {
      nombre: demandesBySourceId_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Mobilité" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
  }
`

const GET_GLOBAL_REPIT = gql`
  query getGlobalRepit($begin: timestamptz!, $end: timestamptz!) {
    demandes: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Répit" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    aidant: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            type: { _eq: "aidant" }
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { theme: { _eq: "Répit" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    HMA: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            type: { _eq: "HMA" }
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { theme: { _eq: "Répit" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }

    demande_type: app_dropdown(where: { type: { _eq: "demande_type" } }) {
      nombre: demandesByTypeId_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Répit" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_contact: app_dropdown(where: { type: { _eq: "demande_contact" } }) {
      nombre: demandes_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Répit" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }

    demande_source: app_dropdown(where: { type: { _eq: "demande_source" } }) {
      nombre: demandesBySourceId_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Répit" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_contact: app_dropdown(where: { type: { _eq: "demande_contact" } }) {
      nombre: demandes_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Répit" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_source: app_dropdown(where: { type: { _eq: "demande_source" } }) {
      nombre: demandesBySourceId_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Répit" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
  }
`

const GET_GLOBAL_RESSOURCES = gql`
  query getGlobalRessources($begin: timestamptz!, $end: timestamptz!) {
    demandes: demande_aggregate(
      where: {
        _and: [
          { created_at: { _gte: $begin } }
          { created_at: { _lte: $end } }
          { theme: { _eq: "Ressources" } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    aidant: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            type: { _eq: "aidant" }
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { theme: { _eq: "Ressources" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }
    HMA: demande_aidant_HMA_aggregate(
      where: {
        _and: [
          {
            type: { _eq: "HMA" }
            demande: {
              _and: [
                { created_at: { _gte: $begin } }
                { created_at: { _lte: $end } }
                { theme: { _eq: "Ressources" } }
              ]
            }
          }
        ]
      }
      distinct_on: id_aidant_HMA
    ) {
      aggregate {
        count
      }
    }

    demande_type: app_dropdown(where: { type: { _eq: "demande_type" } }) {
      nombre: demandesByTypeId_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Ressources" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_contact: app_dropdown(where: { type: { _eq: "demande_contact" } }) {
      nombre: demandes_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Ressources" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }

    demande_source: app_dropdown(where: { type: { _eq: "demande_source" } }) {
      nombre: demandesBySourceId_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Ressources" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_contact: app_dropdown(where: { type: { _eq: "demande_contact" } }) {
      nombre: demandes_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Ressources" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
    demande_source: app_dropdown(where: { type: { _eq: "demande_source" } }) {
      nombre: demandesBySourceId_aggregate(
        where: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Ressources" } }
          ]
        }
      ) {
        aggregate {
          count
        }
      }
      name
    }
  }
`

const GET_PAS_DE_MISE_EN_LIEN = gql`
  query getNoSolutions($begin: timestamptz!, $end: timestamptz!) {
    demande_solution_aggregate(
      where: {
        _and: [
          { demande: { created_at: { _gte: $begin } } }
          { demande: { created_at: { _lte: $end } } }
          # preprod
          # { id_partenaire: { _eq: 5087 } }
          # prod
          { id_partenaire: { _eq: 5117 } }
        ]
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

const GET_SOLUTION_TYPE = gql`
  query getRessourceSolutionType($begin: timestamptz!, $end: timestamptz!) {
    solution_ressources: demande_solution(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Ressources" } }
          ]
        }
      }
    ) {
      partenaire_person {
        partenaire_location {
          app_dropdown {
            name
          }
        }
      }
    }
    solution_repit: demande_solution(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Répit" } }
          ]
        }
      }
    ) {
      partenaire_person {
        partenaire_location {
          app_dropdown {
            name
          }
        }
      }
    }

    solution_mobilite: demande_solution(
      where: {
        demande: {
          _and: [
            { created_at: { _gte: $begin } }
            { created_at: { _lte: $end } }
            { theme: { _eq: "Mobilité" } }
          ]
        }
      }
    ) {
      partenaire_person {
        partenaire_location {
          app_dropdown {
            name
          }
        }
      }
    }
  }
`

export {
  GET_HMA_STATS,
  GET_GLOBAL_STATS,
  GET_AIDANTS_STATS,
  GET_PARTENAIRE_STATS,
  GET_AIDANT_HMA_LISTS,
  GET_GLOBAL_MOBILITE,
  GET_GLOBAL_REPIT,
  GET_GLOBAL_RESSOURCES,
  GET_PAS_DE_MISE_EN_LIEN,
  GET_SOLUTION_TYPE,
}
