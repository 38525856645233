import gql from 'graphql-tag'

const INSERT_REMINDERS = gql`
  mutation insertReminders($reminders: [reminders_insert_input!]!) {
    insert_reminders(objects: $reminders) {
      returning {
        date
        id
        id_demande
        is_active
        person
        type
        time
      }
    }
  }
`

const DEACTIVATE_REMINDER = gql`
  mutation updateReminderStatus($id: Int!) {
    update_reminders(where: { id: { _eq: $id } }, _set: { is_active: false }) {
      affected_rows
    }
  }
`
// TODO remove deacivate and validate afecter switching update
const UPDATE_REMINDER = gql`
  mutation updateReminderStatus($id: Int!, $setObject: reminders_set_input!) {
    update_reminders(where: { id: { _eq: $id } }, _set: $setObject) {
      affected_rows
    }
  }
`

const ACTIVE_REMINDERS = gql`
  query getActiveReminders($id_handler: Int!, $today: date!) {
    today: reminders(
      order_by: { date: asc }
      where: {
        _and: [
          { date: { _lte: $today } }
          { handler_id: { _eq: $id_handler } }
          { is_active: { _eq: true } }
          { type: { _neq: "waiting" } }
        ]
      }
    ) {
      date
      id
      __typename
      id_demande
      person
      type
      is_active
      created_at
      time
      user {
        id
        acronym
      }
    }

    waitings: reminders(
      order_by: { date: asc }
      where: {
        _and: [
          { type: { _eq: "waiting" } }
          { handler_id: { _eq: $id_handler } }
          { is_active: { _eq: true } }
        ]
      }
    ) {
      date
      id
      __typename
      id_demande
      person
      type
      is_active
      created_at
      user {
        acronym
      }
    }

    toCome: reminders(
      order_by: { date: asc }
      where: { _and: [{ is_active: { _eq: true } }, { date: { _gt: $today } }] }
    ) {
      date
      id
      __typename
      id_demande
      person
      type
      is_active
      created_at
      user {
        acronym
      }
    }

    repit: reminders(
      order_by: { date: asc }
      where: {
        _and: [
          { is_active: { _eq: true } }
          { date: { _gt: $today } }
          { _or: [{ type: { _eq: "relayeur" } }, { type: { _eq: "Mobilité" } }] }
        ]
      }
    ) {
      date
      id
      __typename
      id_demande
      person
      type
      is_active
      created_at
      user {
        acronym
      }
    }

    rappel: reminders(
      order_by: { date: asc }
      where: {
        _and: [{ is_active: { _eq: true } }, { date: { _gt: $today } }, { type: { _eq: "rappel" } }]
      }
    ) {
      date
      id
      __typename
      id_demande
      person
      type
      is_active
      created_at
      user {
        acronym
      }
    }

    information: reminders(
      order_by: { date: asc }
      where: {
        _and: [
          { is_active: { _eq: true } }
          { date: { _gt: $today } }
          { type: { _eq: "information" } }
        ]
      }
    ) {
      date
      id
      __typename
      id_demande
      person
      type
      is_active
      created_at
      user {
        acronym
      }
    }

    visite: reminders(
      order_by: { date: asc }
      where: {
        _and: [{ is_active: { _eq: true } }, { date: { _gt: $today } }, { type: { _eq: "visite" } }]
      }
    ) {
      date
      id
      __typename
      id_demande
      person
      type
      time
      is_active
      created_at
      user {
        acronym
      }
    }

    waiting: reminders(
      order_by: { date: asc }
      where: {
        _and: [
          { is_active: { _eq: true } }
          { date: { _gt: $today } }
          { type: { _eq: "waiting" } }
        ]
      }
    ) {
      date
      id
      __typename
      id_demande
      person
      type
      is_active
      created_at
      user {
        acronym
      }
    }
  }
`
const DEMANDE_REMINDERS = gql`
  query getDemandeReminders($id: Int!) {
    reminders(where: { _and: [{ id_demande: { _eq: $id } }, { is_active: { _eq: true } }] }) {
      id
      id_demande
      person
      type
      date
      time
      is_active
      __typename
      user {
        id
        acronym
        __typename
      }
    }
  }
`
const VALIDATE_REMINDER = gql`
  mutation validateReminder($id_reminder: Int!) {
    __typename
    update_reminders(where: { id: { _eq: $id_reminder } }, _set: { is_active: false }) {
      affected_rows
      returning {
        id
        is_active
      }
    }
  }
`
export {
  INSERT_REMINDERS,
  DEACTIVATE_REMINDER,
  ACTIVE_REMINDERS,
  DEMANDE_REMINDERS,
  VALIDATE_REMINDER,
  UPDATE_REMINDER,
}
