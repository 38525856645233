import gql from 'graphql-tag'

export const addOccurrenceExceptionMutation = gql`
  mutation addOccurrenceException($occurrenceException: occurrence_exception_insert_input!) {
    insert_occurrence_exception_one(object: $occurrenceException) {
      id
      event_id
      relayeur_id
      relayeur_retour_id
      demande_id
      duration
      event_type
      time
      mobility_arrival_1
      mobility_arrival_2
      mobility_departure_1
      mobility_departure_2
      mobility_mode
      mobility_signed
      mobility_trip_type
      repit_address
      repit_mode
      new_date
      cancelled
      recurrence
      demande {
        demande_aidant_HMAs {
          identity_aidant_HMA {
            id
            firstname
            lastname
            type
          }
        }
      }
      partenaire_person {
        id
        email
        firstname
        lastname
        actif
        unavailabilities: partenaire_unavailabilities {
          all_day
          created_at
          date_end
          date_start
          id
          rrule
        }
        structure: partenaire_location {
          id
        }
      }
      retour_partenaire_person {
        id
        email
        firstname
        lastname
        actif
        unavailabilities: partenaire_unavailabilities {
          all_day
          created_at
          date_end
          date_start
          id
          rrule
        }
        structure: partenaire_location {
          id
        }
      }
      cancellation_reason
      cancelled
    }
  }
`
