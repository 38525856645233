import uniqBy from 'lodash/uniqBy'

import {
  ADD_IDENTITY,
  // GET_AIDANTS_HMA,
  GET_IDENTITY_DETAILS,
  INSERT_DEMANDE_AIDANT_MHA,
  UPDATE_STATUS,
  UPDATE_IDENTITY,
  SEARCH_IDENTITY_BY_NAME,
  GET_RGPD_EMAIL,
  GET_ALL_EMAIL,
  GET_HMA_WITH_AIDANT,
  // ADD_ADHESION,
  UPDATE_ADHESION,
  ADD_IDENTITY_COMMENT,
} from '@/graphql/identity'

const initialState = () => {
  return {
    sex: null,
    firstname: null,
    lastname: null,
    adress: null,
    cedex_city_id: null,
    hebergement: null,
    building_floor: null,
    access_codes: null,
    phone_number: null,
    birthday: null,
    email: null,
    status_id: null,
    category: [],
    adhesions: [],
    family_link_id: null,
    host_id: null,
  }
}
export default {
  namespaced: true,
  state: {
    identity: initialState(),
    aidantsHMA: null,
    filter: {
      category: null,
      city: null,
      year: null,
    },
    identityDetails: null,
    identityDemandes: null,
    identityDemandesStatus: {
      open: 0,
      waiting: 0,
      close: 0,
      call: 0,
    },
    identityList: [],
    adhesions: [],
    anonym: false,
  },
  getters: {
    lastAdhesion(state) {
      return state.identityDetails.adhesions[state.identityDetails.adhesions.length - 1]
    },
    identity(state) {
      return state.identity
    },
    aidantsHMA(state) {
      let aidantsHMA = state.aidantsHMA
      if (state.filter.category) {
        let categFilter = state.filter.category.toLowerCase()
        if (categFilter === 'âgée') {
          categFilter = 'agée'
        }

        aidantsHMA = aidantsHMA.filter((identity) => {
          return identity.category.includes(categFilter)
        })
      }

      if (state.filter.city) {
        aidantsHMA = aidantsHMA.filter((identity) => {
          return identity.cedex_city ? identity.cedex_city.id === state.filter.city : false
        })
      }

      if (state.filter.year) {
        aidantsHMA = aidantsHMA.filter((demande) => {
          const year = new Date(demande.created_at).getFullYear()
          return year === state.filter.year
        })
      }

      return aidantsHMA
    },
    filter(state) {
      return state.filter
    },
    identityDetails(state) {
      if (state.identityDetails) {
        state.identityDetails.created_at = formatDate(state.identityDetails.created_at)
        state.identityDetails.updated_at = formatDate(state.identityDetails.updated_at)
        state.identityDetails.birthday = state.identityDetails.birthday
          ? `${formatDate(state.identityDetails.birthday)} (${getAge(
              state.identityDetails.birthday,
            )} ans)`
          : null
      }
      return state.identityDetails
    },
    identityDemandes(state) {
      return state.identityDemandes
    },
    identityDemandesStatus(state) {
      return state.identityDemandesStatus
    },
    isDead(state) {
      return state.identityDetails.dead
    },
    identityList(state) {
      return state.identityList
    },
    isAnonym(state) {
      return state.anonym
    },
  },
  mutations: {
    setIdentity(state, identity) {
      state.identity = identity
    },
    addType(state) {
      state.identity.type = 'aidant'
    },
    joinCategory(state) {
      const category = state.identity.category
      state.identity.category = !category || category.length === 0 ? null : category.join(';')
    },
    resetAidant(state) {
      state.identity = initialState()
    },
    SET_AIDANTS_HMA(state, aidantsHMA) {
      if (aidantsHMA) {
        for (const identity of aidantsHMA) {
          if (identity.type === 'aidant') {
            if (identity.category) {
              identity.category += ';aidant'
              identity.category = identity.category.split(';')
            } else identity.category = ['aidant']
          } else if (identity.type === 'HMA') {
            identity.category = identity.category ? identity.category.split(';') : []
          }
        }
        state.aidantsHMA = aidantsHMA
      }
    },
    SET_IDENTITY_DETAILS(state, details) {
      state.identityDetails = details
    },
    SET_IDENTITY_DEMANDE(state, demandes) {
      for (const demande of demandes) {
        switch (demande.status_id) {
          case 0: {
            state.identityDemandesStatus.open++
            break
          }
          case 1: {
            state.identityDemandesStatus.waiting++
            break
          }
          case 2: {
            state.identityDemandesStatus.call++
            break
          }
          case 3: {
            {
              state.identityDemandesStatus.closed++
              // No default
            }
            break
          }
        }
      }
      state.identityDemandes = demandes
    },
    RESET_IDENTITY_DETAILS(state) {
      state.identityDetails = null
      state.adhesions = null
      state.identityDemandes = null
      state.identityDemandesStatus = {
        open: 0,
        waiting: 0,
        close: 0,
        call: 0,
      }
    },
    SET_IDENTITY_SEARCH(state, identityList) {
      state.identityList = identityList
    },
    SET_ADHESIONS(state, adhesions) {
      state.adhesions = adhesions
    },
    SET_ANONYM(state, value) {
      state.anonym = value
    },
  },
  actions: {
    async insertIdentity({ state, commit }) {
      const identity = state.identity
      delete identity.adhesions
      if (identity !== null) {
        // commit("addDemandeId", rootState.demande.demandeId);
        commit('addType')
        commit('joinCategory')
        const response = await this._vm.$apollo.mutate({
          mutation: ADD_IDENTITY,
          variables: {
            identity,
          },
        })
        return response.data.insert_identity_aidant_HMA.returning[0]
      }
    },
    async storeIdentity({ commit }, identity) {
      await commit('setIdentity', identity)
    },
    resetAidant({ commit }) {
      commit('resetAidant')
    },
    async getAidantsHMA({ commit }) {
      const response = await this._vm.$apollo.query({
        query: GET_HMA_WITH_AIDANT,
      })

      const results = transformDemandeHMA(response.data.demande_aidant_HMA)
      commit('SET_AIDANTS_HMA', results)
      //
    },
    async getIdentityDetails({ commit }, id) {
      commit('RESET_IDENTITY_DETAILS')
      const response = await this._vm.$apollo.mutate({
        mutation: GET_IDENTITY_DETAILS,
        variables: {
          id_identity: id,
        },
        fetchPolicy: 'no-cache',
      })
      commit('SET_IDENTITY_DEMANDE', response.data.demande)
      commit('SET_IDENTITY_DETAILS', response.data.identity_aidant_HMA[0])
    },
    async insertDemandeAidantHMA(_, ids) {
      await this._vm.$apollo.mutate({
        mutation: INSERT_DEMANDE_AIDANT_MHA,
        variables: {
          ids,
        },
      })
    },
    resetIdentityDetails({ commit }) {
      commit('RESET_IDENTITY_DETAILS')
    },
    async updateStatus(_, { id, isDead }) {
      await this._vm.$apollo.mutate({
        mutation: UPDATE_STATUS,
        variables: {
          id,
          isDead,
        },
      })
    },
    async updateIdentity({ state, dispatch }, { id, identity }) {
      delete identity.adhesions
      await this._vm.$apollo.mutate({
        mutation: UPDATE_IDENTITY,
        variables: {
          id,
          identity,
        },
      })
      const oldAdhesions = state.adhesions
        .filter((adhesion) => adhesion.id !== -1)
        .map((adhesion) => {
          return {
            id: adhesion.id,
            start_date: adhesion.start_date,
            end_date: adhesion.end_date,
            identity_aidant_HMA_id: id,
          }
        })

      const newAdhesions = state.adhesions
        .filter(
          (adhesion) =>
            adhesion.id === -1 && adhesion.start_date !== '' && adhesion.end_date !== '',
        )
        .map((adhesion) => {
          return {
            start_date: adhesion.start_date,
            end_date: adhesion.end_date,
            identity_aidant_HMA_id: id,
          }
        })
      const adhesions = [...oldAdhesions, ...newAdhesions]

      if (adhesions.length > 0) {
        await this._vm.$apollo.mutate({
          mutation: UPDATE_ADHESION,
          variables: {
            adhesions,
          },
        })
      }
      /* Add comment if comment */

      await dispatch('insertComment', { id, comment: identity.comment })
      dispatch('getIdentityDetails', id)
    },
    async searchIdentityByName({ commit }, { name }) {
      const response = await this._vm.$apollo.mutate({
        mutation: SEARCH_IDENTITY_BY_NAME,
        variables: {
          name,
        },
      })
      commit('SET_IDENTITY_SEARCH', response.data.identities)
    },
    async getRGPDEmail() {
      const response = await this._vm.$apollo.query({
        query: GET_RGPD_EMAIL,
      })
      return response.data.identity_aidant_HMA
    },
    async getAllEmail() {
      const response = await this._vm.$apollo.query({
        query: GET_ALL_EMAIL,
      })
      return response.data.identity_aidant_HMA
    },
    async insertComment({ state }, { id, comment }) {
      const content = comment ?? state.identity.comment
      const aidantId = id ?? state.identity.id

      if (content !== '' && content !== undefined) {
        const comments = [
          {
            content,
            aidant_HMA_id: aidantId,
          },
        ]

        await this._vm.$apollo.mutate({
          mutation: ADD_IDENTITY_COMMENT,
          variables: {
            comments,
          },
        })
      }
    },
    updateAdhesions({ commit }, adhesions) {
      commit('SET_ADHESIONS', adhesions)
    },
  },
}

function getAge(dateString) {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

function formatDate(date) {
  const fullDate = new Date(date)
  let day = fullDate.getDate()
  let month = fullDate.getMonth() + 1
  const year = fullDate.getFullYear()
  if (day < 10) day = '0' + day
  if (month < 10) month = '0' + month
  return `${day}/${month}/${year}`
}

function transformDemandeHMA(elems) {
  const results = elems
    .filter(
      (element) =>
        element.identity_aidant_HMA.isAnonymized === false &&
        element.demande?.demande_aidant_HMAs.length > 0,
    )
    .map((element) => {
      return {
        id: element.identity_aidant_HMA?.id,
        type: element?.type,
        dead: element.identity_aidant_HMA?.dead,
        sex: element.identity_aidant_HMA?.sex,
        firstname: element.identity_aidant_HMA?.firstname,
        lastname: element.identity_aidant_HMA?.lastname,
        cedex_city: element.identity_aidant_HMA?.cedex_city,
        category: element.identity_aidant_HMA?.category,
        created_at: element.identity_aidant_HMA?.created_at,
        aidant_id:
          element?.type === 'aidant'
            ? ''
            : element.demande?.demande_aidant_HMAs[0]?.identity_aidant_HMA?.id,
        aidant_sex:
          element?.type === 'aidant'
            ? ''
            : element.demande?.demande_aidant_HMAs[0]?.identity_aidant_HMA?.sex,
        aidant_firstname:
          element?.type === 'aidant'
            ? ''
            : element.demande?.demande_aidant_HMAs[0]?.identity_aidant_HMA?.firstname,
        aidant_lastname:
          element?.type === 'aidant'
            ? ''
            : element.demande?.demande_aidant_HMAs[0]?.identity_aidant_HMA?.lastname,
      }
    })
  return uniqBy(results, (element) => element.id)
}
