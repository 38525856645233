import { GET_DROPDOWN_DATAS, GET_CITY_CEDEX, GET_KEYWORDS } from '@/graphql/dropdowns'
const defaultState = () => {
  return {
    structure_type: [],
    identity_family_link: [],
    identity_status: [],
    demande_type: [],
    demande_source: [],
    demande_contact: [],
    life_domain: [],
    cities: [],
    keywords: [],
  }
}
export default {
  namespaced: true,
  state: defaultState(),
  getters: {
    demandeContact(state) {
      return state.demande_contact
    },
    demandeSource(state) {
      return state.demande_source
    },
    demandeType(state) {
      return state.demande_type
    },
    structureType(state) {
      return state.structure_type
    },
    identityFamilyLink(state) {
      return state.identity_family_link
    },
    identityStatus(state) {
      return state.identity_status
    },
    lifeDomain(state) {
      return state.life_domain
    },
    cities(state) {
      return state.cities
    },
    keywords(state) {
      return state.keywords
    },
  },
  mutations: {
    dispatchDropdownData(state, dropdownData) {
      for (const element of dropdownData) {
        state[element.type].push(element)
      }
      state.demande_type.push(state.demande_type.splice(0, 1)[0])
    },
    resetState(state) {
      Object.assign(state, defaultState())
    },
    setCities(state, cities) {
      state.cities = cities
    },
    setKeywords(state, keywords) {
      state.keywords = keywords
    },
  },
  actions: {
    async getDropdownData({ commit }) {
      commit('resetState')
      const response = await this._vm.$apollo.query({
        query: GET_DROPDOWN_DATAS,
      })
      commit('dispatchDropdownData', response.data.app_dropdown)
    },
    async getCities({ commit }) {
      const response = await this._vm.$apollo.query({
        query: GET_CITY_CEDEX,
      })
      commit('setCities', response.data.cedex_city)
    },
    async getKeywords({ commit }) {
      const response = await this._vm.$apollo.query({
        query: GET_KEYWORDS,
      })
      commit('setKeywords', response.data.keywords)
    },
  },
}
