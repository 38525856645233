import Vue from 'vue'
import App from './App.vue'

import '@/scss/global.scss'
import '@/plugins'

import { loadSentry } from '@/plugins/sentry.plugin'

import router from '@/router'
import store from '@/store'

if (process.env.NODE_ENV === 'production') {
  loadSentry(router)
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
