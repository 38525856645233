import gql from 'graphql-tag'

const GET_DROPDOWN_DATAS = gql`
  query getDropdownDatas {
    app_dropdown(order_by: { id: asc }) {
      id
      name
      type
    }
  }
`

const GET_CITY_CEDEX = gql`
  query getCities {
    cedex_city {
      cedex
      city
      lat
      lon
      id
    }
  }
`

const GET_KEYWORDS = gql`
  query getKeywords {
    keywords(order_by: { label: asc }) {
      label
      id
    }
  }
`

const GET_LIFE_DOMAINS = gql`
  query GetLifeDomains {
    app_dropdown(where: { type: { _eq: "life_domain" } }, order_by: { id: asc }) {
      name
      id
    }
  }
`

export { GET_DROPDOWN_DATAS, GET_CITY_CEDEX, GET_KEYWORDS, GET_LIFE_DOMAINS }
