import {
  ADD_PARTENAIRE_LOCATION,
  ADD_PARTENAIRE_PERSON,
  // ADD_PARTENAIRE_KEYWORDS,
  GET_PARTENAIRE_LIST,
  // ADD_PARTENAIRE_LIFE_DOMAIN,
  SEARCH_PARTENAIRE_BY_KEYWORDS,
  SEARCH_NB_KEYWORDS,
  GET_PARTENAIRE_BY_NAME,
  GET_PERSON_BY_NAME,
  GET_PERSON_BY_NAME_ONLY,
  GET_PERSON_BY_NAME_AND_STRUCTURE_TYPE,
  ADD_PERSON_KEYWORDS,
  SEARCH_STRUCTURE,
} from '@/graphql/partenaire'

import { GET_STRUCTURE_TYPES } from '@/graphql/navbar'

const initialLocationState = () => {
  return {
    name: null,
    adress: null,
    complement: null,
    cedex_city_id: null,
    phone_number1: null,
    dontCommunicatePhoneNumber1: false,
    phone_number2: null,
    dontCommunicatePhoneNumber2: false,
    email: null,
    dontCommunicateEmail: false,
    structure_type_id: null,
  }
}
const initialPersonState = () => {
  return {
    sex: null,
    firstname: null,
    lastname: null,
    phone_number1: null,
    dontCommunicatePhoneNumber1: false,
    phone_number2: null,
    dontCommunicatePhoneNumber2: false,
    email: null,
    dontCommunicateEmail: false,
    profession: null,
    service: null,
    location_id: null,
    actif: null,
  }
}
export default {
  namespaced: true,
  state: {
    partenaireLocationId: null,
    partenaireLocation: initialLocationState(),
    partenairePerson: initialPersonState(),
    partenaireLifeDomains: null,
    personKeywords: null,
    partenaires: null,
    citiesOptions: [],
    keywordsOptions: [],
    filter: {
      query: null,
      city: null,
      keyword: null,
    },
    partenaire: null,
    structure_types: [],
    structure_type_objects: [],
    partenaireList: [],
    personList: [],
    partenairePersonList: [],
    structureList: [],
    beneficiaires: [],
  },

  getters: {
    structureTypes(state) {
      return state.structure_types
    },
    structureTypeObjects(state) {
      return state.structure_type_objects
    },
    getPartenaireLocation(state) {
      return state.partenaireLocation
    },
    getPartenairePerson(state) {
      return state.partenairePerson
    },
    partenaires: (state) => (queryFilter) => {
      let partenaires = state.partenaires

      //FILTER BY QUERY
      if (queryFilter) {
        partenaires = partenaires?.filter(
          (partenaire) => partenaire.structure.app_dropdown?.name == queryFilter,
        )
      }

      //FILTER BY CITY
      if (state.filter.city) {
        partenaires = partenaires?.filter(
          (partenaire) =>
            partenaire.structure.cedex_city?.city === state.filter.city || state.filter.city === '',
        )
      }

      //FILTER BY KEYWORD
      if (state.filter.keyword) {
        const sortKeys = state.filter.keyword
        if (sortKeys == undefined || sortKeys.length === 0) {
          return partenaires
        }
        const temporaryFilteredItems = []
        for (const [_index, item] of partenaires.entries()) {
          let isPushed = false
          item.nb_keywords = 0
          for (const [_index, keyword] of item.person_keywords.entries()) {
            for (const key of sortKeys) {
              if (keyword.keyword.id == key.id) {
                item.nb_keywords = item.nb_keywords + 1
                isPushed = true
              }
            }
          }
          if (isPushed) temporaryFilteredItems.push(item)
        }
        partenaires = temporaryFilteredItems.sort((a, b) => b.nb_keywords - a.nb_keywords)
      }
      return partenaires
    },

    citiesOptions(state) {
      return state.citiesOptions
    },

    keywordsOptions(state) {
      return state.keywordsOptions
    },

    filter(state) {
      return state.filter
    },

    partenaire(state) {
      return state.partenaire
    },

    ID(state) {
      return state.partenaire.id
    },

    name(state) {
      return state.partenaire.name
    },
    partenaireList(state) {
      return state.partenaireList
    },
    personList(state) {
      return state.personList
    },
    partenairePersonList(state) {
      return state.partenairePersonList.map((p) => {
        return {
          ...p,
          fullName: `${p.firstname || ''} ${p.lastname}`,
        }
      })
    },
    structureList(state) {
      return state.structureList
    },
    beneficiaires(state) {
      return state.beneficiaires.map((b) => {
        return {
          id: b.id,
          fullName: `${b.firstname || ''} ${b.lastname || ''}`,
          ...b,
        }
      })
    },
  },

  mutations: {
    setNavbarStructureTypes(state, structure_types) {
      state.structure_types = structure_types.map((structure) => structure.name)
    },
    setStructureTypeObjects(state, structure_types) {
      state.structure_type_objects = structure_types
    },
    setPartenaireLocation(state, partenaireLocation) {
      state.partenaireLocation = partenaireLocation
    },
    setPartenairePerson(state, partenairePerson) {
      state.partenairePerson = partenairePerson
    },
    setPartenaireLocationId(state, id) {
      state.partenaireLocationId = id
    },
    setPartenaireLifeDomains(state, lifeDomains) {
      state.partenaireLifeDomains = lifeDomains
    },
    setPersonKeywords(state, keywords) {
      state.personKeywords = keywords
    },
    addDemandeId(state, id) {
      state.partenairePerson.demande_id = id
    },
    addLocationId(state, locationId) {
      if (Array.isArray(state.partenairePerson)) {
        for (const person of state.partenairePerson) {
          person.location_id = locationId
        }
      } else {
        state.partenairePerson.location_id = locationId
      }
    },
    resetPartenaire(state) {
      state.partenaireLocation = initialLocationState()
      state.partenairePerson = initialPersonState()
    },
    formatIdArray(state, { name, id_person, id_name }) {
      const idsArray = []
      if (!state[`${name}`]) return (state[`${name}`] = [])
      for (const id of state[`${name}`]) {
        const object = {
          id_person: id_person,
        }
        object[`${id_name}`] = id
        idsArray.push(object)
      }
      state[`${name}`] = idsArray
    },

    SET_PARTENAIRES(state, partenaires) {
      state.partenaires = partenaires
    },

    SET_CITIES_OPTIONS(state, cities) {
      state.citiesOptions = cities
    },

    SET_KEYWORDS_OPTIONS(state, keywords) {
      state.keywordsOptions = keywords
    },

    SET_CITY_FILTER(state, city) {
      state.filter.city = city
    },

    SET_PARTENAIRE(state, partenaire) {
      state.partenaire = partenaire
    },

    UNSET_PARTENAIRE(state) {
      state.partenaire = null
    },
    SET_PARTENAIRE_SEARCH_RESULT(state, list) {
      state.partenaireList = list
    },
    SET_PERSON_SEARCH_RESULT(state, persons) {
      state.personList = persons
    },
    SET_PARTENAIRE_PERSON_SEARCH_RESULT(state, persons) {
      state.partenairePersonList = persons
    },
    RESET_SEARCH_PARTENAIRE(state) {
      state.partenaireList = []
    },
    RESET_SEARCH_PERSON(state) {
      state.personList = []
    },
    SET_STRUCTURE_LIST(state, list) {
      state.structureList = list
    },
  },

  actions: {
    async retrieveStructureTypes({ commit }) {
      try {
        const {
          data: { app_dropdown },
        } = await this._vm.$apollo.query({
          query: GET_STRUCTURE_TYPES,
        })

        commit('setNavbarStructureTypes', app_dropdown)
        commit('setStructureTypeObjects', app_dropdown)

        return app_dropdown
      } catch (error) {
        throw new Error(`[store/partenaire](retrieveStructureTypes) ${error}`)
      }
    },
    insertPartenaireLocation({ state }) {
      return this._vm.$apollo.mutate({
        mutation: ADD_PARTENAIRE_LOCATION,
        variables: {
          partenaire_location: state.partenaireLocation,
        },
      })
    },
    insertPartenairePerson({ state, commit }, { partenaireLocationId }) {
      commit('addLocationId', partenaireLocationId)
      return this._vm.$apollo.mutate({
        mutation: ADD_PARTENAIRE_PERSON,
        variables: {
          partenaire_person: state.partenairePerson,
        },
      })
    },
    async insertMultiplePartenairePerson({ state, commit, dispatch }, { partenaireLocationId }) {
      await commit('addLocationId', partenaireLocationId)

      await asyncForEach(state.partenairePerson, async (person) => {
        const personKeywords = person.keywords
        delete person.keywords

        const {
          data: { insert_partenaire_person },
        } = await this._vm.$apollo.mutate({
          mutation: ADD_PARTENAIRE_PERSON,
          variables: {
            partenaire_person: person,
          },
        })

        const personId = insert_partenaire_person.returning[0].id
        await commit('setPersonKeywords', personKeywords)
        await dispatch('insertPersonKeywords', personId)
      })
    },
    async storeLocationPartenaireKeywords({ commit }, { location, person, lifeDomains, keywords }) {
      await commit('setPartenaireLocation', location)
      await commit('setPartenairePerson', person)
      if (lifeDomains) await commit('setPartenaireLifeDomains', lifeDomains)
      if (keywords) await commit('setPersonKeywords', keywords)
    },
    // old way of adding keywords to the partenaire

    // async insertPartenaireKeywords ({ state, commit }, id_partenaire) {
    //   await commit('formatIdArray', { name: 'partenaireKeywords', id_partenaire, id_name: 'id_keyword' })
    //   return this._vm.$apollo.mutate({
    //     mutation: ADD_PARTENAIRE_KEYWORDS,
    //     variables: {
    //       keywords: state.partenaireKeywords
    //     }
    //   });
    // },
    async insertPersonKeywords({ state, commit }, id_person) {
      await commit('formatIdArray', {
        name: 'personKeywords',
        id_person,
        id_name: 'id_keyword',
      })
      return this._vm.$apollo.mutate({
        mutation: ADD_PERSON_KEYWORDS,
        variables: {
          keywords: state.personKeywords,
        },
      })
    },
    async searchPartenaireByKeywords({ rootState }, keywords) {
      let partenaireFilter = rootState.demande.demandePartenaire
      if (!partenaireFilter) partenaireFilter = 0
      const partenairesData = await this._vm.$apollo.mutate({
        mutation: SEARCH_PARTENAIRE_BY_KEYWORDS,
        variables: {
          keywords,
          partenaireFilter,
        },
      })

      return partenairesData.data.partenaire_person
    },

    async nbOfKeywords({ _commit }, { id_partenaire, keywords }) {
      return this._vm.$apollo.query({
        query: SEARCH_NB_KEYWORDS,
        variables: {
          id_partenaire,
          keywords,
        },
      })
    },

    async getPartenaires({ commit, dispatch }) {
      const response = await this._vm.$apollo.query({
        query: GET_PARTENAIRE_LIST,
      })
      commit('SET_PARTENAIRES', response.data.partenaire_person)
      dispatch('setCitiesOptions')
      dispatch('setKeywordOptions')
    },

    // SET CITIES KEYWORD OPTION
    setCitiesOptions({ state, commit }) {
      const citiesValue = []
      let push = true
      citiesValue.push({ value: undefined, text: '' })
      for (const partenaire of state.partenaires) {
        const cedex = partenaire.structure.cedex_city
        if (cedex && cedex.city && cedex.city !== '') {
          push = true
          for (const city of citiesValue) {
            if (city.value == cedex.city) push = false
          }
          if (push) citiesValue.push({ value: cedex.city, text: cedex.city })
        }
      }
      commit('SET_CITIES_OPTIONS', citiesValue)
    },

    // SET SELECT KEYWORD OPTION
    setKeywordOptions({ state, commit }) {
      const keywordsValue = []
      let push = true
      for (const partenaire of state.partenaires) {
        const keywords = partenaire.person_keywords
        if (keywords && keywords.length > 0) {
          for (const keyword of keywords) {
            push = true
            for (const keywordValue of keywordsValue) {
              if (keywordValue.value == keyword.keyword.id) push = false
            }
            if (push)
              keywordsValue.push({
                value: keyword.keyword.id,
                text: keyword.keyword.label,
              })
          }
        }
      }
      commit('SET_KEYWORDS_OPTIONS', keywordsValue)
    },

    setCityFilter({ commit }, filter) {
      commit('SET_CITY_FILTER', filter)
    },

    unsetPartenaire({ commit }) {
      commit('UNSET_PARTENAIRE')
    },

    async searchPartenaireByName({ commit }, name) {
      const response = await this._vm.$apollo.query({
        query: GET_PARTENAIRE_BY_NAME,
        variables: {
          name,
        },
      })

      commit('SET_PARTENAIRE_SEARCH_RESULT', response.data.partenaire_location)
    },
    async searchPersonByName({ commit }, { name, id }) {
      const where = {
        actif: { _eq: true },
        lastname: { _ilike: name },
      }
      if (id) {
        where.partenaire_location = { id: { _eq: id } }
      }
      const response = await this._vm.$apollo.query({
        query: GET_PERSON_BY_NAME,
        variables: { where },
      })

      commit('SET_PERSON_SEARCH_RESULT', response.data.partenaire_person)
    },

    async searchPersonByNameOnly({ commit }, { name }) {
      const response = await this._vm.$apollo.query({
        query: GET_PERSON_BY_NAME_ONLY,
        variables: {
          name: `%${name}%`,
        },
      })

      commit('SET_PARTENAIRE_PERSON_SEARCH_RESULT', response.data.partenaire_person)
    },
    setSearchPerson({ commit }, persons) {
      commit('SET_PERSON_SEARCH_RESULT', persons)
    },
    resetPartenaireSearch({ commit }) {
      commit('RESET_SEARCH_PARTENAIRE')
      commit('RESET_SEARCH_PERSON')
      commit('resetPartenaire')
    },
    resetPersonSearch({ commit }) {
      commit('RESET_SEARCH_PERSON')
      commit('resetPartenaire')
    },

    resetPartenaire({ commit }) {
      commit('resetPartenaire')
    },
    async searchPersonByNameAndStructureType({ _commit }, { search, structureTypeId }) {
      const name = `%${search}%`
      const response = await this._vm.$apollo.query({
        query: GET_PERSON_BY_NAME_AND_STRUCTURE_TYPE,
        variables: {
          name,
          structure_type_id: structureTypeId,
        },
      })

      return response.data.partenaire_person

      // commit("SET_PERSON_SEARCH_RESULT", response.data.partenaire_person);
    },
    async searchStructure({ commit }, { name }) {
      const response = await this._vm.$apollo.query({
        query: SEARCH_STRUCTURE,
        variables: {
          name,
        },
      })

      commit('SET_STRUCTURE_LIST', response.data.structures)
    },
  },
}

const asyncForEach = async (array, callback) => {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array)
  }
}
