// @ts-check

import {
  ADD_DEMANDE_COMMENT,
  UPDATE_DEMANDE_COMMENT,
  UPDATE_DEMANDE_STATUS,
  ADD_DEMANDE_SOLUTION,
  ADD_DEMANDE_PARTENAIRE,
  GET_DEMANDE_PARTENAIRE_AND_AIDANT,
  GET_DEMANDE_LIST,
  ADD_DEMANDE_SEARCH,
  ADD_DEMANDE_PERTINENCE,
  GET_DEMANDE_PERTINENCE,
  DELETE_DEMANDE_SEARCH,
  INSERT_FAMILY_LINK,
  // INSERT_DEMANDE_SEARCH,
  GET_DEMANDE_DETAIL,
  UPDATE_SEARCH,
  UPDATE_FAMILIY_LINK,
  GET_DEMANDE_SOLUTION,
  GET_HISTORIQUE_EXPORT,
  REMOVE_DEMANDE_SOLUTION,
  DELETE_DEMANDE,
  ADD_COMPLETE_DEMANDE,
  UPDATE_HANDLER,
  LAST_DEMANDE,
} from '@/graphql/demande'
import { ADD_DEMANDE_SUGGESTION } from '../graphql/demande'
import { ADD_FAMILY_LINK } from '../graphql/familyLink'

import axios from 'axios'

const initialDemandeState = () => {
  return {
    theme: null,
    contact_method_id: null,
    source_id: null,
    type_id: null,
    // change to the user id
    handler_id: null,
    created_at: new Date().toLocaleString('fr-FR').split(',')[0].split('/').reverse().join('-'),
  }
}
export default {
  namespaced: true,
  state: {
    demande: initialDemandeState(),
    demandeId: null,
    demandePartenaire: null,
    demandeAidant: null,
    demandeHMA: null,
    demandeList: null,
    demandeAidantHMA: null,
    filter: {
      type: null,
      lifeDomain: null,
      year: null,
      acronym: null,
      city: null,
    },
    demandeListLength: {
      all: null,
      waiting: null,
      open: null,
      closed: null,
      call: null,
    },
    demandeDetails: null,
    isDemandeDetailsReady: false,
    demandePertinence: null,
    solution: null,
    historiqueExport: null,
    search: null,
    demandeType: null,
  },
  getters: {
    demande(state) {
      return state.demande
    },
    demandeId(state) {
      return state.demandeId
    },
    demandePartenaire(state) {
      return state.demandePartenaire
    },
    demandeAidant(state) {
      return state.demandeAidant
    },
    demandeHMA(state) {
      return state.demandeHMA
    },
    demandeAidantHMA(state) {
      return state.demandeAidantHMA
    },
    demandeList(state) {
      let demandes = state.demandeList
      if (state.filter.type) {
        demandes = demandes.filter((demande) => {
          return demande.appDropdownByTypeId.id == state.filter.type
        })
      }
      if (state.filter.lifeDomain) {
        demandes = demandes.filter((demande) => {
          if (demande.demande_search) {
            return demande.demande_search.app_dropdown.id == state.filter.lifeDomain
          }
        })
      }
      if (state.filter.year) {
        demandes = demandes.filter((demande) => {
          const year = new Date(demande.created_at).getFullYear()
          return year == state.filter.year
        })
      }
      if (state.filter.acronym) {
        demandes = demandes.filter((demande) => demande.user.acronym === state.filter.acronym)
      }
      if (state.filter.city) {
        demandes = demandes.filter(
          (demande) => demande.demande_search?.cedex_city?.id === state.filter.city,
        )
      }
      return demandes
    },
    filter(state) {
      return state.filter
    },
    demandeListLength(state) {
      return state.demandeListLength
    },
    demandeDetails(state) {
      return state.demandeDetails
    },
    demandeRappels(state) {
      return state.demandeRappels
    },
    demandeWaitings(state) {
      return state.demandeWaitings
    },
    isDemandeDetailsReady(state) {
      return state.isDemandeDetailsReady
    },
    demandePertinence(state) {
      return state.demandePertinence
    },
    hasSolution(state) {
      return state.solution
    },
    hasSearch(state) {
      return state.search
    },
    historiqueExport(state) {
      return state.historiqueExport
    },
    demandeType(state) {
      return state.demandeType
    },
  },
  mutations: {
    SET_DEMANDE(state, demande) {
      state.demande = demande
    },
    setDemandeId(state, id) {
      state.demandeId = id
    },
    setDemandePartenaire(state, demandePartenaire) {
      state.demandePartenaire = demandePartenaire
    },
    setDemandeAidant(state, demandeAidant) {
      state.demandeAidant = demandeAidant
    },
    setDemandeHMA(state, demandeHMA) {
      state.demandeHMA = demandeHMA
    },
    setDemandeAidantHMA(state, demandeAidantHMA) {
      state.demandeAidantHMA = demandeAidantHMA
    },
    resetDemande(state) {
      state.demandeId = null
      state.demande = initialDemandeState()
    },
    setDemandeList(state, demandeList) {
      state.demandeList = demandeList
    },
    setDemandeListLength(state, demandeList) {
      let open = 0,
        waiting = 0,
        closed = 0,
        call = 0
      const all = demandeList.length

      for (const demande of demandeList) {
        switch (demande.status_id) {
          case 0: {
            open++
            break
          }
          case 1: {
            waiting++
            break
          }
          case 2: {
            call++
            break
          }
          case 3: {
            {
              closed++
              // No default
            }
            break
          }
        }
      }
      state.demandeListLength = {
        open,
        waiting,
        closed,
        call,
        all,
      }
    },
    SET_DEMANDE_DETAILS(state, demandeDetail) {
      state.demandeDetails = { detail: demandeDetail }
    },
    SET_DEMANDE_SOLUTION(state, solution) {
      state.demandeDetails.solution = solution
    },
    SET_DEMANDE_SUGGESTIONS(state, suggestions) {
      state.demandeDetails.suggestions = suggestions
    },
    SET_DEMANDE_PARTENAIRE(state, partenaire) {
      state.demandeDetails.partenaire = partenaire.length === 0 ? null : partenaire[0]
    },
    SET_DEMANDE_COMMENTS(state, comments) {
      if (comments.length === 0) state.demandeDetails.comments = null
      else {
        for (const comment of comments) {
          comment.comment = JSON.parse(comment.comment)
        }
        state.demandeDetails.comments = comments
      }
      state.isDemandeDetailsReady = true
    },

    SET_DEMANDE_AIDANT_HMA(state, aidant_hma) {
      try {
        if (aidant_hma.length === 0) {
          state.demandeDetails.aidant = null
          state.demandeDetails.HMA = null
        } else {
          for (const identity of aidant_hma) {
            if (identity.type === 'aidant')
              state.demandeDetails.aidant = identity.identity_aidant_HMA
            else if (identity.type === 'HMA')
              state.demandeDetails.HMA = identity.identity_aidant_HMA
          }
        }
      } catch (error) {
        new Error(`[demande](SET_DEMANDE_AIDANT_HMA) ${error}`)
      }
    },
    resetDetails(state) {
      state.demandeDetails = null
      state.isDemandeDetailsReady = false
    },
    pushNewComment(state, newCommentObject) {
      newCommentObject.comment = JSON.parse(newCommentObject.comment)
      state.demandeDetails.comments = state.demandeDetails.comments
        ? [newCommentObject, ...state.demandeDetails.comments]
        : [newCommentObject]
    },
    SET_DEMANDE_PERTINENCE(state, demandePertinence) {
      state.demandePertinence = demandePertinence
    },
    RESET_PERSONS(state) {
      state.demandeAidant = null
      state.demandePartenaire = null
      state.demandeHMA = null
    },
    SET_HAS_SOLUTION(state, solution) {
      state.solution = solution
    },
    SET_HAS_SEARCH(state, search) {
      state.search = search
    },
    SET_HISTORIQUE_EXPORT(state, historiqueExport) {
      state.historiqueExport = historiqueExport
    },
    SET_DEMANDE_TYPE(state, type) {
      state.demandeType = type
    },
  },
  actions: {
    setDemande({ commit }, demande) {
      commit('SET_DEMANDE', demande)
    },
    setDemandeType({ commit }, type) {
      commit('SET_DEMANDE_TYPE', type)
    },
    async insertCompleteDemande({ dispatch, commit, rootState }, { demande, params }) {
      const variables = constructMutation(rootState, demande, params)
      await this._vm.$apollo
        .mutate({
          mutation: ADD_COMPLETE_DEMANDE,
          variables: variables,
        })
        .then(async (res) => {
          const demandeId = res.data.insert_demande.returning[0].id
          await commit('setDemandeId', demandeId)
          if (!params.aidant.isSelected && rootState.aidant.identity.email) {
            dispatch('sendRGPDEmail', rootState.aidant.identity.email)
          }
          if (!params.HMA.isSelected && rootState.HMA.identity.email) {
            dispatch('sendRGPDEmail', rootState.HMA.identity.email)
          }
          if (!params.HMA.isAnonym && params.HMA.isPresent && params.HMA.isSelected) {
            const address = {
              hebergement: params.HMA.data.hebergement,
              access_codes: params.HMA.data.access_codes,
              adress: params.HMA.data.adress,
              building_floor: params.HMA.data.building_floor,
              cedex_city_id: params.HMA.data.cedex_city_id,
            }
            dispatch(
              'HMA/updateAddress',
              { hma_id: params.HMA.data.id, address: address },
              { root: true },
            )
          }
        })
    },
    sendRGPDEmail(_, email) {
      axios.post(process.env.VUE_APP_WEBHOOK_URL + 'sendSigninRGPD', {
        email,
      })
    },
    resetDemande({ commit }) {
      commit('resetDemande')
    },
    async insertDemandeComment({ commit }, commentObject) {
      const isPushed = commentObject.push
      delete commentObject.push
      const id_demande = commentObject.id_demande
      if (!commentObject.created_at) delete commentObject.created_at
      const response = await this._vm.$apollo.mutate({
        mutation: ADD_DEMANDE_COMMENT,
        variables: {
          commentObj: commentObject,
          id_demande,
          date: new Date(),
        },
      })
      if (isPushed) commit('pushNewComment', response.data.insert_demande_comments.returning[0])
    },
    updateDemandeStatus({ _state }, demande) {
      let status = demande.status
      switch (status) {
        case 'attente': {
          status = 1

          break
        }
        case 'veille': {
          status = 2

          break
        }
        case 'visite': {
          status = 3

          break
        }
        // No default
      }
      return this._vm.$apollo.mutate({
        mutation: UPDATE_DEMANDE_STATUS,
        variables: {
          id: demande.id,
          status,
        },
      })
    },
    insertDemandeSolution({ _state }, { id_partenaire, id_demande }) {
      return this._vm.$apollo.mutate({
        mutation: ADD_DEMANDE_SOLUTION,
        variables: {
          id_demande,
          id_partenaire,
        },
      })
    },
    insertDemandeSuggestion({ _state }, suggestions) {
      return this._vm.$apollo.mutate({
        mutation: ADD_DEMANDE_SUGGESTION,
        variables: {
          suggestions,
        },
      })
    },
    insertDemandeSearch({ _commit }, search) {
      return this._vm.$apollo.mutate({
        mutation: ADD_DEMANDE_SEARCH,
        variables: {
          search,
        },
      })
    },
    insertPertinence({ _commit }, pertinence) {
      return this._vm.$apollo.mutate({
        mutation: ADD_DEMANDE_PERTINENCE,
        variables: {
          pertinence,
        },
      })
    },
    updateHandler({ _commit }, data) {
      return this._vm.$apollo.mutate({
        mutation: UPDATE_HANDLER,
        variables: {
          id: data.id,
          handler_id: data.handler_id,
        },
      })
    },
    insertDemandePartenaire({ state }, id_partenaire_person) {
      return this._vm.$apollo.mutate({
        mutation: ADD_DEMANDE_PARTENAIRE,
        variables: {
          id_partenaire_person,
          id_demande: state.demandeId,
        },
      })
    },
    async getDemandePertinence({ commit }) {
      const pertinence = await this._vm.$apollo.query({
        query: GET_DEMANDE_PERTINENCE,
      })
      commit('SET_DEMANDE_PERTINENCE', pertinence.data.pertinence_vote)
    },
    async getDemandePartenaireAndAidant({ commit }, id_demande) {
      try {
        const {
          data: { demande, demande_aidant_HMA },
        } = await this._vm.$apollo.mutate({
          mutation: GET_DEMANDE_PARTENAIRE_AND_AIDANT,
          variables: {
            id_demande,
          },
        })

        if (demande[0]?.demande_partenaire && demande[0]?.demande_partenaire.partenaire_person) {
          const id_partenaire = demande[0].demande_partenaire.partenaire_person.id
          commit('setDemandePartenaire', id_partenaire)
        }

        commit('setDemandeAidant', null)
        commit('setDemandeHMA', null)

        if (demande_aidant_HMA.length > 0) {
          for (const person of demande_aidant_HMA) {
            if (person.type === 'aidant') {
              const aidantPerson = {
                id: person.identity_aidant_HMA.id,
                fullName: `${person.identity_aidant_HMA.firstname} ${person.identity_aidant_HMA.lastname}`,
                type: person.identity_aidant_HMA.__typename,
              }
              commit('setDemandeAidant', aidantPerson)
            } else if (person.type === 'HMA') {
              const HMAPerson = {
                id: person.identity_aidant_HMA.id,
                fullName: `${person.identity_aidant_HMA.firstname} ${person.identity_aidant_HMA.lastname}`,
                type: person.identity_aidant_HMA.__typename,
              }

              commit('setDemandeHMA', HMAPerson)
            }
          }
        }
      } catch (error) {
        throw new Error(`[demande](getDemandePartenaireAndAidant) ${error}`)
      }
    },
    async getDemandeList({ commit }) {
      const {
        data: { demande },
      } = await this._vm.$apollo.query({
        query: GET_DEMANDE_LIST,
      })
      commit('setDemandeList', demande)
      commit('setDemandeListLength', demande)

      return demande
    },
    async getDemandeDetails({ commit }, id) {
      try {
        const response = await this._vm.$apollo.mutate({
          mutation: GET_DEMANDE_DETAIL,
          fetchPolicy: 'no-cache',
          variables: {
            id,
          },
        })
        commit('SET_DEMANDE_DETAILS', response.data.demande[0])
        commit('SET_DEMANDE_SOLUTION', response.data.demande_solution)
        commit('SET_DEMANDE_PARTENAIRE', response.data.demande_partenaire)
        commit('SET_DEMANDE_AIDANT_HMA', response.data.demande_aidant_HMA)
        commit('SET_DEMANDE_SUGGESTIONS', response.data.demande_suggestions)
        commit('SET_DEMANDE_COMMENTS', response.data.demande_comments)
      } catch (error) {
        throw new Error(`[demande](getDemandeDetails) While getting demande details - ${error}`)
      }
    },
    resetDetails({ commit }) {
      commit('resetDetails')
    },
    deleteDemandeSearch({ _commit }, id_demande) {
      return this._vm.$apollo.mutate({
        mutation: DELETE_DEMANDE_SEARCH,
        variables: {
          id_demande,
        },
      })
    },
    updateDemandeComment(_, { id, properties }) {
      return this._vm.$apollo.mutate({
        mutation: UPDATE_DEMANDE_COMMENT,
        variables: {
          id,
          properties,
        },
      })
    },
    async updateSearch(
      _context,
      { id, theme, search, family_link, has_already_family_link, demande },
    ) {
      theme = theme ? { theme } : {}

      const searchTemporary = {}

      for (const property in search) {
        if (search[property]) searchTemporary[`${property}`] = search[property]
      }

      const _response = await this._vm.$apollo.mutate({
        mutation: UPDATE_SEARCH,
        variables: {
          id,
          theme,
          search: searchTemporary,
        },
      })

      if (!family_link) {
        return
      }

      await (has_already_family_link
        ? this._vm.$apollo.mutate({
            mutation: UPDATE_FAMILIY_LINK,
            variables: { id, family_link },
          })
        : this._vm.$apollo.mutate({
            mutation: INSERT_FAMILY_LINK,
            variables: {
              object: {
                aidant_id: demande.aidant.id,
                hma_id: demande.HMA.id,
                app_dropdown_id: family_link,
                demande_id: demande.detail.id,
              },
            },
          }))
    },
    async getDemandeSolutionAndSearch({ commit }, id) {
      const response = await this._vm.$apollo.mutate({
        mutation: GET_DEMANDE_SOLUTION,
        variables: {
          id,
        },
      })
      commit('SET_HAS_SOLUTION', response.data.demande_solution)
      commit('SET_HAS_SEARCH', response.data.demande_search)
    },
    async getHistoriqueExport({ commit }) {
      commit('SET_HISTORIQUE_EXPORT', null)
      const response = await this._vm.$apollo.query({
        query: GET_HISTORIQUE_EXPORT,
        fetchPolicy: 'no-cache',
      })
      commit('SET_HISTORIQUE_EXPORT', response.data.demande)
    },
    async removeSolution(_context, { id_partenaire, id_demande }) {
      const _response = await this._vm.$apollo.mutate({
        mutation: REMOVE_DEMANDE_SOLUTION,
        variables: {
          id_partenaire,
          id_demande,
        },
      })
    },
    async deleteDemande(_context, { id_demande }) {
      const _response = await this._vm.$apollo.mutate({
        mutation: DELETE_DEMANDE,
        variables: {
          id_demande,
        },
      })
    },
    async insertFamilyLink(context, { aidant_id, hma_id, app_dropdown_id, demande_id }) {
      if (aidant_id && hma_id && app_dropdown_id) {
        const _response = await this._vm.$apollo.mutate({
          mutation: ADD_FAMILY_LINK,
          variables: {
            family_link: {
              aidant_id,
              hma_id,
              app_dropdown_id,
              demande_id,
            },
          },
        })
        const host = {
          hma_id,
          aidant_id,
        }
        context.dispatch('HMA/updateIdentity', host, { root: true })
      }
    },
    async lastDemande(_context) {
      return await this._vm.$apollo.query({
        query: LAST_DEMANDE,
      })
    },
  },
}
function constructMutation(root, demande, parameters) {
  const curentUserId = root.auth.user.id
  const variables = {
    demande_partenaire: null,
    demande_aidant_HMA: null,
    contact_method_id: null,
    handler_id: null,
    source_id: null,
    type_id: null,
    theme: null,
    created_at: null,
  }

  // adding demande variables
  variables.contact_method_id = demande.contact_method_id
  variables.source_id = demande.source_id
  variables.theme = demande.theme
  variables.type_id = demande.type_id
  variables.created_at = demande.created_at
  variables.handler_id = curentUserId
  // PARTENAIRE
  if (parameters.partenaire.isPresent) {
    // if the partenaire person is selected from partenaire we only add his id
    if (parameters.partenaire.isPersonSelected) {
      variables.demande_partenaire = {
        data: { id_partenaire_person: parameters.partenaire.personData.id },
      }
    } else if (parameters.partenaire.isStructureSelected) {
      // else if the structure is selected we add the new partenaire person with the structure id
      const person = root.partenaire.partenairePerson
      person.actif = true
      if (root.partenaire.personKeywords) {
        const keywords = root.partenaire.personKeywords.map((key) => {
          return { id_keyword: key }
        })
        person.person_keywords = { data: keywords }
      }
      person.location_id = parameters.partenaire.structureData.id
      variables.demande_partenaire = {
        data: { partenaire_person: { data: person } },
      }
    } else {
      // else we create the structure and his person
      const person = root.partenaire.partenairePerson
      person.actif = true
      if (root.partenaire.personKeywords) {
        const keywords = root.partenaire.personKeywords.map((key) => {
          return { id_keyword: key }
        })
        person.person_keywords = { data: keywords }
      }
      delete person.location_id
      const location = root.partenaire.partenaireLocation
      person.partenaire_location = { data: location }
      variables.demande_partenaire = {
        data: { partenaire_person: { data: person } },
      }
    }
  }

  const aidant_hma = []
  // AIDANT
  if (!parameters.aidant.isAnonym && parameters.aidant.isPresent) {
    // if aidant is selected from aidant list we only add his id
    if (parameters.aidant.isSelected)
      aidant_hma.push({
        type: 'aidant',
        id_aidant_HMA: parameters.aidant.data.id,
      })
    else {
      // else we create the aidant
      const aidant = { ...root.aidant.identity }
      aidant.category = joinCategory(aidant.category)
      aidant.type = 'aidant'
      delete aidant.adhesions
      aidant_hma.push({
        type: 'aidant',
        identity_aidant_HMA: { data: aidant },
      })
    }
  }
  // HMA
  if (!parameters.HMA.isAnonym && parameters.HMA.isPresent) {
    // if HMA is selected from aidant list we only add his id and is address
    if (parameters.HMA.isSelected) {
      const _address = {
        hebergement: parameters.HMA.data.hebergement,
        access_codes: parameters.HMA.data.access_codes,
        adress: parameters.HMA.data.adress,
        building_floor: parameters.HMA.data.building_floor,
        cedex_city_id: parameters.HMA.data.cedex_city_id,
      }
      aidant_hma.push({ type: 'HMA', id_aidant_HMA: parameters.HMA.data.id })
    } else {
      // else we create the aidant the HMA
      const HMA = { ...root.HMA.identity }
      HMA.type = 'HMA'
      HMA.category = joinCategory(HMA.category)
      delete HMA.adhesions
      aidant_hma.push({ type: 'HMA', identity_aidant_HMA: { data: HMA } })
    }
  }
  variables.demande_aidant_HMA = { data: aidant_hma }
  return variables
}

function joinCategory(category) {
  if (!category || category.length === 0) {
    return null
  } else if (typeof category === 'string') return category
  else {
    return category.join(';')
  }
}
