import gql from 'graphql-tag'

const SEARCH_DEMANDE_NOTIF_PERSON = gql`
  query searchPerson($name: String!, $id_demande: Int!) {
    demande_aidant_HMA(
      where: {
        identity_aidant_HMA: {
          _or: [{ firstname: { _ilike: $name } }, { lastname: { _ilike: $name } }]
        }
        _and: { id_demande: { _eq: $id_demande } }
      }
    ) {
      identity_aidant_HMA {
        id
        lastname
        firstname
      }
    }
    partenaire_person(
      where: {
        _or: [{ firstname: { _ilike: $name } }, { lastname: { _ilike: $name } }]
        _and: { isObsolete: { _eq: false }, actif: { _eq: true } }
      }
    ) {
      firstname
      id
      lastname
      __typename
      # unavailabilities: partenaire_unavailabilities {
      #   date
      # }
    }
  }
`

export { SEARCH_DEMANDE_NOTIF_PERSON }
