// @ts-check

import Vue from 'vue'
import { ApolloClient, createHttpLink, InMemoryCache, from } from '@apollo/client'
import router from '@/router/index.js'
import store from '@/store/index'
import 'isomorphic-unfetch'
import { GC_AUTH_TOKEN } from '@/constants/settings'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_URL,
  credentials: 'same-origin',
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(GC_AUTH_TOKEN)

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    console.error(`[ApolloClientPlugin](onError): Network error - ${networkError}`)
  }

  if (graphQLErrors?.[0]?.extensions?.code == 'access-denied') {
    const token = localStorage.getItem(GC_AUTH_TOKEN)

    if (token) {
      const decodedToken = parseJwt(token)
      var current_time = Date.now().valueOf() / 1000
      if (decodedToken.exp < current_time) {
        /* expired */
        store.dispatch('auth/cleanLocalStorage')
        router.push('/auth')
      }
    }
  }
})

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([onErrorLink, authLink, httpLink]),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
    },
  },
})

function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  /* eslint-disable unicorn/prefer-spread */
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.codePointAt(0).toString(16)).slice(-2))
      .join(''),
  )
  /* eslint-enable unicorn/prefer-spread */
  return JSON.parse(jsonPayload)
}

Vue.prototype.$apollo = apolloClient
