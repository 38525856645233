// @ts-check

import { Duration } from 'luxon'
import { RRule, rrulestr } from 'rrule'
import { moment } from '@/plugins/moment.plugin'

/** @typedef {import('@/domain/events/types').Event} Event */

export function checkIfDateIsInsideRange(date, startDate, endDate) {
  return date >= startDate && date <= endDate
}

/**
 * @param {(number | string)[]} array
 * @returns
 */
export function getUniqFromArray(array) {
  return array.filter((value, index, array) => array.indexOf(value) === index)
}

/**
 * @param {Date} startDateA
 * @param {Date} endDateA
 * @param {Date} startDateB
 * @param {Date} endDateB
 */
export function checkIfTwoDateRangeAreConflicting(startDateA, endDateA, startDateB, endDateB) {
  return (
    (startDateA <= startDateB && endDateA >= startDateB) ||
    (startDateA <= endDateB && endDateA >= endDateB) ||
    (startDateB <= startDateA && endDateB >= startDateA) ||
    (startDateB <= endDateA && endDateB >= endDateA)
  )
}

export function getStartOfWeekFromDate(date) {
  return moment(date).clone().startOf('week').toDate()
}

export function getEndOfWeekFromDate(date) {
  return moment(date).clone().endOf('week').toDate()
}

/**
 * @param {Date | string} dateA
 * @param {Date | string} dateB
 * @returns {boolean}
 */
export function isSameDay(dateA, dateB) {
  return moment(moment(dateA)).isSame(moment(dateB), 'day')
}

/**
 * @param {import('@/domain/events/types').ParterUnavaibility} unavailability
 * @param {Event} event
 * @returns {boolean}
 */
export function checkIfPartnerIsAbsentForEvent(unavailability, event) {
  try {
    const { start, end } = getStartAndEndDates({
      date: event.date,
      duration: event.duration,
      time: event.time,
    })

    const unavailabilityRruleString = rrulestr(unavailability.rrule)
    const isRecurrentAbsence = !unavailabilityRruleString.options.count

    const isAllDayUnavaibility = unavailability.all_day

    if (isRecurrentAbsence) {
      const unavaibilityRrule = new RRule({
        ...unavailabilityRruleString.options,
      })

      const abscenceDates = unavaibilityRrule.between(
        getStartOfWeekFromDate(start),
        getEndOfWeekFromDate(end),
      )

      if (isAllDayUnavaibility) {
        return abscenceDates.some((date) => isSameDay(date, event.date))
      }

      return abscenceDates.some((date) => {
        const unavailabilityStartDate = moment(date).set(
          'hours',
          moment(unavailability.date_start).get('hours'),
        )
        const unavailabilityStartEnd = moment(date).set(
          'hours',
          moment(unavailability.date_end).get('hours'),
        )

        return (
          moment(start).isBetween(
            unavailabilityStartDate,
            unavailabilityStartEnd,
            'minutes',
            '[]',
          ) ||
          moment(end).isBetween(unavailabilityStartDate, unavailabilityStartEnd, 'minutes', '[]')
        )
      })
    } else {
      if (isAllDayUnavaibility) {
        return isSameDay(start, new Date(unavailability.date_start))
      }

      return (
        moment(start).isBetween(
          unavailability.date_start,
          unavailability.date_end,
          'minutes',
          '[]',
        ) ||
        moment(end).isBetween(unavailability.date_start, unavailability.date_end, 'minutes', '[]')
      )
    }
  } catch (error) {
    throw new Error(`[utilities](checkIfPartnerIsAbsentForEvent) ${error}`)
  }
}

export function formatDate(date, withTime = false) {
  if (date === null) return '-'

  return moment(date).format(withTime ? 'll' : 'ddd ll')
}

export function formatTime(time) {
  if (!time) {
    return
  }

  return moment(time, 'HH:mm:ss').format('LT')
}

/** @param {number} value */
export const getDurationValueFromMinutes = (value) => {
  if (!value) {
    return
  }

  const parsedDuration = Duration.fromMillis(value * 60 * 1000, { numberingSystem: 'numeric' })
    .toFormat('hh:mm')
    .split(':')

  return { HH: parsedDuration[0], mm: parsedDuration[1] }
}

/**
 * @param {object} payload
 * @param {string} payload.date
 * @param {string} payload.time
 * @param {number} payload.duration
 * @param {string=} payload.format - Moment Format
 */
export function getStartAndEndDates({ date, time, duration, format = 'YYYY-MM-DD' }) {
  try {
    const start = moment(`${date}T${time ?? '00:00:00'}`)
    const end = moment(`${date}T${time ?? '00:00:00'}`).add(duration, 'minutes')

    return {
      start: start.toDate(),
      end: end.toDate(),
      startFormatted: start.format(format),
      endFormatted: end.format(format),
    }
  } catch (error) {
    throw new Error(`[utilities](getStartAndEndDates) ${error}`)
  }
}

/**
 * @param {object} object
 * @returns {Partial<object>} object
 */
export function removeAllUndefinedPropertiesFromObject(object) {
  const newObject = {}

  for (const [key, value] of Object.entries(object)) {
    const noValue = value === undefined

    if (!noValue) {
      newObject[key] = value
    }
  }

  return newObject
}

/**
 * @param {number} durationInMin
 */
export function getEventDuration(durationInMin) {
  const duration = moment.duration(durationInMin * 60 * 1000)
  const durationHours = duration.hours()
  const durationMin = duration.minutes()

  const hourWord = durationHours > 1 ? 'heures' : 'heure'

  if (durationHours && durationMin) {
    return `${durationHours} ${hourWord} et ${durationMin} min`
  } else if (durationHours) {
    return `${durationHours} ${hourWord}`
  } else {
    return `${durationMin} min`
  }
}

/**
 * @param {string} dateString
 */
export function getAgeFromDateString(dateString) {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export const isObject = (object) => {
  return object != null && typeof object === 'object'
}

export const isDeepEqual = (object1, object2) => {
  const objectKeys1 = Object.keys(object1)
  const objectKeys2 = Object.keys(object2)

  const hasSameKeysLength = objectKeys1.length === objectKeys2.length

  if (!hasSameKeysLength) {
    return false
  }

  for (var key of objectKeys1) {
    const value1 = object1[key]
    const value2 = object2[key]

    const isObjects = isObject(value1) && isObject(value2)

    if ((isObjects && !isDeepEqual(value1, value2)) || (!isObjects && value1 !== value2)) {
      return false
    }
  }

  return true
}

/**
 * Extract text content from a html string
 * @param {string?} html
 */
export function clearHTMLTags(html) {
  if (!html) return ''
  const myHTML = new DOMParser().parseFromString(html, 'text/html')
  return myHTML.body.textContent || ''
}

/**
 * Loop over object and remove html tag of each properties
 * @param {object?} object
 */
export function clearObjectFromHtmlTags(object) {
  if (!isObject(object)) return

  for (const key in object) {
    if (typeof object[key] === 'object') {
      if (Array.isArray(object[key])) {
        // loop through array
        for (let index = 0; index < object[key].length; index++) {
          clearObjectFromHtmlTags(object[key][index])
        }
      } else {
        // call function recursively for object
        clearObjectFromHtmlTags(object[key])
      }
    } else {
      object[key] = clearHTMLTags(object[key])
    }
  }
  return object
}
