import gql from 'graphql-tag'

export const updateOccurencesMutation = gql`
  mutation updateOccurences($occurences: [occurrence_exception_insert_input!]!) {
    insert_occurrence_exception(
      objects: $occurences
      on_conflict: {
        constraint: occurrence_exception_pkey
        update_columns: [event_id, new_date, date]
      }
    ) {
      returning {
        id
        __typename
      }
    }
  }
`
