import gql from 'graphql-tag'

const ADD_FAMILY_LINK = gql`
  mutation insertFamilyLinkOne($family_link: family_link_insert_input!) {
    insert_family_link_one(object: $family_link) {
      id
    }
  }
`

export { ADD_FAMILY_LINK }
