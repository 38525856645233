/** @type {import('./types').VeeValidateCustomRule} */
const password = {
  name: 'password',
  validator: {
    message: () => {
      return 'Le mot de passe doit faire au minimum 8 caractères, contenir une majuscule, une minuscule et un chiffre'
    },

    validate: (value) => {
      let isValidated = true
      // Validate lowercase letters
      var lowerCaseLetters = /[a-z]/g
      if (!value) return false
      if (!lowerCaseLetters.test(value)) isValidated = false

      // Validate capital letters
      var upperCaseLetters = /[A-Z]/g
      if (!upperCaseLetters.test(value)) isValidated = false

      // Validate numbers
      var numbers = /\d/g
      if (!numbers.test(value)) isValidated = false

      // Validate length
      if (!(value.length >= 8)) isValidated = false
      return isValidated
    },
  },
}
/** @type {import('./types').VeeValidateCustomRule} */
const minArrayLength = {
  name: 'minArrayLength',
  validator: {
    validate: (values, minCount) => {
      const filteredValues = values?.filter(Boolean)

      return {
        valid: filteredValues.length >= Number(minCount[0]) ? true : false,
      }
    },
    message: (field, minCount) => {
      return `Vous devez donner au moins ${minCount[0]} ${field}`
    },
  },
}

export const validators = [minArrayLength, password]
