// @ts-check

import { RRule } from 'rrule'
import { moment } from '@/plugins/moment.plugin'
import { removeAllUndefinedPropertiesFromObject } from '@/utils/utilities'

/** @typedef {import('./events.provider').Event} Event */

const recurrenceOptionsEnum = {
  daily: { freq: RRule.DAILY, interval: 1 },
  '7 days': { freq: RRule.WEEKLY, interval: 1 },
  '14 days': { freq: RRule.WEEKLY, interval: 2 },
}

/**
 * @param {Event[]} events
 * @param {string} startOccurrencesDate
 * @param {string} endOccurrencesDate
 */
export function getEventsWithOccurrencesAndExceptions(
  events,
  startOccurrencesDate,
  endOccurrencesDate,
) {
  try {
    const recurrentEvents = []

    for (const event of events) {
      if (event.recurrence === '00:00:00') {
        recurrentEvents.push(event)
        continue
      }

      const occurrences = getOccurrencesFromEvent(event, startOccurrencesDate, endOccurrencesDate)

      for (const occurrence of occurrences) {
        recurrentEvents.push(occurrence)
      }
    }

    return recurrentEvents.map((event) => {
      const findedOccurrenceException = getOccurrenceExceptionOfEvent(
        event.occurrence_exceptions,
        event,
      )

      if (findedOccurrenceException) {
        const { id, ...rest } = findedOccurrenceException

        const eventException = removeAllUndefinedPropertiesFromObject(rest)

        return {
          ...event,
          ...eventException,
          // cancelled: eventException.cancelled ?? event.cancelled,
          originalDate: rest.date,
          date: rest.new_date ?? event.date,
          occurrenceExceptionId: id,
        }
      } else {
        return event
      }
    })
  } catch (error) {
    throw new Error(`[events/utils](getEventsWithOccurrencesAndExceptions) ${error}`)
  }
}

/**
 * Prevent date to be one day before cause of the switch of winter/summer time
 * RRrule doesnt handle it or it break all events
 * @param {Date} date
 */
export function parseRRDate(date) {
  if (new Date(date).getHours() === 23) {
    return moment(date)
      .add(1, 'day')
      .set({
        hour: 0,
        minute: 0,
        second: 0,
      })
      .toDate()
  }
  return date
}

/**
 * @param {Event} event
 * @param {string} startDate
 * @param {string} endDate
 */
export function getOccurrencesFromEvent(event, startDate, endDate) {
  try {
    const eventOccurrencesDates = new RRule({
      ...recurrenceOptionsEnum[event.recurrence],
      dtstart: moment(event.date_start).toDate(),
      until: moment(event.date_end).toDate(),
    })
      .all()
      .map((date) => parseRRDate(date))

    const occurrencesWithoutNoNeededDates = eventOccurrencesDates.filter((occurrenceDate) =>
      moment(occurrenceDate).isBetween(
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        'date',
        '[]',
      ),
    )

    return occurrencesWithoutNoNeededDates.map((date) => {
      return {
        ...event,
        cancelled: event.cancelled,
        cancellation_reason: event.cancellation_reason,
        date: moment(date).format('YYYY-MM-DD'),
        // date_start: moment(date).format('YYYY-MM-DD'),
      }
    })
  } catch (error) {
    throw new Error(`[events/utils](getOccurrencesFromEvent) ${error}`)
  }
}

/**
 * @param {import('./events.provider').OccurrenceException[]} occurrenceExceptions
 * @param {Event} event
 * @returns {import('./events.provider').OccurrenceException=}
 */
export function getOccurrenceExceptionOfEvent(occurrenceExceptions, event) {
  try {
    return occurrenceExceptions.find(
      (occurrenceException) => occurrenceException.date === event.date,
    )
  } catch (error) {
    throw new Error(`[events/utils](getOccurrenceExceptionOfEvent) ${error}`)
  }
}
