// @ts-check

import Vue from 'vue'
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from 'vee-validate'
import {
  regex,
  email,
  min,
  max,
  oneOf,
  numeric,
  required,
  confirmed,
  alpha,
} from 'vee-validate/dist/rules'
import { validators } from './custom-validators'

extend('email', {
  ...email,
  message: "Vérifier le format de l'e-mail",
})

extend('required', {
  ...required,
  message: 'Vous devez remplir le champ {_field_}',
})

extend('regex', {
  ...regex,
  message: 'Vérifiez le format de {_field_}',
})

extend('min', {
  ...min,
  message: 'Le champ {_field_} doit faire au moins {length} caractères',
})

extend('max', {
  ...max,
  message: 'Le champ {_field_} doit faire au maximum {length} caractères',
})

extend('oneOf', {
  ...oneOf,
  message: 'Vérifiez le champ {_field_}',
})

extend('numeric', {
  ...numeric,
  message: 'Le champ {_field_} ne doit contenir que des chiffres',
})

extend('confirmed', {
  ...confirmed,
  message: 'Les mots de passent ne correspondent pas',
})
extend('alpha', {
  ...alpha,
  message: 'Le champ {_field_} ne doit contenir que des lettres',
})

for (const validator of validators) {
  extend(validator.name, validator.validator)
}

setInteractionMode('eager')

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
