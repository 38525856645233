import gql from 'graphql-tag'
import { eventFieldsFragment } from './fragments/event.fragment'

export const getEventsQuery = gql`
  query getEvents($dateStart: date!, $dateEnd: date!) {
    events(
      order_by: { date: asc }
      where: { date_start: { _lte: $dateEnd }, date_end: { _gte: $dateStart } }
    ) ${eventFieldsFragment}
  }
`
