import gql from 'graphql-tag'

const ADD_IDENTITY = gql`
  mutation insertIdentity($identity: [identity_aidant_HMA_insert_input!]!) {
    insert_identity_aidant_HMA(objects: $identity) {
      returning {
        id
        firstname
        lastname
        __typename
      }
    }
  }
`

const GET_HMA_WITH_AIDANT = gql`
  query getHMAWithAidant {
    demande_aidant_HMA(order_by: { created_at: desc }) {
      type
      identity_aidant_HMA {
        id
        firstname
        lastname
        email
        sex
        type
        isAnonymized
        category
        dead
        cedex_city {
          city
          id
        }
        created_at
      }
      demande {
        id
        demande_aidant_HMAs {
          id
          demande {
            id
          }
          identity_aidant_HMA {
            id
            firstname
            lastname
            email
            sex
            type
          }
        }
      }
    }
  }
`
const GET_AIDANTS_HMA = gql`
  query getAidantsHMA {
    identity_aidant_HMA(where: { isAnonymized: { _eq: false } }, order_by: { created_at: desc }) {
      id
      birthday
      category
      dead
      created_at
      type
      cedex_city {
        id
        city
      }
      sex
      lastname
      firstname
      tranche
      status: appDropdownByStatusId {
        name
      }
    }
  }
`

export const GET_AIDANT_HMA = gql`
  query getAidantsHMA($id: Int!) {
    identity_aidant_HMA_by_pk(id: $id) {
      id
      birthday
      tranche
      category
      created_at
      updated_at
      type
      dead
      isRGPD
      cedex_city {
        id
        city
        cedex
      }
      sex
      lastname
      firstname
      adress
      access_codes
      building_floor
      email
      hebergement
      phone_number
      phone_number_2
      host_id
      comment
    }
  }
`

const GET_IDENTITY_DETAILS = gql`
  query getIdentityDetails($id_identity: Int!) {
    identity_aidant_HMA(where: { id: { _eq: $id_identity } }) {
      __typename
      id
      birthday
      tranche
      category
      created_at
      updated_at
      type
      dead
      isRGPD
      cedex_city {
        id
        city
        cedex
      }
      sex
      lastname
      firstname
      adress
      access_codes
      building_floor
      email
      hebergement
      phone_number
      phone_number_2
      host_id
      status: appDropdownByStatusId {
        id
        name
      }
      comment
      adhesions: aidant_HMA_adhesions {
        id
        start_date
        end_date
      }
      comments: aidant_HMA_comments(order_by: { updated_at: desc }) {
        updated_at
        content
        id
      }
    }

    demande(
      where: { demande_aidant_HMAs: { id_aidant_HMA: { _eq: $id_identity } } }
      order_by: { created_at: desc }
    ) {
      updated_at
      created_at
      id
      appDropdownByTypeId {
        id
        name
      }
      demande_comments(order_by: { created_at: asc }) {
        comment
      }
      theme
      demande_solutions {
        partenaire_person {
          firstname
          lastname
          profession
          partenaire_location {
            name
          }
        }
      }
      status_id
    }
  }
`

const INSERT_DEMANDE_AIDANT_MHA = gql`
  mutation insertDemandeAidantHMA($ids: [demande_aidant_HMA_insert_input!]!) {
    insert_demande_aidant_HMA(objects: $ids) {
      affected_rows
    }
  }
`

const ADD_ADHESION = gql`
  mutation add_aidant_hma_adhesions($adhesions: [aidant_HMA_adhesions_insert_input!]!) {
    insert_aidant_HMA_adhesions(objects: $adhesions) {
      affected_rows
    }
  }
`

const UPDATE_ADHESION = gql`
  mutation update_adhesion($adhesions: [aidant_HMA_adhesions_insert_input!]!) {
    insert_aidant_HMA_adhesions(
      objects: $adhesions
      on_conflict: { constraint: aidant_HMA_adhesions_pkey, update_columns: [start_date, end_date] }
    ) {
      affected_rows
    }
  }
`

const UPDATE_STATUS = gql`
  mutation updateStatus($id: Int!, $isDead: Boolean!) {
    __typename
    update_identity_aidant_HMA(where: { id: { _eq: $id } }, _set: { dead: $isDead }) {
      affected_rows
    }
  }
`
const UPDATE_IDENTITY = gql`
  mutation updateStatus($id: Int!, $identity: identity_aidant_HMA_set_input!) {
    __typename
    update_identity_aidant_HMA(where: { id: { _eq: $id } }, _set: $identity) {
      affected_rows
    }
  }
`

const SEARCH_IDENTITY_BY_NAME = gql`
  query searchIdentitiesByName($name: String!) {
    identities: identity_aidant_HMA(
      where: {
        _and: [
          { _or: [{ firstname: { _ilike: $name } }, { lastname: { _ilike: $name } }] }
          { dead: { _eq: false } }
          { isAnonymized: { _eq: false } }
        ]
      }
      order_by: { lastname: asc }
    ) {
      __typename
      adress
      access_codes
      birthday
      building_floor
      category
      cedex_city_id
      email
      # family_link_id
      firstname
      hebergement
      lastname
      id
      phone_number
      sex
      status_id
      comments: aidant_HMA_comments(order_by: { updated_at: desc }) {
        updated_at
        content
        id
      }
    }
  }
`

const GET_RGPD_EMAIL = gql`
  query getRGPDEmail {
    identity_aidant_HMA(where: { isRGPD: { _eq: true } }) {
      email
    }
  }
`
const GET_ALL_EMAIL = gql`
  query getAllEmail {
    identity_aidant_HMA {
      email
    }
  }
`

const ADD_IDENTITY_COMMENT = gql`
  mutation insert_identity_comments($comments: [aidant_HMA_comments_insert_input!]!) {
    insert_aidant_HMA_comments(objects: $comments) {
      affected_rows
    }
  }
`

export {
  ADD_IDENTITY,
  GET_AIDANTS_HMA,
  GET_IDENTITY_DETAILS,
  INSERT_DEMANDE_AIDANT_MHA,
  UPDATE_STATUS,
  UPDATE_IDENTITY,
  ADD_ADHESION,
  UPDATE_ADHESION,
  SEARCH_IDENTITY_BY_NAME,
  GET_RGPD_EMAIL,
  GET_ALL_EMAIL,
  GET_HMA_WITH_AIDANT,
  ADD_IDENTITY_COMMENT,
}
