// @ts-check

/**
 * @param {object} payload
 * @param {string} payload.baseUrl
 */
export const getFetchInstance = ({ baseUrl }) => {
  /**
   * @param {object} payload
   * @param {string} payload.path
   * @param {Record<string, unknown>} [payload.body]
   * @param {Omit<RequestInit, 'body'>} [payload.config]
   */
  const fetch = async ({ path, body, config }) => {
    const bodyRequest = body ? JSON.stringify(body) : undefined

    const promise = window.fetch(baseUrl + path, {
      ...config,
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        ...config.headers,
      },
      body: bodyRequest,
    })

    const response = await promise

    if (!response.ok) {
      throw await response.json()
    }

    return response.json()
  }

  return {
    /**
     * @param {string} path
     * @param {Omit<RequestInit, 'body'>} [config]
     */
    get: (path, config) => fetch({ path, config: { ...config, method: 'GET' } }),
    /**
     * @param {string} path
     * @param {any} body
     * @param {Omit<RequestInit, 'body'>} [config]
     */
    post: (path, body, config) => fetch({ path, body, config: { ...config, method: 'POST' } }),
  }
}
