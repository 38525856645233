// @ts-check

import { EmailsProvider } from './emails.provider'
import {
  formatDate,
  formatTime,
  getEventDuration,
  getAgeFromDateString,
  clearObjectFromHtmlTags,
} from '@/utils/utilities'
import { apolloClient } from '@/plugins/apollo-client.plugin'
import { GET_AIDANT_HMA } from '@/graphql/identity'

/**
 * @param {Record<string, unknown>} object
 */
function isEmptyObject(object) {
  const keys = Object.keys(object)
  const values = Object.values(object)
  return keys.length === 0 || values.every((value) => typeof value !== 'boolean' && !value)
}

export class EmailsService {
  /**
   * @type {EmailsProvider}
   */
  provider = undefined

  constructor() {
    this.provider = new EmailsProvider()
  }

  get baseEmailPayload() {
    return {
      logoLink: 'https://www.espacesingulier.org/',
      logoSrc: 'https://espacesingulier.walkingnerds.dev/img/logo-espace-singulier.png',
      subject: "Confirmation d'une mission de mobilité",
      template: 'mission',
    }
  }

  /**
   *
   * @param {string} mobilityAddress
   * @param {import('./types').AidantHMA} hma
   * @returns {string | undefined}
   */
  getAddress(mobilityAddress, hma) {
    if (!mobilityAddress) {
      return
    }

    const isHmaAddress = mobilityAddress.includes('Adresse HMA')
    return isHmaAddress
      ? `${mobilityAddress.replace('Adresse HMA: ', '')} - ${hma.building_floor}`
      : mobilityAddress
  }

  /** @param {import('./../events/types').Event['partenaire']} partenaire */
  getAddressPartnerComment(partenaire) {
    let comment = ''

    if (!partenaire) {
      return comment
    }

    if (partenaire.comment || partenaire.partenaire_location?.comment) {
      comment += `${partenaire.comment ?? ''} ${partenaire.partenaire_location?.comment ?? ''}`
    }

    if (partenaire.firstname || partenaire.lastname) {
      comment += ` - Contact : ${partenaire.firstname ?? ''} ${partenaire.lastname ?? ''}`
    }

    if (partenaire.phone_number1 || partenaire.partenaire_location?.phone_number1) {
      comment += ` - Tel : ${
        partenaire.phone_number1 ?? partenaire.partenaire_location.phone_number1 ?? ''
      } `
    }

    if (partenaire.partenaire_location?.complement) {
      comment += ` - Complement : ${partenaire.partenaire_location?.complement} `
    }

    if (partenaire.partenaire_location?.rencontre) {
      comment += ` - Rencontre : ${partenaire.partenaire_location?.rencontre} `
    }

    return comment
  }

  /** @param {import('./types').AidantHMA} hma */
  getAddressHmaComment(hma) {
    let comment = ''

    if (hma.comment) {
      comment += `- ${hma.comment}`
    }

    if (hma.hebergement) {
      comment += ` - Hébergement : ${hma.hebergement}`
    }
    if (hma.access_codes) {
      comment += ` - Code: ${hma.access_codes}`
    }
    if (hma.phone_number || hma.phone_number_2) {
      comment += ` - Tel: ${hma.phone_number ?? hma.phone_number_2 ?? ''}`
    }

    return comment
  }

  /**
   * @param {string} mobilityAddress
   * @param {import('./types').AidantHMA} hma
   * @param {import('./../events/types').Partenaire} partenaire
   * @returns {string | undefined}
   */
  getAddressComment(mobilityAddress, hma, partenaire) {
    if (!mobilityAddress) {
      return
    }

    const isHmaAddress = mobilityAddress.includes('Adresse HMA')

    const isRightPartnerForAddress = mobilityAddress.includes(
      partenaire?.partenaire_location?.adress,
    )

    let address = ''
    if (isHmaAddress) {
      address = this.getAddressHmaComment(hma)
    } else if (isRightPartnerForAddress) {
      address = this.getAddressPartnerComment(partenaire)
    }
    return address
  }

  /**
   * @param {object} payload
   * @param {import('./types').MissionEmailPayload} payload.missionEmailPayload
   * @returns {import('./types').MailBody}
   */
  emailBodyMapper({ missionEmailPayload }) {
    try {
      const {
        partenaire,
        partenaire_person,
        date,
        time,
        duration,
        mobility_departure_1,
        mobility_arrival_1,
        mobility_arrival_2,
        mobility_trip_type,
        hma,
      } = missionEmailPayload

      const isGoAndBackMobilityTrip = mobility_trip_type === 1
      const partenaire_pro = partenaire || partenaire_person

      const professional = partenaire_pro
        ? {
            fullName: `${partenaire_pro.firstname} ${partenaire_pro.lastname}`,
            address: [
              partenaire_pro.partenaire_location?.name,
              partenaire_pro.partenaire_location?.adress,
              partenaire_pro.partenaire_location?.cedex_city?.cedex,
              partenaire_pro.partenaire_location?.cedex_city?.city,
              partenaire_pro.partenaire_location?.complement,
            ]
              .filter(Boolean)
              .join(' - '),
            phoneNumber: [
              partenaire_pro.phone_number1,
              partenaire_pro.partenaire_location?.phone_number1,
              partenaire_pro.phone_number2,
            ]
              .filter(Boolean)
              .join(' - '),
            comment: partenaire_pro.comment,
          }
        : undefined

      return {
        professional,
        hma: {
          ...hma,
          age: hma?.birthday ? `${getAgeFromDateString(hma?.birthday)} ans` : undefined,
          phoneNumber: hma?.phone_number,
        },
        event: {
          date: formatDate(date),
          time: formatTime(time),
          duration: getEventDuration(duration),
          departure: this.getAddress(mobility_departure_1, hma),
          arrival: this.getAddress(mobility_arrival_1, hma),
          dropOff: isGoAndBackMobilityTrip ? this.getAddress(mobility_arrival_2, hma) : undefined,
          commentDeparture: this.getAddressComment(mobility_departure_1, hma, partenaire),
          commentArrival: this.getAddressComment(mobility_arrival_1, hma, partenaire),
          commentDropOff: this.getAddressComment(mobility_arrival_2, hma, partenaire),
        },
      }
    } catch (error) {
      throw new Error(`[EmailsService](emailBodyMapper) ${error}`)
    }
  }

  /**
   * @param {number} id
   * @returns {Promise<import('./types').AidantHMA>}
   */
  async getHmaAidantInformations(id) {
    const {
      data: { identity_aidant_HMA_by_pk },
    } = await apolloClient.query({
      query: GET_AIDANT_HMA,
      variables: {
        id,
      },
    })

    return {
      ...identity_aidant_HMA_by_pk,
      fullName: `${identity_aidant_HMA_by_pk.firstname} ${identity_aidant_HMA_by_pk.lastname}`,
    }
  }

  /** @param {import('./types').MissionEmailPayload} missionEmailPayload */
  async getEmailBody(missionEmailPayload) {
    try {
      if (missionEmailPayload.hma) {
        const hma = await this.getHmaAidantInformations(missionEmailPayload.hma.id)

        missionEmailPayload = {
          ...missionEmailPayload,
          hma: {
            ...missionEmailPayload.hma,
            ...hma,
          },
        }
      }
      return this.emailBodyMapper({ missionEmailPayload })
    } catch (error) {
      throw new Error(`[EmailsService](getEmailBody) ${error}`)
    }
  }

  /**
   * @param {import('./types').MailBody} emailBody
   * @param {import('./../events/types').Event} event
   */
  async sendMissionEmails(emailBody, event) {
    try {
      const partners = [event.partenaire_person, event.retour_partenaire_person].filter(Boolean)

      const emailContent = {
        ...emailBody,
        professional: isEmptyObject(emailBody.professional) ? undefined : emailBody.professional,
        extraData: isEmptyObject(emailBody.extraData) ? undefined : emailBody.extraData,
      }
      const strippedContent = clearObjectFromHtmlTags(emailContent)
      const baseEmail = {
        ...this.baseEmailPayload,
        ...strippedContent,
      }
      const requests = partners.map((partner) => {
        /** @type {import('./types').MailBodyWithReceiverPartner} */
        const bodyWithPartner = {
          ...baseEmail,
          toEmail: partner.email,
          firstname: partner.firstname,
          toName: `${partner.firstname} ${partner.lastname}`,
        }

        return this.provider.sendEmail(bodyWithPartner)
      })

      return Promise.all(requests)
    } catch (error) {
      throw new Error(`[EmailsService](sendMissionEmail) ${error}`)
    }
  }
}
