import {
  GET_HMA_STATS,
  GET_GLOBAL_STATS,
  GET_AIDANTS_STATS,
  GET_PARTENAIRE_STATS,
  GET_AIDANT_HMA_LISTS,
  GET_GLOBAL_MOBILITE,
  GET_GLOBAL_REPIT,
  GET_GLOBAL_RESSOURCES,
  GET_PAS_DE_MISE_EN_LIEN,
  GET_SOLUTION_TYPE,
  _GET_MONTHLY_STATS,
} from '@/graphql/stats'
const initialIdentityStats = () => {
  return {
    categories: {
      handicapée: 0,
      malade: 0,
      agée: 0,
    },
    sex: {
      F: 0,
      M: 0,
    },
    age: {
      '-10 ans': 0,
      '11-25 ans': 0,
      '26-40 ans': 0,
      '41-60 ans': 0,
      '61-80 ans': 0,
      '+80 ans': 0,
    },
  }
}
export default {
  namespaced: true,
  state: {
    globalReady: false,
    HMAReady: false,
    aidantReady: false,
    partenaireReady: false,
    demandes: {
      total: null,
      ressources: null,
      repit: null,
      mob: null,
    },
    lifeDomainsStats: null,
    beneficiaires: {
      total: null,
      aidantsStats: null,
      HMAStats: null,
      partenairesStats: null,
    },
    demandeTypeStats: null,
    demandeContactStats: null,
    demandeSourceStats: null,
    identityStats: initialIdentityStats(),
    partenaireStats: {
      total: null,
      lifeDomainsStats: null,
    },
    beginDate: initialiseBeginDate(),
    endDate: initialiseEndDate(),
    lists: {
      mobiliteRessources: [],
      mobiliteRepit: [],
      repitRessources: [],
      repitRessourcesMobilite: [],
    },
    globalTheme: {},
    noSolution: null,
    themeSolutionType: {},
    structureTypeBarData: null,
  },
  getters: {
    barOption() {
      return {
        responsive: false,
        scales: {
          xAxes: [
            {
              display: false,
              gridLines: {},
            },
          ],
          yAxes: [
            {
              display: false,
              gridLines: {},
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        legend: { display: false },
        legendCallback: function (chart) {
          let count = 0
          // CORRECTED: let text = []
          const text1 = []
          const text2 = []
          // let text3 = [];
          // CORRECTED: text.push('<ul class="' + chart.id + '-legend">')
          text1.push('<ul class="column1">')
          text2.push('<ul class="column2">')
          // text3.push('<ul class="column3">');
          for (var index = 0; index < chart.data.datasets.length; index++) {
            let temporaryArray
            if (!count) {
              temporaryArray = text1
              count++
            } else if (count === 1) {
              temporaryArray = text2
              count = 0
            }
            // else if (count === 2) { tempArr = text3; count = 0 }
            temporaryArray.push(
              '<li><span style="background-color:' +
                chart.data.datasets[index].backgroundColor +
                '"></span>',
            )
            if (chart.data.datasets[index].label) {
              temporaryArray.push(
                `<span>${
                  chart.data.datasets[index].label + ' '
                } : </span><span class="percentage">${chart.data.datasets[index].data[0] + ' '}(${
                  chart.data.datasets[index].percentage
                }%)</span></li>`,
              )
            }
          }
          text1.push('</ul>')
          text2.push('</ul>')
          // text3.push("</ul>");
          return [...text1, ...text2].join('')
        },
      }
    },
    pieOption() {
      return {
        responsive: false,
        legend: { display: false },
        legendCallback: function (chart) {
          const formatedArray = []
          for (const label of chart.data.labels) {
            formatedArray.push({ label })
          }
          for (const [index, bgColor] of chart.data.datasets[0].backgroundColor.entries()) {
            formatedArray[index].backgroundColor = bgColor
          }
          for (const [index, data] of chart.data.datasets[0].data.entries()) {
            formatedArray[index].data = data
          }
          for (const [index, percent] of chart.data.datasets[0].percentage.entries()) {
            formatedArray[index].percentage = percent
          }
          const text = []
          text.push('<ul class="' + chart.id + '-legend">')
          for (const data of formatedArray) {
            text.push('<li><span style="background-color:' + data.backgroundColor + '"></span>')
            if (data.label) {
              text.push(
                `${data.label + ' '} : <span class="percentage">${data.data + ' '}(${
                  data.percentage
                }%)</span>`,
              )
            }
            text.push('</li>')
          }
          text.push('</ul>')
          return text.join('')
        },
      }
    },

    beginDate(state) {
      return state.beginDate
    },
    endDate(state) {
      return state.endDate
    },
    // demandes stats
    demandes(state) {
      return state.demandes
    },
    lifeDomainsStats(state) {
      return state.lifeDomainsStats
    },
    beneficiaires(state) {
      return state.beneficiaires
    },
    demandeTypeStats(state) {
      return state.demandeTypeStats
    },
    demandeContactStats(state) {
      return state.demandeContactStats
    },
    demandeSourceStats(state) {
      return state.demandeSourceStats
    },
    globalReady(state) {
      return state.globalReady
    },
    // aidant/hma stats
    identityStats(state) {
      return state.identityStats
    },

    //partenaire stats
    partenaireStats(state) {
      return state.partenaireStats
    },
    HMAReady(state) {
      return state.HMAReady
    },
    aidantReady(state) {
      return state.aidantReady
    },
    partenaireReady(state) {
      return state.partenaireReady
    },
    lists(state) {
      return state.lists
    },
    globalTheme(state) {
      return state.globalTheme
    },
    noSolution(state) {
      return state.noSolution
    },
    themeSolutionType(state) {
      return state.themeSolutionType
    },
    solutionType(state) {
      return state.solutionType
    },
    structureTypeBarData(state) {
      return state.structureTypeBarData
    },
  },
  mutations: {
    // demande mutations
    SET_DEMANDES_STATS(state, { total, ressources, repit, mob, monthly }) {
      state.demandes.total = total
      state.demandes.ressources = {
        label: 'Ressources',
        data: ressources,
        backgroundColor: getColor(),
        percentage: percentage(ressources, total),
      }
      state.demandes.repit = {
        label: 'Répit',
        data: repit,
        backgroundColor: getColor(),
        percentage: percentage(repit, total),
      }
      state.demandes.mob = {
        label: 'Mobilité',
        data: mob,
        backgroundColor: getColor(),
        percentage: percentage(mob, total),
      }
      state.demandes.monthly = {
        label: 'Données mensuelles',
        data: monthly,
        backgroundColor: getColor(),
      }
    },
    SET_LIFE_DOMAIN_STATS(state, lifeDomainsStats) {
      const formatedStats = []
      for (const lifeDomain of lifeDomainsStats) {
        formatedStats.push({
          label: lifeDomain.name,
          backgroundColor: getColor(),
          data: [lifeDomain.nombre.aggregate.count],
          percentage: percentage(lifeDomain.nombre.aggregate.count, state.demandes.total),
        })
      }
      state.lifeDomainsStats = formatedStats
    },
    SET_AIDANTS_STATS(state, aidantsStats) {
      state.beneficiaires.total = 0
      state.beneficiaires.total = state.beneficiaires.total + aidantsStats
      state.beneficiaires.aidantsStats = {
        label: 'Aidants',
        data: [aidantsStats],
        backgroundColor: getColor(),
        percentage: percentage(aidantsStats, state.demandes.total),
      }
    },
    SET_HMA_STATS(state, HMAStats) {
      state.beneficiaires.total = state.beneficiaires.total + HMAStats
      state.beneficiaires.HMAStats = {
        label: 'HMA',
        data: [HMAStats],
        backgroundColor: getColor(),
        percentage: percentage(HMAStats, state.demandes.total),
      }
    },
    SET_PARTENAIRES_STATS(state, partenairesStats) {
      state.beneficiaires.total = state.beneficiaires.total + partenairesStats
      state.beneficiaires.partenairesStats = {
        label: 'Partenaires',
        data: [partenairesStats],
        backgroundColor: getColor(),
        percentage: percentage(partenairesStats, state.demandes.total),
      }
    },
    SET_DEMANDE_TYPE_STATS(state, demandeTypeStats) {
      const formatedStats = []
      // let count = 0
      for (const type of demandeTypeStats) {
        formatedStats.push({
          label: type.name,
          backgroundColor: getColor(),
          data: [type.nombre.aggregate.count],
          percentage: percentage(type.nombre.aggregate.count, state.demandes.total),
        })
      }
      state.demandeTypeStats = formatedStats
    },
    SET_DEMANDE_CONTACT_STATS(state, demandeContactStats) {
      const pieData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            percentage: [],
          },
        ],
      }

      for (const contact of demandeContactStats) {
        pieData.labels.push(contact.name)
        pieData.datasets[0].backgroundColor.push(getColor())
        pieData.datasets[0].data.push(contact.nombre.aggregate.count)
        pieData.datasets[0].percentage.push(
          percentage(contact.nombre.aggregate.count, state.demandes.total),
        )
      }
      state.demandeContactStats = pieData
    },
    SET_DEMANDE_SOURCE_STATS(state, demandeSourceStats) {
      const pieData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            percentage: [],
          },
        ],
      }
      for (const source of demandeSourceStats) {
        pieData.labels.push(source.name)
        pieData.datasets[0].backgroundColor.push(getColor())
        pieData.datasets[0].data.push(source.nombre.aggregate.count)
        pieData.datasets[0].percentage.push(
          percentage(source.nombre.aggregate.count, state.demandes.total),
        )
      }
      state.demandeSourceStats = pieData
      state.globalReady = true
    },
    SET_DEMANDE_STRUCTURE_TYPE(state, structure_types) {
      state.structureTypeBarData = fortmatStructureTypeBarData(structure_types)
    },
    // aidant/hma mutations
    SET_IDENTITY_TOTAL(state, total) {
      state.identityStats.total = total
    },
    SET_IDENTITY_STATS(state, identities) {
      for (const { identity } of identities) {
        if (identity.category) {
          const categories = identity.category.split(';')
          for (const category of categories) {
            state.identityStats.categories[category]++
          }
        }
        state.identityStats.sex[`${identity.sex}`]++
        const age = getAge(identity.birthday)
        if (age <= 10) state.identityStats.age['-10 ans']++
        else if (age <= 25) state.identityStats.age['11-25 ans']++
        else if (age <= 40) state.identityStats.age['26-40 ans']++
        else if (age <= 60) state.identityStats.age['41-60 ans']++
        else if (age <= 80) state.identityStats.age['61-80 ans']++
        else if (age > 80) state.identityStats.age['+80 ans']++
      }
    },
    SET_CITIES_STATS(state, cities) {
      const citiesStats = []
      const orderedCities = cities.sort(
        (a, b) => b.number.aggregate.count - a.number.aggregate.count,
      )
      for (const city of orderedCities) {
        if (city.number.aggregate.count) {
          citiesStats.push({
            label: city.city,
            data: [city.number.aggregate.count],
            backgroundColor: getColor(),
            percentage: percentage(city.number.aggregate.count, state.identityStats.total),
          })
        }
      }
      state.identityStats.citiesStats = citiesStats
    },
    SET_CITIES_BAR_DATA(state, _number) {
      const cityBarData = {
        labels: [''],
        datasets: [],
      }
      for (const city of state.identityStats.citiesStats) {
        cityBarData.datasets.push(city)
      }
      state.identityStats.cityBarData = cityBarData
    },
    SET_CATEGORY_BAR_DATA(state) {
      const categories = state.identityStats.categories
      state.identityStats.categoryBarData = formatBarData(categories, state.identityStats.total)
    },
    SET_SEX_PIE_DATA(state) {
      const pieData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            percentage: [],
          },
        ],
      }
      const sex = state.identityStats.sex
      for (const property in sex) {
        pieData.datasets[0].backgroundColor.push(getColor())
        if (property === 'F') pieData.labels.push('Femmes')
        else if (property === 'M') pieData.labels.push('Hommes')
        pieData.datasets[0].data.push(sex[`${property}`])
        pieData.datasets[0].percentage.push(
          percentage(sex[`${property}`], state.identityStats.total),
        )
      }
      state.identityStats.sexPieData = pieData
    },
    SET_AGE_BAR_DATA(state) {
      const age = state.identityStats.age
      state.identityStats.ageBarData = formatBarData(age, state.identityStats.total)
      const orderAge = [
        state.identityStats.ageBarData.datasets[0],
        state.identityStats.ageBarData.datasets[3],
        state.identityStats.ageBarData.datasets[1],
        state.identityStats.ageBarData.datasets[4],
        state.identityStats.ageBarData.datasets[2],
        state.identityStats.ageBarData.datasets[5],
      ]
      state.identityStats.ageBarData.datasets = orderAge
      state.HMAReady = true
      state.aidantReady = true
    },
    SET_FAMILY_LINK_BAR_DATA(state, familyLinks) {
      state.identityStats.familyLinksBarData = formatBarData(familyLinks, state.identityStats.total)
    },
    SET_STATUS_BAR_DATA(state, status) {
      state.identityStats.statusBarData = formatBarData(status, state.identityStats.total)
      state.aidantReady = true
    },
    setBeginDate(state, beginDate) {
      state.beginDate = beginDate
    },
    setEndDate(state, endDate) {
      state.endDate = endDate
    },
    // PARTENAIRE DATA STATS
    SET_PARTENAIRE_TOTAL(state, total) {
      state.partenaireStats.total = total
    },
    SET_STRUCTURE_TYPE_STATS(state, structure_types) {
      // CHANGER LE TOP PAR LE BOT
      const pieData = {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            percentage: [],
          },
        ],
      }
      for (const structure_type of structure_types) {
        pieData.labels.push(structure_type.name)
        pieData.datasets[0].backgroundColor.push(getColor())
        pieData.datasets[0].data.push(structure_type.nombre.aggregate.count)
        pieData.datasets[0].percentage.push(
          percentage(structure_type.nombre.aggregate.count, state.partenaireStats.total),
        )
      }
      state.partenaireStats.lifeDomainsStats = pieData
      state.partenaireReady = true
    },
    SET_AIDANT_HMA_LISTS(state, lists) {
      const repitRessources = compareArrays(lists.listRepit, lists.listRessources)
      state.lists = {
        mobiliteRessources: compareArrays(lists.listMobilite, lists.listRessources),
        mobiliteRepit: compareArrays(lists.listRepit, lists.listMobilite),
        repitRessources,
        repitRessourcesMobilite: compareArrays(repitRessources, lists.listMobilite),
      }
    },
    SET_GLOBAL_THEME_STATS(state, { dataRep, dataRess, dataMob }) {
      state.globalTheme = { dataRep, dataRess, dataMob }
    },
    SET_NO_SOLUTION(state, noSolution) {
      state.noSolution = noSolution
    },
    SET_SOLUTION_TYPE(state, solutionType) {
      state.themeSolutionType.ressources = solutionType.solution_ressources
      state.themeSolutionType.repit = solutionType.solution_repit
      state.themeSolutionType.mobilite = solutionType.solution_mobilite
    },
  },
  actions: {
    async getHMAStats({ state, commit }, { begin, end }) {
      const response = await this._vm.$apollo.mutate({
        mutation: GET_HMA_STATS,
        variables: {
          begin,
          end,
        },
      })
      state.identityStats = initialIdentityStats()
      commit('SET_IDENTITY_TOTAL', response.data.all.aggregate.count)
      commit('SET_IDENTITY_STATS', response.data.males)
      commit('SET_IDENTITY_STATS', response.data.females)
      commit('SET_CITIES_STATS', response.data.city)
      commit('SET_CITIES_BAR_DATA')
      commit('SET_CATEGORY_BAR_DATA')
      commit('SET_SEX_PIE_DATA')
      commit('SET_AGE_BAR_DATA')
    },
    async getAidantStats({ state, commit }, { begin, end }) {
      const response = await this._vm.$apollo.mutate({
        mutation: GET_AIDANTS_STATS,
        variables: {
          begin,
          end,
        },
      })
      state.identityStats = initialIdentityStats()
      commit('SET_DEMANDES_STATS', {
        total: response.data.demandes.aggregate.count,
        ressources: response.data.ressources.aggregate.count,
        repit: response.data.repit.aggregate.count,
        mob: response.data.mob.aggregate.count,
      })
      commit('SET_IDENTITY_TOTAL', response.data.all.aggregate.count)
      commit('SET_IDENTITY_STATS', response.data.males)
      commit('SET_IDENTITY_STATS', response.data.females)
      commit('SET_CITIES_STATS', response.data.city)
      commit('SET_FAMILY_LINK_BAR_DATA', response.data.family_link)
      commit('SET_STATUS_BAR_DATA', response.data.identity_status)
      commit('SET_CITIES_BAR_DATA')
      commit('SET_CATEGORY_BAR_DATA')
      commit('SET_SEX_PIE_DATA')
      commit('SET_AGE_BAR_DATA')
    },

    async getGlobalStats({ commit }, { begin, end }) {
      const response = await this._vm.$apollo.mutate({
        mutation: GET_GLOBAL_STATS,
        variables: {
          begin,
          end,
        },
      })
      commit('SET_DEMANDES_STATS', {
        total: response.data.demandes.aggregate.count,
        ressources: response.data.ressources.aggregate.count,
        repit: response.data.repit.aggregate.count,
        mob: response.data.mob.aggregate.count,
        monthly: response.data.monthly,
      })
      commit('SET_LIFE_DOMAIN_STATS', response.data.life_domains)
      commit('SET_AIDANTS_STATS', response.data.aidants.aggregate.count)
      commit('SET_HMA_STATS', response.data.HMA.aggregate.count)
      commit('SET_PARTENAIRES_STATS', response.data.partenaires.aggregate.count)
      commit('SET_DEMANDE_TYPE_STATS', response.data.demande_type)
      commit('SET_DEMANDE_CONTACT_STATS', response.data.demande_contact)
      commit('SET_DEMANDE_SOURCE_STATS', response.data.demande_source)
      commit('SET_DEMANDE_STRUCTURE_TYPE', response.data.structure_types)
    },
    async getPartenaireStats({ commit }, { begin, end }) {
      const response = await this._vm.$apollo.mutate({
        mutation: GET_PARTENAIRE_STATS,
        variables: {
          begin,
          end,
        },
      })
      commit('SET_PARTENAIRE_TOTAL', response.data.partenaireTotal.aggregate.count)
      commit('SET_STRUCTURE_TYPE_STATS', response.data.structure_types)
    },
    async getAidantHMALists({ commit }, { begin, end }) {
      const response = await this._vm.$apollo.mutate({
        mutation: GET_AIDANT_HMA_LISTS,
        variables: {
          begin,
          end,
        },
      })
      commit('SET_AIDANT_HMA_LISTS', response.data)
    },
    async getThemeStats({ commit }, { begin, end }) {
      const responseMob = await this._vm.$apollo.mutate({
        mutation: GET_GLOBAL_MOBILITE,
        variables: {
          begin,
          end,
        },
      })
      const responseRepit = await this._vm.$apollo.mutate({
        mutation: GET_GLOBAL_REPIT,
        variables: {
          begin,
          end,
        },
      })
      const responseRess = await this._vm.$apollo.mutate({
        mutation: GET_GLOBAL_RESSOURCES,
        variables: {
          begin,
          end,
        },
      })
      commit('SET_GLOBAL_THEME_STATS', {
        dataRep: responseRepit.data,
        dataRess: responseRess.data,
        dataMob: responseMob.data,
      })
    },
    async getNoSolutions({ commit }, { begin, end }) {
      const response = await this._vm.$apollo.mutate({
        mutation: GET_PAS_DE_MISE_EN_LIEN,
        variables: {
          begin,
          end,
        },
      })
      commit('SET_NO_SOLUTION', response.data.demande_solution_aggregate.aggregate.count)
    },
    async getThemeSolutionType({ commit }, { begin, end }) {
      const response = await this._vm.$apollo.mutate({
        mutation: GET_SOLUTION_TYPE,
        variables: {
          begin,
          end,
        },
      })
      commit('SET_SOLUTION_TYPE', response.data)
    },
  },
}
const colors = [
  'rgba(175, 40, 89, 0.6)',
  '#264C88',
  'rgba(175, 40, 89, 0.3)',
  '#AF2859',
  'rgba(38, 76, 136, 0.6)',
]
let count = 0
function getColor() {
  const color = colors[count]
  count++
  if (count > colors.length - 1) count = 0
  return color
}

function percentage(partialValue, totalValue) {
  let result = ((100 * partialValue) / totalValue).toFixed(1)
  if (result === 'NaN') result = 0
  return result
}

function getAge(dateString) {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

function formatBarData(object, max) {
  const barData = {
    labels: [''],
    datasets: [],
  }
  if (Array.isArray(object)) {
    for (const item of object) {
      barData.datasets.push({
        label: item.name,
        backgroundColor: getColor(),
        data: [item.number.aggregate.count],
        percentage: percentage(item.number.aggregate.count, max),
      })
    }
  } else {
    for (const property in object) {
      barData.datasets.push({
        label: property,
        backgroundColor: getColor(),
        data: [object[`${property}`]],
        percentage: percentage(object[`${property}`], max),
      })
    }
  }
  return barData
}
function formatDate(date) {
  const fullDate = new Date(date)
  let day = fullDate.getDate()
  let month = fullDate.getMonth() + 1
  const year = fullDate.getFullYear()
  if (day < 10) day = '0' + day
  if (month < 10) month = '0' + month
  return `${year}-${month}-${day}`
}

function initialiseBeginDate() {
  const lastYear = new Date()
  lastYear.setFullYear(lastYear.getFullYear() - 1)
  return formatDate(lastYear)
}

function initialiseEndDate() {
  var tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  return formatDate(tomorrow)
}

function compareArrays(array1, array2) {
  const temporaryArray = []
  for (const item1 of array1) {
    for (const item2 of array2) {
      if (item1.id_aidant_HMA === item2.id_aidant_HMA) temporaryArray.push(item1)
    }
  }
  return temporaryArray
}

function fortmatStructureTypeBarData(structure_types) {
  const structure_types_object = extractType(structure_types)
  const total = structure_types.length
  const barData = {
    labels: [''],
    datasets: [],
  }
  for (const property in structure_types_object) {
    barData.datasets.push({
      label: property,
      backgroundColor: getColor(),
      data: [structure_types_object[`${property}`]],
      percentage: percentage(structure_types_object[`${property}`], total),
    })
  }
  return { total, barData }
}

function extractType(statArray) {
  const typeStats = {}
  for (const stat of statArray) {
    const type = stat.partenaire_person.partenaire_location.app_dropdown
      ? stat.partenaire_person.partenaire_location.app_dropdown.name
      : null
    if (typeStats[type] && type) typeStats[type]++
    else if (type) typeStats[type] = 1
  }

  return typeStats
}
