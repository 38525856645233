import gql from 'graphql-tag'

const GC_USER_INFOS = gql`
  query getUserById($id: Int!) {
    user_by_pk(id: $id) {
      acronym
      id
      name
      email
      created_at
      updated_at
    }
  }
`
const GET_ALL_USERS = gql`
  query getAllUsers {
    user(where: { id: { _nin: [3, 30, 8] } }, order_by: { order: asc }) {
      acronym
      name
      id
    }
  }
`

export { GC_USER_INFOS, GET_ALL_USERS }
