import Vue from 'vue'
import Vuex from 'vuex'
import { moment } from '@/plugins/moment.plugin'

import dropdowns from './dropdowns'
import auth from './Authentication'
import demande from './demande'
import partenaire from './partenaire'
import aidant from './aidant'
import HMA from './HMA'
import detail_partenaire from './detail_partenaire'
import autocompletion from './autocompletion'
import reminders from './reminders'
import stats from './stats'
import excelStats from './excelStats'
import event from './event'
import { GET_ALL_USERS } from './../graphql/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  moment,
  modules: {
    dropdowns,
    auth,
    demande,
    partenaire,
    aidant,
    HMA,
    detail_partenaire,
    autocompletion,
    reminders,
    stats,
    excelStats,
    event,
  },
  state: {
    title: '',
    users: [],
  },
  getters: {
    getTitle(state) {
      return state.title
    },
    users(state) {
      return state.users
    },
  },
  mutations: {
    setTitle(state, newTitle) {
      state.title = newTitle
    },
    SET_USERS(state, users) {
      state.users = users
    },
  },
  actions: {
    setTitle({ commit }, title) {
      commit('setTitle', title)
    },
    resetDemande({ dispatch }) {
      dispatch('demande/resetDemande')
      dispatch('partenaire/resetPartenaire')
      dispatch('aidant/resetAidant')
      dispatch('HMA/resetHMA')
    },
    async getUsers({ commit }) {
      const response = await this._vm.$apollo.query({
        query: GET_ALL_USERS,
      })
      commit('SET_USERS', response.data.user)
    },
  },
})
