import { GC_USER_ID, GC_AUTH_TOKEN } from '../constants/settings'
import { GC_USER_INFOS, GET_ALL_USERS } from '@/graphql/auth'

const defaultUser = () => {
  return {
    id: null,
    acronym: null,
  }
}

export default {
  namespaced: true,
  state: {
    user: defaultUser(),
    user_list: [],
  },
  getters: {
    isLoggedIn() {
      return !!localStorage.getItem(GC_AUTH_TOKEN)
    },
    currentUser(state) {
      return state.user
    },
    usersList(state) {
      return state.user_list
    },
  },
  mutations: {
    SET_USER_DATA(state, user) {
      state.user.name = user.name
      state.user.id = user.id
      state.user.acronym = user.acronym
      state.user.email = user.email
    },
    INIT_USER(state) {
      state.user = defaultUser()
    },
    SET_USER_INFOS(state, user) {
      state.user = user
    },
    SET_USERS_LIST(state, list) {
      state.user_list = list
    },
  },
  actions: {
    setUserData({ commit }, user) {
      localStorage.setItem(GC_USER_ID, user.id)
      localStorage.setItem(GC_AUTH_TOKEN, user.token)

      commit('SET_USER_DATA', user)
    },
    cleanLocalStorage({ commit }) {
      localStorage.removeItem(GC_USER_ID)
      localStorage.removeItem(GC_AUTH_TOKEN)

      commit('INIT_USER')
    },
    async getCurrentUserInfos({ commit }) {
      try {
        const currentUserId = localStorage.getItem(GC_USER_ID)

        const {
          data: { user_by_pk },
        } = await this._vm.$apollo.query({
          query: GC_USER_INFOS,
          variables: {
            id: currentUserId,
          },
        })

        commit('SET_USER_INFOS', user_by_pk)
        return user_by_pk
      } catch (error) {
        throw new Error(`[Authentication](getCurrentUserInfos) ${error}`)
      }
    },
    async getAllUsers({ commit }) {
      try {
        const {
          data: { user },
        } = await this._vm.$apollo.query({
          query: GET_ALL_USERS,
        })
        commit('SET_USERS_LIST', user)
      } catch (error) {
        console.error(`[Authentification](getAllUsers)`, error)
      }
    },
  },
}
