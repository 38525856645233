// @ts-check

import { getEventsQuery } from './gql/get-events.query'
import { getEventQuery } from './gql/get-event.query'
import { addEventMutation } from './gql/add-event.mutation'
import { updateEventMutation } from './gql/update-event.mutation'
import { addOccurrenceExceptionMutation } from './gql/add-occurrence-exception.mutation'
import { updateOccurrenceExceptionMutation } from './gql/update-occurrence-exception.mutation'
import { deleteEventMutation } from './gql/delete-event.mutation'
import { addOneEventMutation } from './gql/add-one-event.mutation'
import { updateOccurencesMutation } from './gql/update-occurences'

/** @typedef {import('./types').Event} Event */
/** @typedef {import('./types').OccurrenceException} OccurrenceException */
/** @typedef {import('@apollo/client').ApolloClient<any>} ApolloClient */

export class EventsProvider {
  /**
   * @type {ApolloClient}
   */
  $apollo = undefined

  /**
   * @param {ApolloClient} apolloClient
   */
  constructor(apolloClient) {
    this.$apollo = apolloClient
  }

  /**
   * @param {number} id
   * @returns {Promise<Event>}
   */
  async getOne(id) {
    try {
      const {
        data: { events_by_pk },
      } = await this.$apollo.query({
        query: getEventQuery,
        variables: { id },
      })

      return events_by_pk
    } catch (error) {
      throw new Error(`[EventsProvider](getOne) ${error}`)
    }
  }

  /**
   * @param {number} id
   * @returns {Promise<Event>}
   */
  async deleteOne(id) {
    try {
      const {
        data: { delete_events_by_pk },
      } = await this.$apollo.mutate({
        mutation: deleteEventMutation,
        variables: { id },
      })

      return delete_events_by_pk
    } catch (error) {
      throw new Error(`[EventsProvider](deleteOne) ${error}`)
    }
  }

  /**
   * @param {{ dateStart: string, dateEnd: string }} filters - Filters
   * @returns {Promise<Event[]>}
   */
  async getAll({ dateStart, dateEnd }) {
    try {
      const {
        data: { events },
      } = await this.$apollo.query({
        query: getEventsQuery,
        variables: { dateStart, dateEnd },
      })

      return events
    } catch (error) {
      throw new Error(`[EventsProvider](getAll) ${error}`)
    }
  }

  /**
   *
   * @param {Event|Event[]} event
   * @returns {Promise<number>}
   */
  async add(event) {
    try {
      const {
        data: { affected_rows },
      } = await this.$apollo.mutate({
        mutation: addEventMutation,
        variables: { objects: event },
      })

      return affected_rows
    } catch (error) {
      throw new Error(`[EventsProvider](add) ${error}`)
    }
  }

  /**
   *
   * @param {Event} event
   * @returns {Promise<Event>}
   */
  async addOne(event) {
    try {
      const {
        data: { insert_events_one },
      } = await this.$apollo.mutate({
        mutation: addOneEventMutation,
        variables: { object: event },
      })

      return insert_events_one
    } catch (error) {
      throw new Error(`[EventsProvider](add) ${error}`)
    }
  }

  /**
   * @param {Partial<Event>} payload
   * @returns {Promise<Event>}
   */
  async update({ id, ...rest }) {
    try {
      const {
        data: { update_events_by_pk },
      } = await this.$apollo.mutate({
        mutation: updateEventMutation,
        variables: {
          id,
          _set: rest,
        },
      })

      return update_events_by_pk
    } catch (error) {
      throw new Error(`[EventsProvider](update) ${error}`)
    }
  }

  /**
   * @returns {Promise<Partial<OccurrenceException>>}
   */
  async updateOccurence(occurences) {
    try {
      const {
        data: { insert_occurrence_exception },
      } = await this.$apollo.mutate({
        mutation: updateOccurencesMutation,
        variables: {
          occurences,
        },
      })

      return insert_occurrence_exception
    } catch (error) {
      throw new Error(`[EventsProvider](update) ${error}`)
    }
  }

  /**
   * @param {OccurrenceException} occurrenceException
   * @returns {Promise<OccurrenceException>}
   */
  async addOccurrenceException(occurrenceException) {
    try {
      const {
        data: { insert_occurrence_exception_one },
      } = await this.$apollo.mutate({
        mutation: addOccurrenceExceptionMutation,
        variables: {
          occurrenceException,
        },
      })

      return insert_occurrence_exception_one
    } catch (error) {
      throw new Error(`[EventsProvider](addOccurrenceException) ${error}`)
    }
  }

  /**
   * @param {OccurrenceException} occurrenceException
   * @returns {Promise<OccurrenceException>}
   */
  async updateOccurrenceException({ id, ...rest }) {
    try {
      const {
        data: { update_occurrence_exception_by_pk },
      } = await this.$apollo.mutate({
        mutation: updateOccurrenceExceptionMutation,
        variables: {
          id,
          occurrenceException: rest,
        },
      })

      return update_occurrence_exception_by_pk
    } catch (error) {
      throw new Error(`[EventsProvider](updateOccurrenceException) ${error}`)
    }
  }
}
